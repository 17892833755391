<section>
  <div (click)="closeModal()" class="close-button">
    <mat-icon>close</mat-icon>
  </div>

  <h3 class="page-title">Confirmar o pedido</h3>

  <div style="display: flex; flex-direction: column; width: 100%; gap: 1.5vh;">
    <div class="general-info-container">
      <mat-icon class="info-icon">calendar_month</mat-icon>
      <div class="info-container">
        <h4>Entrega</h4>
        <p>{{seller.seller_delivery?.shipping_time}}</p>
      </div>
    </div>
    <div class="general-info-container">
      <mat-icon class="info-icon">map</mat-icon>
      <div class="info-container">
        <h4>Endereço</h4>
        <small>{{data.customer_address.address.full_address}}</small>
      </div>
    </div>
    <div class="general-info-container">
      <mat-icon class="info-icon">payments</mat-icon>
      <div class="info-container">
        <h4>{{this.paymentMethodTitle}}</h4>
        <small>{{this.paymentMethodText}}</small>
      </div>
    </div>
  </div>

  <div class="button-container mt-2">
    <button class="confirm-button w-100" (click)="createOrder()">Confirmar e fazer pedido</button>
    <button (click)="closeModal()" class="alter-data-button">Alterar Dados</button>
  </div>
</section>
