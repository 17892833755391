import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardCreditPipe } from './card-credit.pipe';



@NgModule({

  imports: [
    CommonModule
  ],
  declarations: [
    CardCreditPipe,
  ],
  exports: [
    CardCreditPipe,
  ]
})
export class PipeCardCreditModule { }
