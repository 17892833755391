import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidatorCpf } from 'src/app/config/validators/validator-cpf';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditarSenhaPageComponent } from './editar-senha-page/editar-senha-page.component';
import { InformationComponent } from 'src/app/shared/dialogs/information/information.component';

@Component({
  selector: 'app-editar-perfil-page',
  templateUrl: './editar-perfil-page.component.html',
  styleUrls: ['./editar-perfil-page.component.scss']
})
export class EditarPerfilPageComponent implements OnInit {

  user_id: string | null = null
  user_info: any

  formCustomer = this.formBuilder.group({
    first_name: [null, [Validators.required]],
    last_name: [null, [Validators.required]],
    cpf: [null, [ValidatorCpf]],
    phone_number: [null, [Validators.required]],
    cellphone_number: [null],
    email: [null, [Validators.required]],
    birthday: [null, [Validators.required]]
  });

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private authUserService: AuthUserService,
    private customersEubeboApiService: CustomersEubeboApiService
  ) { }

  ngOnInit(): void {
    this.user_id = this.authUserService.getValue(this.authUserService.userId);

    if (this.user_id == null) {
      this.router.navigate(['/'])
    } else {
      this.getCustomer()
    }
  }

  getCustomer(): void {
    if (this.user_id != null) {
      this.customersEubeboApiService.getUserById(this.user_id).subscribe({
        next: (res: any) => {
          this.edit(res)
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }

  edit(data: any) {
    this.formCustomer.controls.first_name.setValue(data.first_name);
    this.formCustomer.controls.last_name.setValue(data.last_name);
    this.formCustomer.controls.email.setValue(data.email);
    this.formCustomer.controls.cpf.setValue(data.cpf);
    this.formCustomer.controls.phone_number.setValue(data.homePhone ? data.homePhone.areaCode + data.homePhone.number : data.phone_number);
    this.formCustomer.controls.cellphone_number.setValue(data.mobilePhone.areaCode + data.mobilePhone.number)
    this.formCustomer.controls.birthday.setValue(data.birthday);
  }

  updateCustomer() {

    let splitedPhone = (this.formCustomer.value.phone_number! as string).split(' ')
    let splitedCellphone = (this.formCustomer.value.phone_number! as string).split(' ')

    let homePhone = {
      areaCode: splitedPhone[1].slice(1, 3),
      countryCode: splitedPhone[0].slice(1, 3),
      number: splitedPhone[2].replace('-', '')
    }

    let mobilePhone = {
      areaCode: splitedCellphone[1].slice(1, 3),
      countryCode: splitedCellphone[0].slice(1, 3),
      number: splitedCellphone[2].replace('-', '')
    }

    const body = {
      first_name: this.formCustomer.value.first_name,
      last_name: this.formCustomer.value.last_name,
      email: this.formCustomer.value.email,
      cpf: this.formCustomer.value.cpf,
      homePhone: homePhone,
      mobilePhone: mobilePhone,
      birthday: this.formCustomer.value.birthday
    }

    if (this.user_id) {
      this.customersEubeboApiService.updateUser(this.user_id, body).subscribe({
        next: (res: any) => {
          const dialogRef = this.dialog.open(InformationComponent, {
            panelClass: 'container-add',
            disableClose: true,
            data: {
              error: false,
              message: 'Sucesso!'
            }
          })

          dialogRef.afterClosed().subscribe(result => {
          })
        },
        error: (err: any) => {
          const dialogRef = this.dialog.open(InformationComponent, {
            panelClass: 'container-add',
            disableClose: true,
            data: {
              error: true,
              message: err.error.message
            }
          })

          dialogRef.afterClosed().subscribe(result => {
          })
        }
      })
    }
  }

  changePassword() {
    const dialogRef = this.dialog.open(EditarSenhaPageComponent, {
      panelClass: 'container-add',
      disableClose: true,
    })

    dialogRef.afterClosed().subscribe(result => {
    })
  }

}
