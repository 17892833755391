<section>
  <div class="d-flex flex-column align-items-center justify-content-center gap-3 w-60">
    <div class="row d-flex align-items-center justify-content-center w-100">
      <h2 class="text-center">Alterar senha</h2>
    </div>
    <div class="row d-flex align-items-center justify-content-center w-100 gap-1">
      <p class="text-center">É recomendável usar uma senha forte que você não esteja usando em nenhum outro lugar.</p>
      <button mat-raised-button class="btn-password w-50" (click)="changePassword()">Alterar senha</button>

    </div>
  </div>
  <mat-divider class="division-bar"></mat-divider>
  <form [formGroup]="formCustomer">

    <div class="row d-flex align-items-center justify-content-center w-100">
      <h2>Editar dados</h2>
    </div>
    <div class="row input-group d-flex align-items-center justify-content-center w-100 gap-3">

      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Nome</mat-label>
        <input matInput formControlName="first_name">
        <mat-error *ngIf="formCustomer.controls.first_name.invalid && formCustomer.controls.first_name.touched">
          <span color="accent" *ngIf="formCustomer.controls.first_name.errors?.['required']">
            O nome é obrigatório!
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Sobrenome</mat-label>
        <input matInput formControlName="last_name">
        <mat-error *ngIf="formCustomer.controls.last_name.invalid && formCustomer.controls.last_name.touched">
          <span color="accent" *ngIf="formCustomer.controls.last_name.errors?.['required']">
            O sobrenome é obrigatório!
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100">
        <mat-label>CPF</mat-label>
        <input matInput placeholder="123.456.789-12" formControlName="cpf" mask="000.000.000-00" >
        <mat-error *ngIf="formCustomer.controls.cpf.invalid && formCustomer.controls.cpf.touched">
          <span color="accent" *ngIf="formCustomer.controls.cpf.errors?.['required']">
            O CPF é obrigatório!
          </span>
          <span class="accent" *ngIf="formCustomer.controls.cpf.status == 'INVALID'">
            {{formCustomer.controls.cpf.errors?.['message']}}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" className="w-100 ">
        <mat-label>Digite o seu telefone</mat-label>
        <div style="display: flex; flex-direction: row; align-items: center;">
          <lac-mat-country-selector
          className="h-100"
          matPrefix
          [selectorFor]="input1"
          [preferredCountries]="['br']"
          [showDialCode]="true"
          [disableSearch]="false"
          searchplaceholder="buscar..."
          >
          </lac-mat-country-selector>
          <lac-mat-tel-input #input1 formControlName="phone_number">
          </lac-mat-tel-input>
        </div>
        <mat-error *ngIf="formCustomer.controls.phone_number.invalid && formCustomer.controls.phone_number.touched">
          <span color="accent" *ngIf="formCustomer.controls.phone_number.errors?.['required']">
            O telefone é obrigatório!
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" className="w-100 ">
        <mat-label>Digite o seu celular</mat-label>
        <div style="display: flex; flex-direction: row; align-items: center;">
          <lac-mat-country-selector
          className="h-100"
          matPrefix
          [selectorFor]="input2"
          [preferredCountries]="['br']"
          [showDialCode]="true"
          [disableSearch]="false"
          searchplaceholder="buscar..."
          >
          </lac-mat-country-selector>
          <lac-mat-tel-input #input2 formControlName="cellphone_number">
          </lac-mat-tel-input>
        </div>
        <mat-error *ngIf="formCustomer.controls.cellphone_number.invalid && formCustomer.controls.cellphone_number.touched">
          <span color="accent" *ngIf="formCustomer.controls.cellphone_number.errors?.['required']">
            O celular é obrigatório!
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-100" appearance="outline">
        <mat-label>E-mail</mat-label>
        <input matInput placeholder="exemplo@exemplo.com" formControlName="email" >
        <mat-error *ngIf="formCustomer.controls.email.invalid && formCustomer.controls.email.touched">
          <span color="accent" *ngIf="formCustomer.controls.email.errors?.['required']">
            O e-mail é obrigatório!
          </span>
          <span color="accent" *ngIf="formCustomer.controls.email.errors?.['email']">
            O formato do e-mail digitado não é válido!
          </span>
        </mat-error>
      </mat-form-field>

    <mat-form-field color="accent" class="w-100" appearance="outline">
        <mat-label>Data de nascimento</mat-label>
        <input matInput [matDatepicker]="picker2" formControlName="birthday" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker #picker2 color="primary" style="z-index: 2;"></mat-datepicker>
      </mat-form-field>

    </div>
    <div class="row w-100 d-flex align-items-center justify-content-center">
      <button class="btn-login d-flex align-items-center justify-content-center w-50" mat-raised-button
        (click)="updateCustomer()" [disabled]="formCustomer.invalid">Salvar</button>
    </div>
  </form>
</section>
