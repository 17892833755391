import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'cepMask'})
export class CepPipe implements PipeTransform {
  transform(value: string): string {
    // Verifica se o valor é nulo ou vazio
    if (!value) {
      return '';
    }

    // Remove qualquer caractere não numérico do CEP
    const cepDigitsOnly = value.replace(/\D/g, '');

    // Verifica se o CEP tem a quantidade de dígitos esperada
    if (cepDigitsOnly.length === 8) {
      // Formata o CEP no padrão XXXXX-XXX
      return cepDigitsOnly.replace(/(\d{5})(\d{3})/, '$1-$2');
    } else {
      // Retorna o CEP original se não estiver no formato esperado
      return value;
    }
  }
}
