import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProductsEubeboApiService } from 'src/app/services/products-eubebo-api.service';
import { ProdutoPageComponent } from '../../produto-page/produto-page.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoriesEubeboApiService } from 'src/app/services/categories-eubebo-api.service';
import { CurrentAddressService } from 'src/app/services/current-address.service';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';

@Component({
  selector: 'app-see-subcategory',
  templateUrl: './see-subcategory.component.html',
  styleUrls: ['./see-subcategory.component.scss']
})
export class SeeSubcategoryComponent implements OnInit {
  @Input() subcategoryData: any
  @Input() seller_id: any
  @Output() loadingState: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading: boolean = true

  allProductsBySubcategory: any = []
  allProductsBySubcategoryFiltered: any = []

  category_id: string = ''
  category: any = {}

  latitude: string | null = null
  longitude: string | null = null

  user_id:any = null;
  favorites: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private currentAddressService: CurrentAddressService,
    private productsEubeboApiService: ProductsEubeboApiService,
    private categoriesEubeboApiService: CategoriesEubeboApiService,
    private authUserService: AuthUserService,
    private customersEubeboApiService: CustomersEubeboApiService,
  ) {
   
    const url = this.route.snapshot.url

    
    this.user_id = this.authUserService.getValue(this.authUserService.userId)
    this.category_id = url[3].path
  }

  ngOnInit(): void {
  
    this.latitude = this.currentAddressService.getValue(this.currentAddressService.address_lat)
    this.longitude =  this.currentAddressService.getValue(this.currentAddressService.address_long)
    this.getCategory()
    this.getAllProductsBySubcategory()
    this.getUser();
  }

  openSubcategoryPage(subcategory_id: any){

  }

  getAllProductsBySubcategory(): void {
    this.productsEubeboApiService.getAllProductsBySellerCategoryAndSubcategory(this.seller_id, this.category.slug, this.subcategoryData.slug, 1, 9999, this.latitude, this.longitude, 'Delivery').subscribe((res:any) => {
      this.allProductsBySubcategory.push(res)
      this.loading = false
     
    })
  }

  getCategory(): void {
    this.categoriesEubeboApiService.getCategoryById(this.category_id).subscribe({
      next: (res: any) => {
        this.category = res
      },
      error: (err: any) => {},
    })
  }

  openDialog(item_id: string) {
    const dialogRef = this.dialog.open(ProdutoPageComponent, {
      panelClass: 'container-product',
    
      data: {
        item_id: item_id
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })
  }

  calculateSubmenuItemsPrice(base_price: number, discount: number) {
    return ((base_price - (base_price * (discount / 100))) / 100)
  }

  doesCategoryHaveSubcategory(category: any, subcategorySlug: string): boolean {
    return category.subcategories?.some((subcategory: any) => subcategory.slug === subcategorySlug);
  }

  getUser(){
    if (this.user_id != null) {
      this.customersEubeboApiService.getUserById(this.user_id).subscribe({
        next: (res: any) => {
          this.favorites = res.favorite_products
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }

  isFavorited(item: any){
  
    if(this.favorites && this.favorites.length != 0){
      return this.favorites.some((elem)=> item.id == elem.product_id);
    }
    return false;

  }

  togleFavority(item: any, event: any){

    event.stopPropagation();
    if(this.user_id != null){

      let favorited: any = this.favorites.find((elem)=> item.id == elem.product_id);
      if(!favorited){
        this.customersEubeboApiService.registerNewFavoriteProduct(this.user_id, {
          product_id: item.id
        }).subscribe({
          next: (res: any) => {
            this.getUser();
          },
          error: (err: any) => {
            console.log(err)
          }
        })
      } else {
        this.customersEubeboApiService.deleteFavoriteProduct(this.user_id, favorited._id).subscribe({
          next: (res: any) => {
            this.getUser();
          },
          error: (err: any) => {
            console.log(err)
          }
        })
      }
     
     
    } 
  }
}
