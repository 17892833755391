<!-- <section>
  <form [formGroup]="cardInfo">
    <mat-form-field appearance="outline">
      <mat-label>Número do cartão</mat-label>
      <input matInput formControlName="number">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Nome do titular</mat-label>
      <input matInput formControlName="holderName">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Data de expiração</mat-label>
      <input matInput type="text" mask="00/0000" formControlName="expDate">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>CVV</mat-label>
      <input matInput formControlName="cvv">
    </mat-form-field>

    <div class="addresses-container">
      <div *ngFor="let address of data.customer_addressess">
        {{address.address}}
      </div>
    </div>

    <button class="add-button" (click)="save()">Adicionar</button>
  </form>
</section>
 -->

<section>
  <h2 mat-dialog-title>Novo Cartão</h2>
  <mat-dialog-content>
     <form [formGroup]="cardInfo" class="w-100 container-fluid p-0 mt-2" style="max-width: 500px;">
        <div class="row input-group w-100 p-0 gap-3">

           <mat-form-field class="col-12 p-0 m-auto" appearance="outline">
              <mat-label>Número do cartão</mat-label>
              <mat-icon matPrefix [svgIcon]="iconCard" class="mr-3"></mat-icon>
              <input matInput formControlName="number" type="tel" [mask]="'0000 0000 0000 0000'"
                 placeholder="0000 0000 0000 0000">
              <mat-error *ngIf="cardInfo.controls['number'].invalid && cardInfo.controls['number'].touched">
                 <span color="accent" *ngIf="cardInfo.controls['number'].errors?.['required']">
                    O numero do cartão é obrigatório!
                 </span>
                 <span color="accent" *ngIf="cardInfo.controls['number'].errors?.['card']">
                    {{cardInfo.controls['number'].errors?.['message'] }}
                 </span>
              </mat-error>
           </mat-form-field>

           <mat-form-field class="col-12  p-0" appearance="outline">
              <mat-label>Nome do titular</mat-label>
              <input matInput formControlName="holderName" placeholder="Ex: José Siqueira">
              <mat-error *ngIf="cardInfo.controls['holderName'].invalid && cardInfo.controls['holderName'].touched">
                 <span color="accent" *ngIf="cardInfo.controls['holderName'].errors?.['required']">
                    O nome do titular é obrigatório!
                 </span>
              </mat-error>
           </mat-form-field>

           <div class="row">
            <mat-form-field appearance="outline" class="col-12 col-md-6">
              <mat-label>Validade</mat-label>
              <input matInput placeholder="MM/AAAA" formControlName="expDate" mask="00/0000" type="tel"
                placeholder="00/0000"/>
              <mat-hint>MM/AA</mat-hint>
              <mat-error *ngIf="cardInfo.controls['expDate'].invalid && cardInfo.controls['expDate'].touched">
                 <span color="accent" *ngIf="cardInfo.controls['expDate'].errors?.['required']">
                    O validade é obrigatório!
                </span>
                 <span color="accent" *ngIf="cardInfo.controls['expDate'].errors?.['pattern']">
                    o formato deve seguir o padrão de mês e ano
                </span>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="col-12 col-md-6 p-0 pl-md-2" appearance="outline">
              <mat-label>CVV</mat-label>
              <input matInput formControlName="cvv" placeholder="1234" mask="0009">
              <mat-icon style="cursor: pointer;" [matMenuTriggerFor]="menu" matSuffix color="primary" svgIcon="cvv">
              </mat-icon>
              <mat-error *ngIf="cardInfo.controls['cvv'].invalid && cardInfo.controls['cvv'].touched">
                 <span color="accent" *ngIf="cardInfo.controls['cvv'].errors?.['required']">
                    O cvv é obrigatório!
                </span>
              </mat-error>
            </mat-form-field>
          </div>

           <!-- <mat-form-field class="col-12 p-0 px-md-2" appearance="outline">
              <mat-label>CPF</mat-label>

              <input matInput type="tel" formControlName="cpf" placeholder="000.000.000-00" mask="000.000.000-00">
              <mat-error *ngIf="form.controls.cpf.invalid && form.controls.cpf.touched">
                 <span color="accent" *ngIf="form.controls.cpf.errors?.required">
                    O CPF é obrigatório!
                 </span>
                 <span class="accent" *ngIf="form.controls.cpf.status == 'INVALID'">
                    {{form.controls.cpf.errors.message}}
                 </span>
              </mat-error>
           </mat-form-field> -->


           <div class="row">
            <div class="col-12 text-center p-0">
               <div class="w-100 mt-3">
                  <h2>Endereço de cobrança</h2>
               </div>
            </div>
            <div class="col-12 row text-center p-0">
               <div *ngFor="let address of addresses" class="col-4 card-group p-0">
                  <app-card-address-selected  [selectedAddressId]="selectedAddress?._id" [address]="address" (emitAddress)="respAddress($event)" class="card  p-0 m-0"></app-card-address-selected>
               </div>

               <div class="col-4 p-0">
                  <a mat-button class="add-address" (click)="addAddress()">
                     <span>Adicionar endereço</span>
                  </a>
               </div>

            </div>


         </div>

        </div>

    </form>

    <mat-menu #menu="matMenu">
        <div class="p-2">
           <h3><strong>Onde encontro o cvv?</strong></h3>
           <p>CVV é um código de segurança de 3 dígitos, impresso no verso de cartões de crédito. </p>
           <p style="font-size: 0.8rem;">Importante: Em cartões American Express você encontra o CVV com 4 dígitos e na
              frente.
           </p>
        </div>
     </mat-menu>
  </mat-dialog-content>

  <mat-dialog-actions>
     <button mat-raised-button class="mt-3 mb-5 h-50 d-flex justify-content-center align-items-center add-button"
        (click)="save()">
        <span>Salvar</span>
     </button>
  </mat-dialog-actions>
</section>

