export enum StorageKey {
    AUTH_TOKEN = 'AUTH_TOKEN',
    USER_NAME = 'USER_NAME',
    USER_ID = 'USER_ID',
    USER_ROLE = 'USER_ROLE',
    CART_PRODUCT = 'CART_PRODUCT',
    AMOUNT_ITEMS_CART = 'AMOUNT_ITEMS_CART',
    ZIP_CODE = 'ZIP_CODE',
    PAYMENT_DATA = 'PAYMENT_DATA',
    COUPON = 'COUPON',
    SELLERS_PACKAGES  = 'SELLERS_PACKAGES',
    ACCEPT_COOKIES = 'ACCEPT_COOKIES',
    BILLING_ADDRESS_ID = 'BILLING_ADDRESS_ID'
}
