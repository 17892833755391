<h2 mat-dialog-title class="mt-1">Avaliação dos clientes</h2>
<div mat-dialog-content class="container">
   <div class="row mb-5">
      <mat-divider></mat-divider>
      <h3 *ngIf="ratings?.length === 0 " class="mt-4"> Sem Avaliações no momento</h3>
      <div class="mt-2 " *ngFor="let item of ratings">
         <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center justify-content-center">
               <span>
                  <mat-icon style="width:30px; height: 30px;" inline="true" color="primary" svgIcon="user"></mat-icon>
               </span>
               <h3 class="my-0 mx-2"><strong>{{item.customer?.full_name || 'Anônimo'}} </strong></h3>
            </div>
         </div>
         <app-start-rate class="ml-4" [size]="15" [clickable]="false" [rate]="item?.rate"></app-start-rate>
         <h5 class="ml-1 my-2">{{item.comment}}</h5>
         <mat-divider></mat-divider>
      </div>
   </div>
</div>
<div mat-dialog-actions>
   <div>
      <button *ngIf="totalPages !== page" mat-raised-button color="primary" (click)="nextPage()"> Ver mais </button>
   </div>
</div>