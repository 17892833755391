import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-card-payment-selected',
  templateUrl: './card-payment-selected.component.html',
  styleUrls: ['./card-payment-selected.component.scss']
})
export class CardPaymentSelectedComponent implements OnInit {

  @Input() payment: any;
  @Input() selectedPaymentId: any;
  @Output() emitPayment = new EventEmitter();

  selected = false;
  mouseover = false;
  
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.selected = this.payment?.id == this.selectedPaymentId ? true : false;
  }

  selectPayment(payment: any) {
    this.emitPayment.emit(payment);
  }

}
