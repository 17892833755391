<form [formGroup]="address_form">
<!--   <div>
    <p>Endereço principal</p>
    <input formControlName="main" type="checkbox">
  </div> -->
  <div *ngIf="isOnFirstScreen" class="first-screen">
    <p>
      <mat-form-field appearance="outline">
        <mat-label>Destinatário</mat-label>
        <input matInput formControlName="receiver_name">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>Telefone fixo</mat-label>
        <input matInput formControlName="phone_number">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>Telefone celular</mat-label>
        <input matInput formControlName="cellphone_number">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>Apelido do endereço</mat-label>
        <input matInput formControlName="address_name">
      </mat-form-field>
    </p>
  </div>

  <div *ngIf="isOnSecondScreen" class="second-screen">
    <p>
      <mat-form-field appearance="outline">
        <mat-label>CEP</mat-label>
        <input matInput formControlName="address_zipcode" numbersonly maxLength={8} (input)="inputHandle($event)">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>País</mat-label>
        <input matInput formControlName="address_country" [value]="this.address_form.value.address_country">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>Estado</mat-label>
        <input matInput formControlName="address_state" [value]="this.address_form.value.address_state">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>Cidade</mat-label>
        <input matInput formControlName="address_city" [value]="this.address_form.value.address_city">
      </mat-form-field>
    </p>
  </div>

  <div *ngIf="isOnThirdScreen" class="third-screen">
    <p>
      <mat-form-field appearance="outline">
        <mat-label>Bairro</mat-label>
        <input matInput formControlName="address_neighborhood" [value]="this.address_form.value.address_neighborhood">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>Rua/Avenida/Via</mat-label>
        <input matInput formControlName="address_street" [value]="this.address_form.value.address_street">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>Número da residência</mat-label>
        <input matInput formControlName="address_street_number">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>Complemento de endereço</mat-label>
        <input matInput formControlName="address_complementary">
      </mat-form-field>
    </p>

    <p>
      <mat-form-field appearance="outline">
        <mat-label>Referência</mat-label>
        <input matInput formControlName="address_reference">
      </mat-form-field>
    </p>
  </div>

  <div *ngIf="isOnFourthScreen" class="fourth-screen">
    <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" [streetViewControl]="false" class="map-style">
      <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragend)="markerDragEnd($event)"></agm-marker>
    </agm-map>

    <div *ngIf="isOnFourthScreen" class="button-container-final">
      <button class="button-delete" (click)="selectScreen(screenIndex - 1)">Voltar</button>
      <button class="button-confirm" (click)="sendForm()">Enviar</button>
    </div>
  </div>

  <div *ngIf="isOnFirstScreen || isOnSecondScreen || isOnThirdScreen" class="button-container">
    <button class="button-delete" (click)="selectScreen(screenIndex - 1)">Voltar</button>
    <button class="button-confirm" (click)="selectScreen(screenIndex + 1)">Avançar</button>
  </div>
</form>
