import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { AuthUserService } from 'src/app/services/auth-user.service';

@Component({
  selector: 'app-atendimento-ao-cliente-page',
  templateUrl: './atendimento-ao-cliente-page.component.html',
  styleUrls: ['./atendimento-ao-cliente-page.component.scss']
})
export class AtendimentoAoClientePageComponent implements OnInit {

  user_id: string | null = null

  options: AnimationOptions = {
    path: '/assets/images/call-center.json',
  };

  constructor(
    private router: Router,
    private authUserService: AuthUserService
  ) { }

  ngOnInit(): void {
    // this.user_id = this.authUserService.getValue(this.authUserService.userId);

    // if (this.user_id == null) {
    //   this.router.navigate(['/'])
    // }
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.setSpeed(1);
  }
}
