import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ValidatorConfirmPassword } from 'src/app/config/validators/confirmPassword';
import { ValidatorCpf } from 'src/app/config/validators/validator-cpf';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { InformationComponent } from 'src/app/shared/dialogs/information/information.component';

@Component({
  selector: 'app-editar-senha-page',
  templateUrl: './editar-senha-page.component.html',
  styleUrls: ['./editar-senha-page.component.scss']
})
export class EditarSenhaPageComponent implements OnInit {

  hideOld: boolean = true;
  hidePass: boolean = true;
  hideConfirm: boolean = true;
  request: boolean = false;

  formChangePassword = this.formBuilder.group({
    old_password: [null, [Validators.required]],
    password: [null, [Validators.required]],
    repeatPassword: [null, [Validators.required]],
  }, { validator: ValidatorConfirmPassword });

  constructor(
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private authUserService: AuthUserService,
    private dialogChangePassword: MatDialogRef<EditarSenhaPageComponent>,
    private customersEubeboApiService: CustomersEubeboApiService,
    ) {

  }

  ngOnInit(): void {
  }

  close(){
    this.dialogChangePassword.close();
  }

  save() {
    if(this.formChangePassword.invalid){
      this.formChangePassword.markAllAsTouched();
      return;
    }
    this.request = true;
    let id = this.authUserService.getValue(this.authUserService.userId);

    if (id) this.customersEubeboApiService.updateUser(id, this.formChangePassword.value).subscribe({
      next: (res: any) => {
        const dialogRef = this.dialog.open(InformationComponent, {
          panelClass: 'container-add',
          disableClose: true,
          data: {
            error: false,
            message: 'Sucesso!'
          }
        })

        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
        })

        this.request = false;
      },
      error: (err: any) => {
        const dialogRef = this.dialog.open(InformationComponent, {
          panelClass: 'container-add',
          disableClose: true,
          data: {
            error: true,
            message: err.error.message
          }
        })

        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
        })

        this.request = false;
      }
    })
  }

}
