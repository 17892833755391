import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ProductsEubeboApiService } from 'src/app/services/products-eubebo-api.service';
import { MatDialog } from '@angular/material/dialog';
import { ProdutoPageComponent } from '../../produto-page/produto-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';
import { AuthUserService } from 'src/app/services/auth-user.service';

@Component({
  selector: 'app-see-more',
  templateUrl: './see-more.component.html',
  styleUrls: ['./see-more.component.scss']
})
export class SeeMoreComponent implements OnInit {
  @Input() categoryData: any
  @Input() seller_id: any

  pathname: any

  larguraTela: number = outerWidth;

  allProductsByCategory: any = []
  favorites: any[]=[];
  user_id: any = null;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private productsEubeboApiService: ProductsEubeboApiService,
    private customersEubeboApiService: CustomersEubeboApiService,
    private authUserService: AuthUserService,
  ) {
    this.pathname =  decodeURI(location.pathname)
   
    console.log(this.pathname)
  }

  ngOnInit(): void {
    this.user_id = this.authUserService.getValue(this.authUserService.userId)
    this.getAllProductsByCategory(this.seller_id, 1, 9999, this.categoryData.slug);
    this.getUser();
  }

  calculateSubmenuItemsPrice(base_price: number, discount: number) {
    return ((base_price - (base_price * (discount / 100))) / 100).toString()
  }

  getAllProductsByCategory(seller_id: string, page: number, limit: number, category_slug: string): void {
    this.productsEubeboApiService.getAllProductsByCategoryAndSellerId(seller_id, page, limit, category_slug, 'Delivery').subscribe((res:any) => {
      if (res.is_active) {
      }
      this.allProductsByCategory.push(res)
    })
  }

  openCategoryPage(category_id: string): void {
    this.router.navigate([`${this.pathname}/${category_id}`])
  }

  openDialog(item_id: string) {
    const dialogRef = this.dialog.open(ProdutoPageComponent, {
      panelClass: 'container-product',
    
      data: {
        item_id: item_id
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })
  }

  
  isFavorited(item: any){
  
    if(this.favorites && this.favorites.length != 0){
      return this.favorites.some((elem)=> item.id == elem.product_id);
    }
    return false;

  }

  togleFavority(item: any, event: any){

    event.stopPropagation();
    if(this.user_id != null){

      let favorited: any = this.favorites.find((elem)=> item.id == elem.product_id);
      if(!favorited){
        this.customersEubeboApiService.registerNewFavoriteProduct(this.user_id, {
          product_id: item.id
        }).subscribe({
          next: (res: any) => {
            this.getUser();
          },
          error: (err: any) => {
            console.log(err)
          }
        })
      } else {
        this.customersEubeboApiService.deleteFavoriteProduct(this.user_id, favorited._id).subscribe({
          next: (res: any) => {
            this.getUser();
          },
          error: (err: any) => {
            console.log(err)
          }
        })
      }
     
     
    } 
  }

  getUser(){
    if (this.user_id != null) {
      this.customersEubeboApiService.getUserById(this.user_id).subscribe({
        next: (res: any) => {
          this.favorites = res.favorite_products
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }
}
