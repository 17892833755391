import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { Router } from '@angular/router';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { CurrentAddressService } from 'src/app/services/current-address.service';
import { CepDialogComponent } from '../cep-dialog/cep-dialog.component';

@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {

  login: FormGroup

  showPassword: boolean = false

  eubeboLogo: String = '../../../assets/images/Logo fundo claro.png';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private formBuilder: FormBuilder,
    private customersEubeboApiService: CustomersEubeboApiService,
    private authUserService: AuthUserService,
    private currentAddressService: CurrentAddressService,
  ) {
    this.login = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]]
    })
  }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.dialogRef.close()
  }

  onSubmit(): void {
    if(this.login.invalid){
      this.login.markAllAsTouched();
      return 
    }
    this.customersEubeboApiService.loginUser(this.login.getRawValue()).subscribe({
      next: (res: any) => {
        const token = res.access_token
        const data = res.data

        this.currentAddressService.removeAll();
        this.authUserService.setValue(this.authUserService.tokenKey ,token)
        this.authUserService.setValue(this.authUserService.userName ,data.first_name)
        this.authUserService.setValue(this.authUserService.userId, data.id)

        if (data?.homePhone || data?.mobilePhone) {
          this.dialogRef.close()
          this.dialog.open(CepDialogComponent, {
            data: {
              
            }
          })
        } else {
          //TODO: TERMINAR VALIDAÇÃO, REDIRECIONANDO PARA PAGINA DE EDITAR INFORMAÇÕES DO USUARIO PARA ADQUIRIR AS INFOS
          const dialogRef = this.dialog.open(ErrorDialogComponent, {
            data: {
              type: "phone-alert",
              error: "Você precisa atualizar suas informações de usuário antes de continuar"
            }
          })

          dialogRef.afterClosed().subscribe(result => {
            if (result == 'close-all') {
              this.dialogRef.close(result)
              this.dialog.open(CepDialogComponent, {
                data: {
                  
                }
              })
            }
          })

        }
      },
      error: (err: any) => {
        console.log(err)

        const dialogRef = this.dialog.open(ErrorDialogComponent, {
          data: {
            error: err
          }
        })

        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
        })
      }
    })
  }

  redirectToRegisterForgetPassword() {
    location.href = 'https://teste.eubebo.com.br/autenticacao/recuperar-senha'
  }

  redirectToRegisterPage() {
    location.href = 'https://teste.eubebo.com.br/autenticacao/cadastrar'
  }
}
