import { Injectable } from '@angular/core';
import { CartProductsList } from '../config/models/cart-products.model';

@Injectable({
  providedIn: 'root'
})
export class CartEubeboService {

  numOfProducts = 'NUMBER_OF_PRODUCTS'
  productsList = 'PRODUCTS_LIST'
  cupom = 'CUPOM'
  cupomObject = 'CUPOM_OBJECT'

  constructor() { }

  removeAllValues(){
    if(this.getValue(this.numOfProducts)){
      this.removeValue(this.numOfProducts);
    }
    
    if(this.getValue(this.cupom)){
      this.removeValue(this.cupom);
    }
    
    if(this.getValue(this.cupomObject)){
      this.removeValue(this.cupomObject);
    }

    if(this.getValue(this.productsList)){
      this.removeValue(this.productsList);
    }

  }

  setValue(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  setSession(key: string, value: any) {
    value = JSON.stringify(value);
    sessionStorage.setItem(key, value);
}

  getValue(key: string): string | null {
    return localStorage.getItem(key);
  }

  getSession(key: string): any {
    const value = sessionStorage.getItem(key);
    return JSON.parse(value!);
}

  finishSession(key: string): any {
    sessionStorage.removeItem(key)
  }

  removeValue(key: string): void {
    localStorage.removeItem(key)
  }

  addItemToCart(items: any, seller_id: any) {
    let productCounter: number = 0

    let listProductsInCartParse = localStorage.getItem(this.productsList);
    let listProductsInCart: CartProductsList = {
      data: []
    };

    if (listProductsInCartParse) {
      listProductsInCart = JSON.parse(listProductsInCartParse);
    }

    for (let i = 0; i < items.length; i++) {

      listProductsInCart.data.push({
        product_id: items[i].product_id,
        product_name: items[i].name,
        product_base_price: (items[i].price / 100).toString(),
        product_seller_id: seller_id,
        product_current_offer: items.current_offer,
        number_of_products: items[i].quantity
      });
      productCounter = items[i].quantity + productCounter
    }

    let atualCartNumber = Number(localStorage.getItem(this.numOfProducts)) + productCounter;

    localStorage.setItem(this.numOfProducts, atualCartNumber.toString());
    localStorage.setItem(this.productsList, JSON.stringify(listProductsInCart));
  }
}
