<section>

  <mat-tab-group>
    <mat-tab label="Sobre">
      <div class="about-container">
        <h4 class="mt-2">Endereço</h4>
        <h5>{{seller.address?.full_address}}</h5>
        <h5>CEP: {{seller.cep | dynamic: 'cep'}}</h5>
        <h4  class="mt-2">Outras informações</h4>
        <h5>CNPJ: {{seller.cnpj | dynamic: 'cnpj'}}</h5>
      </div>
    </mat-tab>
    <mat-tab label="Horário">
      <div class="p-3">
        <div>
          <h5 class="m-2">Segunda-feira</h5>
          <div class="d-flex flex-column align-items-center" *ngFor="let time of openingHoursMonday">
            de {{time.start}} às {{time.end}}
          </div>
          <div class="d-flex flex-column align-items-center" *ngIf="openingHoursMonday.length == 0">Não abre</div>
        </div>

        <div>
          <h5 class="m-2">Terça-feira</h5>
          <div class="d-flex flex-column align-items-center" *ngFor="let time of openingHoursTuesday">
            de {{time.start}} às {{time.end}}
          </div>
          <div class="d-flex flex-column align-items-center" *ngIf="openingHoursTuesday.length == 0">Não abre</div>
        </div>

        <div>
          <h5 class="m-2">Quarta-feira</h5>
          <div class="d-flex flex-column align-items-center" *ngFor="let time of openingHoursWednesday">
            de {{time.start}} às {{time.end}}
          </div>
          <div class="d-flex flex-column align-items-center" *ngIf="openingHoursWednesday.length == 0">Não abre</div>
        </div>

        <div>
          <h5 class="m-2">Quinta-feira</h5>
          <div class="d-flex flex-column align-items-center" *ngFor="let time of openingHoursThursday">
            de {{time.start}} às {{time.end}}
          </div>
          <div class="d-flex flex-column align-items-center" *ngIf="openingHoursThursday.length == 0">Não abre</div>
        </div>

        <div>
          <h5 class="m-2">Sexta-feira</h5>
          <div class="d-flex flex-column align-items-center" *ngFor="let time of openingHoursFriday">
            de {{time.start}} às {{time.end}}
          </div>
          <div class="d-flex flex-column align-items-center" *ngIf="openingHoursFriday.length == 0">Não abre</div>
        </div>

        <div>
          <h5 class="m-2">Sábado</h5>
          <div class="d-flex flex-column align-items-center" *ngFor="let time of openingHoursSaturday">
            de {{time.start}} às {{time.end}}
          </div>
          <div class="d-flex flex-column align-items-center" *ngIf="openingHoursSaturday.length == 0">Não abre</div>
        </div>

        <div>
          <h5 class="m-2">Domingo</h5>
          <div class="d-flex flex-column align-items-center" *ngFor="let time of openingHoursSunday">
            de {{time.start}} às {{time.end}}
          </div>
          <div class="d-flex flex-column align-items-center" *ngIf="openingHoursSunday.length == 0">Não abre</div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>

</section>
