import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ChatsService } from 'src/app/services/chats.service';
import { OrdersEubeboApiService } from 'src/app/services/orders-eubebo-api.service';
import { SellerEubeboApiService } from 'src/app/services/sellers-eubebo-api.service';
import { StorageKey } from 'src/app/services/storage/storage.model';
import { StorageService } from 'src/app/services/storage/store.service';
import { DynamicPipe } from 'src/app/config/pipe/dynamic-pipes/dynamic.pipe';
import { AuthUserService } from 'src/app/services/auth-user.service';

const { USER_ID } = StorageKey;
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [DynamicPipe]
})
export class ChatComponent implements OnInit {
  innerWidth: number;
  order_id: any;
  order_number: string = '';
  order: any;

  customer_id: string;
  productDevolution: any;
  product_id: any;

  inputMessage: string = '';
  seller_id: any;
  chat: any;
  promesseChat: Subscription | any;
  seller: any;

  constructor(
    private sellerService: SellerEubeboApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private orderService: OrdersEubeboApiService,
    private storage: StorageService,
    private chatsService: ChatsService,
    private dynamicPipe: DynamicPipe,
    private authUserService: AuthUserService,
  ) {
    this.innerWidth = window.innerWidth;
    this.customer_id = this.storage.read(this.authUserService.getValue(this.authUserService.userId))
    this.activatedRoute.params.subscribe(data=> {

      this.order_id = data['order_id']  || '';
      this.seller_id = data['seller_id']  || '';
      this.product_id = data['product_id']  || '';
      this.getChat();

    });
   }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
  }

  observeChat(chat_id: any){
    this.promesseChat = this.chatsService.observeChat(chat_id).subscribe({
      next: (res: any) => {
        this.chat.messages.push({...res, created_at: this.getNowIso() })

      }
    })
  }

  getNowIso(){
    return new Date().toISOString();
  }

  getChat(){

    this.orderService.getChatByOrderAndSeller(this.order_id,  this.seller_id).then((res: any) => {
      this.chat = res;

      this.orderService.getById(res.order).subscribe({
        next: (res: any) => {
          this.chat.order = res;

          if(this.product_id !== '') {
            this.getProductDevolution();
          }

          this.observeChat(this.chat._id);
        },
        error: (err: any) => {

        },
      })
    }).catch((error: any) => {

    })

  }




  getSeller(store_id: any){
    this.sellerService.getById(store_id, null, null).subscribe({
      next: (res: any) => {
        this.seller = res;
      },
      error: (err: any) => {

      }
    })
  }

  sendMessage(){
    const message = {
      from: 'customer',
      message: this.inputMessage
    }
    this.chatsService.sendMessage(this.chat._id, {message} ).then(data => {
      this.inputMessage = '';
    });
  }

  checkChat( message: any , index: any ){

    if(index > 0 && message?.created_at ) {
      const dateCurrent = new Date(message.created_at);
      const dateBeffore = new Date(this.chat.messages[index - 1].created_at)
      if( dateCurrent.getDate() !== dateBeffore.getDate() ){
        return true;
      } else {
        return false;
      }
    }
    return true;
  }
  ngOnDestroy(){
    this.chatsService.unsubscribeChat(this.chat._id)
    if( this.promesseChat){
      this.promesseChat.unsubscribe();
    }
  }

  getProductDevolution() {
    this.productDevolution = this.chat.order.items.find((item: any) => item.product_id == this.product_id);
    this.generateMessageDevolution();
  }

  generateMessageDevolution() {
    this.inputMessage = `Solicito a troca ou devolução do produto "${this.productDevolution.quantity} X ${this.productDevolution.name}", referente ao Pedido ${this.chat.order.order_number} adquirido em ${this.dynamicPipe.fullDate(this.chat.order.created_at)}.`;
  }


}
