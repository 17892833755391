import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-aviso-endereco-principal',
  templateUrl: './aviso-endereco-principal.component.html',
  styleUrls: ['./aviso-endereco-principal.component.scss']
})
export class AvisoEnderecoPrincipalComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/images/alert.json',
  };

  constructor() { }

  ngOnInit(): void {
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.setSpeed(1);
  }
}
