<section>
  <h1>Favoritos</h1>
  <!-- <app-grid *ngIf="!isLoading" [itemsPerRow]="7" [items]="user_favorite_products"></app-grid> -->
  <div class="row">
    <div class="col-6 col-sm-4 col-md-3 col-lg-2 mb-3" style="cursor: pointer;" *ngFor="let itemProduct of user_favorite_products" (click)="openProduct(itemProduct?.product_id)">
      <div class="">
        <span *ngIf="itemProduct?.is_just_liquid" style="background-color: #d8d8d8af; border-radius: 5px;" class="position-absolute top-1 ms-1 mt-1 p-1 ">
          <mat-icon  svgIcon="retornavel"></mat-icon>
        </span>
        <img class="card-product-image" src="{{itemProduct.product_picture_url}}" alt="">
      </div>
      <div class="card-product-detail">
    
        <p style="min-height: 75px;"class="item-info-nome">{{itemProduct?.product_name  | truncate:['40']}}</p>
        
      </div>
    </div>
  </div>
</section>
