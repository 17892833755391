<section>
  <div class="left-container">
    <ng-lottie width="70vh" height="70vh" [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
  </div>
  <div class="right-container">
    <div class="text-container">
      <h1 class="text-title">WhatsApp:</h1>
      <p class="text-content">(37) 99815-5803</p>
    </div>
    <div class="text-container">
      <h1 class="text-title">E-mail:</h1>
      <p class="text-content">contato@eubebo.com.br</p>
    </div>
  </div>
</section>
