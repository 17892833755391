import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SellerEubeboApiService } from 'src/app/services/sellers-eubebo-api.service';

@Component({
  selector: 'app-store-ratings',
  templateUrl: './store-ratings.component.html',
  styleUrls: ['./store-ratings.component.scss']
})
export class StoreRatingsComponent implements OnInit {
  seller_id: any = '';

  ratings: any[] = []
  page = 1;
  limit = 10;

  totalPages = 1;

  constructor(private sellerEubeboApiService: SellerEubeboApiService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.seller_id = data.seller_id
    this.getRatings();
  }

  ngOnInit(): void {
  }

  getRatings(){
    this.sellerEubeboApiService.getAllRatingPaginate(this.seller_id, this.page, this.limit).subscribe({
      next: (res)=> {
        this.ratings.push(...res.items);
        this.totalPages = res.meta.totalPages
      }
    })
  }

  nextPage(){
    this.page++;
    this.getRatings();
  }

}
