import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';
import { InformationComponent } from 'src/app/shared/dialogs/information/information.component';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit {

  customer_id: any

  cardInfo: FormGroup
  iconCard = 'credit-card';

  addresses: any[] = []
  selectedAddress: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreditCardComponent>,
    private formBuilder: FormBuilder,
    private authUserService: AuthUserService,
    private customersEubeboApiService: CustomersEubeboApiService,
  ) {
    this.customer_id = this.data.customer_id

    this.addresses = data.customer_addressess

    this.cardInfo = this.formBuilder.group({
      number: [null],
      holderName: [null],
      expDate: [null, [Validators.minLength(6)]],
      cvv: [null],
      billingAddressId: [null]
    })

    this.checkBrandCard();
  }

  ngOnInit(): void {
  }

  addAddress(){
    this.dialogRef.close();
  }

  respAddress(address: any){

    if(address && address?._id ){

      if(!address.address?.pagarme_address_id) {
        this.dialog.open(InformationComponent, {
          disableClose: true,
          data: {
            error: true,
            message: 'Endereço precisa ser atualizado!'
          }

        })
        return
      }

      this.cardInfo.get('billingAddressId')?.patchValue(address?._id)
      this.selectedAddress = address
    } else {
      this.selectedAddress = null;
      this.cardInfo.get('billingAddressId')?.patchValue(null);
    }

  }

  checkBrandCard(){
    let visaRegex: RegExp =  new RegExp(/^4[0-9]{6,}$/);
    let masterRegex: RegExp =  new RegExp(/^5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720$/);
    let americanExpressRegex: RegExp =  new RegExp(/^3[47][0-9]{5,}$/);
    let dinnersRegex: RegExp =  new RegExp(/^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/);
    let discoverRegex: RegExp =  new RegExp(/^6(?:011|5[0-9]{2})[0-9]{3,}$/);
    let jcbRegex: RegExp =  new RegExp(/^(?:2131|1800|35[0-9]{3})[0-9]{3,}$/);

    this.cardInfo.get('number')?.valueChanges.subscribe(data => {
      if(visaRegex.test(data)){
        this.iconCard = 'visa'
      } else if( masterRegex.test(data)){
        this.iconCard = 'mastercard';
      } else if( americanExpressRegex.test(data)){
        this.iconCard = 'american-express';
      } else if( dinnersRegex.test(data)){
        this.iconCard = 'dinners-club';
      } else if( discoverRegex.test(data)){
        this.iconCard = 'discover';
      } else if( jcbRegex.test(data)){
        this.iconCard = 'jcb';
      } else {
        this.iconCard = 'credit-card';
      }
    })
  }

  save() {
    let splitedMonth = Number(this.cardInfo.value.expDate.substr(0, 2))
    let splitedYear = this.cardInfo.value.expDate.substr(2, 4)

    let body = {
      number: this.cardInfo.value.number,
      holderName: this.cardInfo.value.holderName,
      expMonth: splitedMonth,
      expYear: splitedYear,
      cvv: this.cardInfo.value.cvv,
      billingAddressId: this.cardInfo.value.billingAddressId
    }

    this.customersEubeboApiService.registerNewCreditCard(this.customer_id, body).subscribe({
      next: (res: any) => {
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }
}
