import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CartDialogComponent } from '../cart-dialog/cart-dialog.component';
import { CartEubeboService } from 'src/app/services/cart-eubebo.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CouponsEubeboApiService } from 'src/app/services/coupons-eubebo-api.service';
import { InformationComponent } from 'src/app/shared/dialogs/information/information.component';
import { CupomErrorComponent } from './cupom-error/cupom-error.component';

@Component({
  selector: 'app-cupom-dialog',
  templateUrl: './cupom-dialog.component.html',
  styleUrls: ['./cupom-dialog.component.scss']
})
export class CupomDialogComponent implements OnInit {

  user_id: string | null = null
  cupom: string | null = null
  cupomForm: FormGroup

  isOnMenu: boolean = false
  isEditingCupom: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CupomDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private authUserService: AuthUserService,
    private cartEubeboService: CartEubeboService,
    private couponsEubeboApiService: CouponsEubeboApiService,
  ) {
    this.cupomForm = this.formBuilder.group({
      cupom: [null],
    })
  }

  ngOnInit(): void {
    this.user_id = this.authUserService.getValue(this.authUserService.userId)
    this.cupom = this.cartEubeboService.getValue(this.cartEubeboService.cupom)
    this.cupom == null || this.cupom == 'null' ? this.cupom = '' : this.cupom == this.cupom

    if (!this.cupom) {
      this.isEditingCupom = true
    } else {
      this.isOnMenu = true
    }
  }

  adicionarCupom(): void {
    this.getCupom()
  }

  editCupom() {
    this.isEditingCupom = true
    this.isOnMenu = false
  }

  close(screen: any) {
    if (screen != 'checkout') {
      this.closeModal()
      this.openModal('cart')
    } else if (screen == 'checkout') {
      this.closeModal()
    }
  }

  removeCupom() {
    this.cartEubeboService.removeValue(this.cartEubeboService.cupom)
    this.cartEubeboService.finishSession(this.cartEubeboService.cupomObject)
    this.dialogRef.close()

    if (this.data.screen != 'checkout') {
      this.openModal('cart')
    } else {
      this.close('checkout')
    }
  }

  getCupom(){
    if(this.cupomForm.controls['cupom'].value == '' || this.cupomForm.controls['cupom'].value == null || this.cupomForm.controls['cupom'].value == undefined){
      this.cupomForm.markAllAsTouched();
      return
    }

    if(!this.user_id){
      const dialogRef = this.dialog.open(InformationComponent, {
        disableClose: true,
        panelClass: 'container-add',
        data: {
          error: true,
          message: `É preciso estar logado. Favor logar e tentar novamente.`
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })

      return
    }

    this.couponsEubeboApiService.getByCode(this.cupomForm.controls['cupom'].value).subscribe({
      next: (res: any) => {
      this.cupom =  res;

      if (this.data.subtotal >= res.order_min_total) {
        this.cartEubeboService.setSession(this.cartEubeboService.cupomObject, res)
        this.cartEubeboService.setValue(this.cartEubeboService.cupom, this.cupomForm.value.cupom)
        if (this.data.screen != 'checkout') {
          this.openModal('cart')
        } else {
          this.close('checkout')
        }
      } else {
        const dialogRef = this.dialog.open(CupomErrorComponent, {
          disableClose: true,
          panelClass: 'container-add',
          data: {
            error: true,
            order_min_total: res.order_min_total
          }
        })

        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
        })
      }
      },
      error: (err: any) => {
        const dialogRef = this.dialog.open(InformationComponent, {
          disableClose: true,
          panelClass: 'container-add',
          data: {
            error: true,
            message: err.error.message
          }
        })

        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
        })
      }
    })
  }

  openModal(component: string): void {
    if (component == 'cart') {
      const dialogRef = this.dialog.open(CartDialogComponent, {
        data: {
          animal: 'panda',
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    }
  }

  closeModal(): void {
    this.dialogRef.close()
  }
}
