<section>
  <div (click)="backPage()" class="back-button-container">
    <mat-icon>keyboard_backspace</mat-icon>
    <p>Voltar</p>
  </div>
  <div class="d-flex justify-content-center align-items-center mt-5" *ngIf="hasItems ">
    <app-nothing-found-screen></app-nothing-found-screen>
  </div>
  <app-see-subcategory (loadingState)="onLoadingStateChanged($event)" *ngFor="let subcategory of allSubcategories" [subcategoryData]="subcategory" [seller_id]="seller_id"></app-see-subcategory>
</section>
