import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChatRoutingModule } from './chat-routing.module';
import { ChatComponent } from './chat.component';
import { FormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { DynamicPipeModule } from 'src/app/config/pipe/dynamic-pipes/dynamic.module';
import { MatButtonModule } from '@angular/material/button';
import { StartRateModule } from './start-rate/start-rate.module';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    ChatComponent
  ],
  imports: [
    CommonModule,
    ChatRoutingModule,
    FormsModule,
    MatDividerModule,
    DynamicPipeModule,
    MatButtonModule,
    StartRateModule,
    MatIconModule,
  ]
})
export class ChatModule { }
