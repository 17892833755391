<section>
  <div class="d-flex align-items-end justify-content-end">
    <mat-icon mat-dialog-close (click)="close()">close</mat-icon>
  </div>
  <h1 class="text-center" mat-dialog-title>Alterar senha</h1>
  <div mat-dialog-content>

    <form [formGroup]="formChangePassword">
      <div class="input-group mb-2 d-flex align-items-center justify-content-center gap-2 p-1">

        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Digite a sua senha</mat-label>
          <input matInput [type]="hideOld ? 'password' : 'text'" formControlName="old_password" (keyup.enter)="save()">
          <button style=" border: none; border-radius: 25px; background-color: transparent;" class="w-auto h-100 p-2" matSuffix (mouseup)="hideOld = !hideOld" >
            <mat-icon>{{hideOld ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error
            *ngIf="formChangePassword.controls['old_password'].invalid && formChangePassword.controls['old_password'].touched">
            <span color="accent" *ngIf="formChangePassword.controls['old_password'].errors?.['required']">
              A senha é obrigatória!
            </span>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Digite uma nova senha</mat-label>
          <input matInput [type]="hidePass ? 'password' : 'text'" formControlName="password" (keyup.enter)="save()">
          <button style=" border: none; border-radius: 25px; background-color: transparent;" class="w-auto h-100 p-2"  matSuffix (mouseup)="hidePass = !hidePass" >
            <mat-icon >{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="formChangePassword.controls['password'].invalid && formChangePassword.controls['password'].touched">
            <span color="accent" *ngIf="formChangePassword.controls['password'].errors?.['required']">
              A senha é obrigatória!
            </span>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Confirme a sua nova senha</mat-label>
          <input matInput  [type]="hideConfirm ? 'password' : 'text'" formControlName="repeatPassword" (keyup.enter)="save()">
          <button style=" border: none; border-radius: 25px; background-color: transparent;" class="w-auto h-100 p-2" matSuffix (mouseup)="hideConfirm = !hideConfirm">
            <mat-icon >{{hideConfirm ? 'visibility_off' : 'visibility'}}</mat-icon>
          </button>
          <mat-error *ngIf="formChangePassword.controls['repeatPassword'].invalid && formChangePassword.controls['repeatPassword'].touched">
            <span color="accent" *ngIf="formChangePassword.controls['repeatPassword'].errors?.['required']">
              A confirmação de senha é obrigatória!
            </span>
          </mat-error>
        </mat-form-field>

      </div>

      <span class="span-error w-100" *ngIf="formChangePassword.errors?.['confirmPassword']">
        As senhas não conferem!
      </span>

    </form>


  </div>
  <div mat-dialog-actions class="d-flex">
    <button mat-raised-button class="btn-save w-100" (click)="save()" >Salvar</button>
    <!-- <button mat-raised-button class="btn-cancel ml-2" (click)="close()" type="button">Cancelar</button> -->
  </div>

</section>
