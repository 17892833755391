import { Component, ElementRef, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/images/wine-bottle.json',
  };
  innerWidth: number = 0;

  constructor(
    private eRef: ElementRef
  ) {
    this.innerWidth = window.innerWidth;
   }

  ngOnInit(): void {
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.setSpeed(1);
  }

}
