import { Component, OnInit, DoCheck } from '@angular/core';
import { ProductsEubeboApiService } from 'src/app/services/products-eubebo-api.service';
import { CategoriesEubeboApiService } from 'src/app/services/categories-eubebo-api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SellerEubeboApiService } from 'src/app/services/sellers-eubebo-api.service';
import { CurrentAddressService } from 'src/app/services/current-address.service';
import { MatDialog } from '@angular/material/dialog';
import { MetaSeoService } from 'src/app/services/meta-seo.service';
import { environment } from 'src/environments/environment';
import { TruncatePipe } from 'src/app/config/pipe/pipe-truncate/truncate.pipe';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { StoreInfosDialogComponent } from './store-infos-dialog/store-infos-dialog.component';
import { StoreRatingsComponent } from './store-ratings/store-ratings.component';

@Component({
  selector: 'app-lojas-page',
  templateUrl: './lojas-page.component.html',
  styleUrls: ['./lojas-page.component.scss'],
  providers: [
    TruncatePipe
  ]
})
export class LojasPageComponent implements OnInit, DoCheck {
  pageInfos: any = {
    title: 'Vendola - Delivery',
    metaTags : [
      { name: 'description', content: 'Bebidas para toda hora.' },
      { property: 'og:title', content: 'Vendola - Delivery' },
      { proprety: 'og:description', content: 'Bebidas para toda hora.' },
      { property: 'og:image', content: environment.url_api + 'assets/image/Sìmbolo-roxo.png' },
      { property: 'og:url', content: environment.url_api  },
      { name: "twitter:card", content: "summary_large_image" },
    ]
  }

  options: AnimationOptions = {
    path: '/assets/images/search.json',
  };

  loading: boolean = true
  innerWidth: number = window.innerWidth

  openingHoursMonday: any[] = []
  openingHoursTuesday: any[] = []
  openingHoursWednesday: any[] = []
  openingHoursThursday: any[] = []
  openingHoursFriday: any[] = []
  openingHoursSaturday: any[] = []
  openingHoursSunday: any[] = []
  seller: any = {}
  seller_id: any = ''
  sellerCategories: any = []
  sellerCategoriesFiltered: any = []
  allProducts: any = []
  allProductsBySeller: any = []
  allCategories: any = []

  lat: any
  lng: any

  pathname: any

  days_of_week = [
    {
      number: 1,
      day: 'Segunda-feira'
    },
    {
      number: 2,
      day: 'Terça-feira'
    },
    {
      number: 3,
      day: 'Quarta-feira'
    },
    {
      number: 4,
      day: 'Quinta-feira'
    },
    {
      number: 5,
      day: 'Sexta-feira'
    },
    {
      number: 6,
      day: 'Sábado'
    },
    {
      number: 7,
      day: 'Domingo'
    },
  ]

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private categoriesEubeboApiService: CategoriesEubeboApiService,
    private sellerEubeboApiService: SellerEubeboApiService,
    private productsEubeboApiService: ProductsEubeboApiService,
    private currentAddressService: CurrentAddressService,
    private metaSeoService: MetaSeoService,
    private truncate: TruncatePipe,
    ) {
      this.lat = this.currentAddressService.getValue(this.currentAddressService.address_lat)
      this.lng = this.currentAddressService.getValue(this.currentAddressService.address_long)

      this.pathname = decodeURI(location.pathname)
      const url = this.route.snapshot.url

      this.seller = decodeURI(url[1].path)
      this.seller_id = url[2].path

      console.log(this.pathname);
      console.log(this.seller);
      console.log(this.seller_id);

      if(window?.location?.pathname){
        this.pageInfos.newTitle = this.seller.replace(/%20/g, ' ') + ' - eubebo';
        this.pageInfos.metaTags = [
          { property: 'og:title', content: this.truncate.transform(this.seller, ['60', '...']) +' - eubebo'  },
          { property: 'og:url', content: window.location.href},
          { name: "twitter:card", content: "summary_large_image" },
        ]
        this.setTitleInMetas(this.pageInfos);
      }
    }

  ngOnInit(): void {
    this.getAllCategories()
    this.getSellerById()
  }

  ngDoCheck(): void {
    if (this.allCategories != null && this.allProductsBySeller != null) {
      for (let i = 0; i < this.allCategories.length; i++) {
        const category = this.allCategories[i];
        const categoryItems = this.allProductsBySeller.filter((item: any) => {
          return category.slug == item.category.slug && item.is_active;
        });

        if (categoryItems.length > 0 && !this.sellerCategoriesFiltered.some((filteredCategory: any) => filteredCategory.slug === category.slug)) {
          this.sellerCategoriesFiltered.push({
            slug: category.slug,
            id: category.id
          });
        }
      }
    }
  }

  public setTitleInMetas({newTitle , metaTags }: any) {
    this.metaSeoService.updateTitle(newTitle);
    this.metaSeoService.updateMetaTags(metaTags)
  }

  getUniqueSellerCategories(sellerCategories: any[]): any[] {
    const uniqueCategories: any = [];
    sellerCategories.forEach(item => {
      if (!uniqueCategories.some((cat: any) => cat.slug === item.slug)) {
        uniqueCategories.push(item);
      }
    });
    return uniqueCategories;
  }

  getSellerById(): void {
    this.sellerEubeboApiService.getById(this.seller_id, this.lat, this.lng).subscribe({
      next: (res: any) => {
        this.seller = res

        this.seller.opening_hours.forEach((item: any) => {
          switch (item.days_of_week) {
            case 1:
              this.openingHoursMonday.push(item)
              break
            case 2:
              this.openingHoursTuesday.push(item)
              break
            case 3:
              this.openingHoursWednesday.push(item)
              break
            case 4:
              this.openingHoursThursday.push(item)
              break
            case 5:
              this.openingHoursFriday.push(item)
              break
            case 6:
              this.openingHoursSaturday.push(item)
              break
            case 7:
              this.openingHoursSunday.push(item)
              break
          }
        })

        if (res == null) {
          this.router.navigate(['/nao-encontrado'])
        }
        this.getAllProductsBySeller()
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getAllCategories(): void {
    this.categoriesEubeboApiService.getAll().subscribe((res:any) => {
      this.allCategories = res;
    })
  }

  getAllProductsBySeller(): void {
    this.productsEubeboApiService.getAllProductsBySellerId(this.seller_id, 1, 9999, 'Delivery').subscribe((res: any) => {
      this.allProductsBySeller = res.items
      this.loading = false
    })
  }

  openCategoryPage(category_id: string): void {
    this.router.navigate([`${this.pathname}/${category_id}`])
  }

  openModal(component: string): void {
    if (component == 'store-infos') {
      const dialogRef = this.dialog.open(StoreInfosDialogComponent, {
        data: {
          seller: this.seller,
          monday: this.openingHoursMonday,
          tuesday: this.openingHoursTuesday,
          wednesday: this.openingHoursThursday,
          thursday: this.openingHoursThursday,
          friday: this.openingHoursFriday,
          saturday: this.openingHoursSaturday,
          sunday: this.openingHoursSunday
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    }
  }

  convertNumberToDay(day: any) {
    switch (day) {
      case 1:
        return 'Segunda-feira'
      case 2:
        return 'Terça-feira'
      case 3:
        return 'Quarta-feira'
      case 4:
        return 'Quinta-feira'
      case 5:
        return 'Sexta-feira'
      case 6:
        return 'Sábado'
      case 7:
        return 'Domingo'
      default:
        return '?'
    }
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.setSpeed(1);
  }

  onViewRatings(){

    this.dialog.open(StoreRatingsComponent, {
      data: {
        seller_id: this.seller_id,
        
      } ,
      width: '100',
    } )
    
  }
}
