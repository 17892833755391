// @ts-ignore
import pagarme from 'pagarme/browser'
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidatorCards } from 'src/app/config/validators/validator-cards';
import { ValidatorCpf } from 'src/app/config/validators/validator-cpf';
import { environment } from 'src/environments/environment';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { DynamicPipe } from 'src/app/config/pipe/dynamic-pipes/dynamic.pipe';

@Component({
  selector: 'app-card-hash-creator',
  templateUrl: './card-hash-creator.component.html',
  styleUrls: ['./card-hash-creator.component.scss']
})
export class CardHashCreatorComponent implements OnInit {

  @Output() emitPayment = new EventEmitter();

  form: FormGroup;
  userId: any;
  cards: any;

  constructor(
    private formBuilder : FormBuilder,
    private authUserService: AuthUserService,
    private dynamicPipe: DynamicPipe,
    private customersEubeboApiService: CustomersEubeboApiService,
  ) {
    this.userId = this.authUserService.getValue(this.authUserService.userId)
    this.form = this.formBuilder.group({
      card_number: ['',[Validators.required, ValidatorCards]],
      card_expiration_date: ['', [Validators.required, Validators.pattern('0[1-9]|1[0-2][0-9]{2}')] ],
      card_holder_name: ['',[Validators.required]],
      card_cvv: ['', [Validators.required]],
      installment: ['', [Validators.required]],
      cpf: ['', [Validators.required, ValidatorCpf]],
      save_card: [ false, [Validators.required]],
    })
  }

  ngOnInit(): void {
  }

  getCards(){
    this.customersEubeboApiService.getUserById(this.userId).subscribe({
      next: (res: any) => {
        this.cards = res.cards
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }
}
