import { Component, OnInit, DoCheck, Output, EventEmitter, HostListener } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CepDialogComponent } from './cep-dialog/cep-dialog.component';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { CartDialogComponent } from './cart-dialog/cart-dialog.component';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CurrentAddressService } from 'src/app/services/current-address.service';
import { CartEubeboService } from 'src/app/services/cart-eubebo.service';
import { CategoriesEubeboApiService } from 'src/app/services/categories-eubebo-api.service';
import { InformationComponent } from 'src/app/shared/dialogs/information/information.component';
import { EnderecosPageComponent } from 'src/app/pages/enderecos-page/enderecos-page.component';

export interface itemDropDown {
  id: string;
  title: string;
  subtitles: any[];
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, DoCheck {

  @Output() searchEvent = new EventEmitter<string>()

  isMenuOpen: boolean = false

  openSidenav: boolean = false;
  searchBar: FormGroup;

  innerWidth: number;
  innerHeight: number;

  loading: boolean = true;

  categoryDropDown: itemDropDown = {id: '', title: '', subtitles: []};

  panelOpenState = false;
  panelSubOpenState = false;
  statusAwaitDropDown = false;

  //Imagens
  eubeboLogo: String = '../../../assets/images/logo-delivery-com-nome.png';
  eubeboLogoBranca: String = '../../../assets/images/logo-delivery-transparente.png';

  // User Infos
  user_name: string | null = null
  zipcode: string | null = null
  neighborhoodAddress: string | null = null
  street: string | null = null
  street_number: string | null = null
  residence_number: string | null = null
  number_of_products: any | null = null
  lat: string | null = null
  long: string | null = null
  products_list: any | null = null

  categoriesAllList: any[] = [];
  buyCategoriesList: any[] = [];
  categoryList: any;
  acceptCookies: boolean = false;

  isLogged: boolean = false;
  

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private authUserService: AuthUserService,
    private cartEubeboService: CartEubeboService,
    private currentAddressService: CurrentAddressService,
    private categoriesEubeboApiService: CategoriesEubeboApiService,
    ) {
    this.searchBar = this.formBuilder.group({
      busca: [null]
    });

    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight

    this.currentAddressService.emitAddress.subscribe({
      next: (res: any) => {
        this.getAddressInfos()
      }
    })
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.innerWidth = window.innerWidth;

  }

  ngOnInit(): void {
    this.getUserInfos()
    this.getAddressInfos()
    this.getCartInfos()
    this.getCategory();
  }

  ngDoCheck(): void {
    this.user_name = this.authUserService.getValue(this.authUserService.userName);
    this.isLogged = this.user_name ? true : false;
    this.zipcode = this.currentAddressService.getValue(this.currentAddressService.zipcode)
    this.number_of_products = this.cartEubeboService.getValue(this.cartEubeboService.numOfProducts)
    this.getAddressInfos()
  }

  openDialog(component: string) {
    if (component == 'cep') {
      const dialogRef = this.dialog.open(CepDialogComponent, {
        data: {
          zipcode: this.zipcode,
          user_name: this.user_name
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    } else if (component == 'login') {
      const dialogRef = this.dialog.open(LoginDialogComponent, {
        data: {
          zipcode: this.zipcode,
          user_name: this.user_name
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        this.user_name = this.authUserService.getValue(this.authUserService.userName);
        this.isLogged = this.user_name ? true : false;
      })
    } else if (component == 'carrinho') {
      const dialogRef = this.dialog.open(CartDialogComponent, {
        data: {
          zipcode: this.zipcode,
          user_name: this.user_name
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    } else if (component == 'enderecos') {
      const dialogRef = this.dialog.open(CepDialogComponent, {
        data: {
          zipcode: this.zipcode,
          user_name: this.user_name
        }
      })

      dialogRef.afterClosed().subscribe(result => {
      })
    }
  }

  onSearch(): void {
    this.router.navigate([`busca/${this.searchBar.value.busca}`])
  }

  redirecionaMenu() {
    this.router.navigate(['/'])
  }

  redirecionaParaCategoria(category_id: string) {
    const isItem = true

    this.router.navigate([`busca`], {
      queryParams: {
        category_id: category_id,
        isItem
      }
    })
  }

  searchCategory(param: any){
    setTimeout(() => {
      this.router.navigate(['/busca'], { queryParams: { category_id: param, isItem: true }});
    }, 500);
  }

  searchSubcategory(param: any, data: any) {
    setTimeout(() => {
      this.router.navigate(['/busca'], { queryParams: { category_id: param, subcategory_id: data, isItem: true }});
    }, 500);
  }

  searchAllCategories() {
    this.router.navigate(['/busca' ], { queryParams: { order_by_price: 'ASC' } });
  }

  getCategory() {
    this.categoriesEubeboApiService.getAll().subscribe({
      next: (res: any) => {
        this.categoriesAllList = res;

        this.categoryList = res;
        this.categoryList = this.categoryList.slice( 0, 5 );
        this.orderBuyCategories(7);
        this.loading = false;
      },
      error: (err: any) => {
        const dialogRef = this.dialog.open(InformationComponent, {
          panelClass: 'container-add',
          disableClose: true,
          data: {
            error: true,
            message: err.error.message
          }
        })

        dialogRef.afterClosed().subscribe(result => {
          console.log(result)
        })
      }
    })
  }

  getUserInfos(): void {
    this.user_name = this.authUserService.getValue(this.authUserService.userName);
    this.isLogged = this.user_name ? true : false;
  }

  getAddressInfos(): void {
    this.zipcode = this.currentAddressService.getValue(this.currentAddressService.zipcode)
    this.neighborhoodAddress = this.currentAddressService.getValue(this.currentAddressService.neighborhood)
    this.street = this.currentAddressService.getValue(this.currentAddressService.street)
    this.street_number = this.currentAddressService.getValue(this.currentAddressService.street_number)
    this.residence_number = this.currentAddressService.getValue(this.currentAddressService.residence_number)
    this.lat = this.currentAddressService.getValue(this.currentAddressService.address_lat)
    this.long = this.currentAddressService.getValue(this.currentAddressService.address_long)
  }

  getCartInfos(): void {
    this.number_of_products = this.cartEubeboService.getValue(this.cartEubeboService.numOfProducts)
    this.products_list = this.cartEubeboService.getValue(this.cartEubeboService.productsList)
  }

  changeCategoryDropDown(id: any, title: any, subtitles: any){
    this.categoryDropDown.id = id;
    this.categoryDropDown.title = title;
    this.categoryDropDown.subtitles = subtitles;
  }

  logoutUser(): void {
    this.authUserService.removeAll()
    this.currentAddressService.removeAll()

    location.reload()
  }

  removeDropdownCategory() {
    this.panelOpenState=false;
    setTimeout(() => {
      if(!this.panelOpenState) {
        this.panelSubOpenState = false;
      }
    },50)
  }

  orderBuyCategories(quantity: number) {
    this.categoriesAllList.slice( 0, quantity).forEach(item => {
      item.subcategories.length > 0 ? this.buyCategoriesList.unshift(item) : this.buyCategoriesList.push(item);
    });
  }


  awaitDropDown() {
    this.statusAwaitDropDown = true;
    setTimeout(() => {
      if(this.statusAwaitDropDown) {
        this.panelOpenState=true;
        this.panelSubOpenState=false;
      }
    },500)
  }

  cookiesAccept() {
    // this.acceptCookies = true;
    // this.storage.save(ACCEPT_COOKIES, this.acceptCookies);
  }

  logout(){

  }

  login(){}
}
