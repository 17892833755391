<div class="container" *ngFor="let item of allProductsByCategory; let i = index">
  <div class="header-container" *ngIf="item.items.length != 0">
    <h1 class="header-category-title">{{categoryData.name}}</h1>
    <div class="header-division-bar"></div>
    <button class="header-see-more-button" (click)="openCategoryPage(categoryData.id)">Ver mais</button>
  </div>
  <div class="row">
   
    <div class=" col-6 col-sm-4 col-md-3 col-lg-2 " *ngFor="let itemProduct of item.items.slice(0, 5)" style="cursor: pointer;" (click)="openDialog(itemProduct.id)">
      <div class="position-relative">
        <span *ngIf="itemProduct?.is_just_liquid" style="background-color: #d8d8d8af; border-radius: 5px;" class="position-absolute top-0 ms-1 mt-1 p-1 ">
          <mat-icon  svgIcon="retornavel"></mat-icon>
        </span>
        <img class="card-product-image" src="{{itemProduct.pictures[0]?.url}}" alt="">
        <span *ngIf="user_id" style="border-radius: 5px;" (click)="togleFavority(itemProduct, $event)" class="position-absolute top-0 icon-heart p-1 ">
          <mat-icon  [svgIcon]="isFavorited(itemProduct) ? 'heart-full': 'heart-empty'"></mat-icon>
        </span>
      </div>
      <div class="card-product-detail">
        
        <p style="min-height: 50px;" class="d-flex flex-column justify-content-end mb-2 pb-0" [ngClass]="itemProduct?.current_offer ? 'h-auto': 'h-auto'">
          <span *ngIf="itemProduct?.current_offer" class="w-100 " style=" display: block;font-size: 0.8rem; color: #666">de
            <span style="text-decoration: line-through; "> {{ (itemProduct?.base_price/100) | currency:"R$" }} </span> por
          </span>
          <span *ngIf="itemProduct?.current_offer" class="w-100" style="display: block; font-size: 1rem; color: #510c76" > {{  ( itemProduct?.base_price - (itemProduct?.base_price *(itemProduct?.current_offer?.individual_discount_percentage /100) )) / 100 | currency:"R$" }}</span>
          <span *ngIf="!itemProduct?.current_offer"  class="w-100" style="display: block; font-size: 1rem; color: #510c76"> {{ (itemProduct?.base_price/100) | currency:"R$" }}</span>
        </p>
        
        <p class="item-info-nome">{{itemProduct?.display_name}}</p>
        
      </div>
    </div>
    
  </div>

</div>
