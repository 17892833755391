import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VariableBinding } from '@angular/compiler';
import { environment } from 'src/environments/environment';

const API_URL = environment.url_api

@Injectable({
  providedIn: 'root'
})
export class ProductsEubeboApiService {

  constructor(private http: HttpClient) { }

  getProduct(id: string): Observable<any> {
    return this.http.get(`${API_URL}products/${id}`)
  }

  getAllProducts(
    page: number,
    limit: number,
    latitude:string | null,
    longitude: string | null,
    search_term: string | null,
    price_to: number | null,
    price_from: number | null,
    category_slug: string | null,
    subcategory_slug: string | null,
    category_id_list: string[] | null,
    subcategory_id_list: string[] | null,
    order_by_price: string | null,
    platform_type: string | null,
    is_top_sales?: string | null,
    is_shipping_fee_free?: string| null
    ): Observable<any> {

    if (search_term == 'null') {
      search_term = null
    }

    let params =  new HttpParams();

    if(page){
      params = params.append('page', page)
    }

    if(limit){
      params = params.append('limit', limit)
    }

    if(latitude){
      params = params.append('lat', latitude)
    }

    if(longitude){
      params = params.append('long', longitude)
    }

    if(is_top_sales){
      params = params.append('is_top_sales', is_top_sales)
    }

    if (platform_type) {
      params = params.append('platform_type', platform_type)
    }

    if(search_term && search_term != null){
      params = params.append('search_term', search_term)
    }

    if(price_to && price_to != null){
      params = params.append('price_to', price_to)
    }

    if(price_from && price_from != null){
      params = params.append('price_from', price_from)
    }

    if(category_slug && category_slug != null){
      params = params.append('category_slug', category_slug)
    }

    if(category_id_list && category_id_list.length > 0) {
      category_id_list?.forEach((value, i) => {
        if (value) {
          params = params.append(`categories[${i}]`, value)
        }
      })
    }

    if(subcategory_slug && subcategory_slug != null){
      params = params.append('subcategory_slug', subcategory_slug)
    }

    if(subcategory_id_list && subcategory_id_list.length > 0) {
      subcategory_id_list?.forEach((value, i) => {
        if (value) {
          params = params.append(`subcategories[${i}]`, value)
        }
      })
    }

    if(is_shipping_fee_free){
      params = params.append('is_shipping_fee_free', is_shipping_fee_free)
    }

    if(order_by_price && order_by_price != null){
      params = params.append('order_by_price', order_by_price)
    }

    return this.http.get(`${API_URL}products`, {params})
  }

  getAllProductsBySellerId(seller_id: string, page: number, limit: number, platform_type: string | null): Observable<any> {

    let params =  new HttpParams();

    if(page || page == 0){
      params = params.append('page', page)
    }

    if(limit){
      params = params.append('limit', limit)
    }

    if (platform_type) {
      params = params.append('platform_type', platform_type)
    }

    return this.http.get(`${API_URL}products?seller_id=${seller_id}`, {params})
  }

  getAllProductsByCategoryId(category_id: string, page: number, limit: number, latitude:string | null, longitude: string | null, platform_type: string | null): Observable<any> {

    let params = new HttpParams()

    if(category_id){
      params = params.append('category_slug', category_id)
    }

    if(latitude){
      params = params.append('lat', latitude)
    }

    if(longitude){
      params = params.append('long', longitude)
    }

    if(page){
      params = params.append('page', page)
    }

    if(limit){
      params = params.append('limit', limit)
    }

    if (platform_type) {
      params = params.append('platform_type', platform_type)
    }

    return this.http.get(`${API_URL}products/`, {params})

  }

  getAllProductsByCategoryAndSellerId(id: string, page: number, limit: number, category_slug: string, platform_type: string | null): Observable<any> {


    let params =  new HttpParams();

    params = params.append('is_active', 1)

    if(page){
      params = params.append('page', page)
    }

    if(limit){
      params = params.append('limit', limit)
    }

    if(category_slug){
      params = params.append('category_slug', category_slug)
    }

    if (platform_type) {
      params = params.append('platform_type', platform_type)
    }

    return this.http.get(`${API_URL}products?seller_id=${id}`, {params})
  }

  getAllProductsBySellerCategoryAndSubcategory(seller_id: string, category_slug: string, subcategory_slug: string, page: number, limit: number, latitude:string | null, longitude: string | null, platform_type: string | null): Observable<any> {

    let params = new HttpParams()

    if(seller_id){
      params = params.append('seller_id', seller_id)
    }

    if(category_slug){
      params = params.append('category_slug', category_slug)
    }

    if(subcategory_slug){
      params = params.append('subcategory_slug', subcategory_slug)
    }

    if(latitude){
      params = params.append('lat', latitude)
    }

    if(longitude){
      params = params.append('long', longitude)
    }

    if(page){
      params = params.append('page', page)
    }

    if(limit){
      params = params.append('limit', limit)
    }

    if (platform_type) {
      params = params.append('platform_type', platform_type)
    }

    return this.http.get(`${API_URL}products/`, {params})
  }

  ratingProduct(product_id: string, body: any): Observable<any>{
    return this.http.post(`${API_URL}/products/${product_id}/ratings`, body);
  }
}
