import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/images/error-pop.json',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogErrorDialog: MatDialogRef<ErrorDialogComponent>,
    private router: Router,
  ) {

  }

  ngOnInit(): void {
    if (this.data.type === 'phone-alert') {

    }
  }

  redirectTo(route: any) {
    if (route == 'profile') {
      this.router.navigate(['/editar-perfil'])
      this.dialogErrorDialog.close('close-all')
    }
  }

  close() {
    this.dialogErrorDialog.close()
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.setSpeed(1);
  }

}
