import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_URL = environment.url_api

@Injectable({
  providedIn: 'root'
})
export class SubcategoriesEubeboApiService {

  constructor(private http: HttpClient) { }

  getAll(): Observable<any> {
    return this.http.get(API_URL + 'subcategories')
  }

  getById(id: string): Observable<any> {
    return this.http.get(API_URL + 'subcategories/' + id)
  }
}
