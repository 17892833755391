import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_URL = environment.url_api

@Injectable({
  providedIn: 'root'
})
export class CouponsEubeboApiService {

  constructor(private http: HttpClient) { }

  getAllCoupons(): Observable<any> {
    return this.http.get(API_URL + 'coupons')
  }

  getByCode(code: any): Observable<any>{
    let params = new HttpParams();

    if(code){
      params = params.append('code', code);
    }

    return this.http.get(`${API_URL}coupons/byCode/`, { params});
  }
}
