import { Component, OnInit } from '@angular/core';
import { ProductsEubeboApiService } from 'src/app/services/products-eubebo-api.service';
import { MatDialog } from '@angular/material/dialog';
import { CartEubeboService } from 'src/app/services/cart-eubebo.service';
import { CartProductsList } from 'src/app/config/models/cart-products.model';
import { AddProductErrorDialogComponent } from '../produto-page/add-product-error-dialog/add-product-error-dialog.component';
import { CurrentAddressService } from 'src/app/services/current-address.service';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { SellerEubeboApiService } from 'src/app/services/sellers-eubebo-api.service';
import { LoginDialogComponent } from 'src/app/navigation/login-dialog/login-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ProdutoPageComponent } from '../produto-page/produto-page.component';
import { environment } from 'src/environments/environment';
import { MetaSeoService } from 'src/app/services/meta-seo.service';

@Component({
  selector: 'app-produto-singlepage',
  templateUrl: './produto-singlepage.component.html',
  styleUrls: ['./produto-singlepage.component.scss']
})
export class ProdutoSinglepageComponent implements OnInit {
  pageInfos: any = {
    title: 'Vendola - Delivery',
    metaTags : [
      { name: 'description', content: 'Bebidas para toda hora.' },
      { property: 'og:title', content: 'Vendola - Delivery' },
      { proprety: 'og:description', content: 'Bebidas para toda hora.' },
      { property: 'og:image', content: environment.url_api + 'assets/image/Sìmbolo-roxo.png' },
      { property: 'og:url', content: environment.url_api  },
      { name: "twitter:card", content: "summary_large_image" },
    ]
  }

  larguraTela: number = window.innerWidth;

  user_id: string | null = null

  modalInfo: any = {}
  productCounter: number = 0
  productsInCart: any = 0

  productsByCategory: any = []

  product: any = []
  product_id: string = ''

  total_price: number = 0

  current_store_id: string = ''
  current_store: any

  latitude: string | null = null
  longitude: string | null = null

  selectedImage: any

  favorite: boolean = false
  favorites: any
  truncate: any;

  notifyCopyText: boolean = false

    constructor(
    public dialog: MatDialog,
    private router: Router,
    private productsEubeboApiService: ProductsEubeboApiService,
    private customersEubeboApiService: CustomersEubeboApiService,
    private sellerEubeboApiService: SellerEubeboApiService,
    private currentAddressService: CurrentAddressService,
    private cartEubeboService: CartEubeboService,
    private authUserService: AuthUserService,
    private metaSeoService: MetaSeoService,
    private route: ActivatedRoute,
    ) {
      const url = this.route.snapshot.url

      this.modalInfo = {
        item: url[1].path,
        item_id: url[2].path
      }

      if(window?.location?.pathname){
        this.pageInfos.newTitle = this.modalInfo.item + ' - eubebo';
        this.pageInfos.metaTags = [
          { property: 'og:title', content: this.modalInfo.item + ' - eubebo'  },
          { property: 'og:url', content: window.location.href},
          { name: "twitter:card", content: "summary_large_image" },
        ]
        this.setTitleInMetas(this.pageInfos);
      }
    }

  ngOnInit(): void {
    this.user_id = this.authUserService.getValue(this.authUserService.userId)

    this.getUser()
    this.getProduct(this.modalInfo.item_id)
    this.getProductsByCategory()
    this.productsInCart = this.cartEubeboService.getValue(this.cartEubeboService.numOfProducts)
    this.latitude = this.currentAddressService.getValue(this.currentAddressService.address_lat)
    this.longitude = this.currentAddressService.getValue(this.currentAddressService.address_long)
  }

  openDialog(item_id: string) {
    const dialogRef = this.dialog.open(ProdutoPageComponent, {
      panelClass: 'container-product',
      data: {
        item_id: item_id
      }
    })

    dialogRef.afterClosed().subscribe(result => {
    })
  }

  public setTitleInMetas({newTitle , metaTags }: any) {
    this.metaSeoService.updateTitle(newTitle);
    this.metaSeoService.updateMetaTags(metaTags)
  }

  selectImage(image: any) {
    this.selectedImage = image
  }

  shareProduct() {
    this.notifyCopyText = true
    const product_name = this.product.name.replace(/ /g, '%20');
    window.navigator.clipboard?.writeText(`${window.location.origin}/produto/${product_name}/${this.product.id}`)
    setInterval(() => {
      this.notifyCopyText = false
    }, 3000)
  }

  increaseCounter(): void {
    if (this.user_id) {
      this.productCounter ++
    } else {
      const dialogRef = this.dialog.open(LoginDialogComponent)

      dialogRef.afterClosed().subscribe(result => {
        this.user_id = this.authUserService.getValue(this.authUserService.userId)
      })
    }
  }

  decrementCounter(): void {
    if (this.user_id) {
      this.productCounter --
    } else {
      const dialogRef = this.dialog.open(LoginDialogComponent)

      dialogRef.afterClosed().subscribe(result => {
        this.user_id = this.authUserService.getValue(this.authUserService.userId)
      })
    }
  }

  defineCounter(value: number): void {
    this.productCounter == value
  }

  setFavorite(){
    if(this.user_id != null){
      const body = {
        product_id: this.modalInfo.item_id
      }

      this.customersEubeboApiService.registerNewFavoriteProduct(this.user_id, body).subscribe({
        next: (res: any) => {
          this.favorite = true

          this.getUser()
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    } else {
      const dialogRef = this.dialog.open(LoginDialogComponent)

      dialogRef.afterClosed().subscribe(result => {
        this.user_id = this.authUserService.getValue(this.authUserService.userId)
      })
    }
  }

  notFavorite(){
    const foo = this.favorites.find((item: { product_id: string; }) => item.product_id === this.modalInfo.item_id);
    if(foo && this.user_id != null){
      this.customersEubeboApiService.deleteFavoriteProduct(this.user_id, foo._id).subscribe({
        next: (res: any) => {
          this.favorite = false
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    } else {
      const dialogRef = this.dialog.open(LoginDialogComponent)

      dialogRef.afterClosed().subscribe(result => {
        this.user_id = this.authUserService.getValue(this.authUserService.userId)
      })
    }

  }

  calculateSubmenuItemsPrice(base_price: number, discount: number) {
    return ((base_price - (base_price * (discount / 100))) / 100)
  }

  addProductToCart(item_id: string, item_name: string, item_base_price: string, item_seller_id: string, current_offer: any) {
    const atualCartNumber = Number(this.cartEubeboService.getValue(this.cartEubeboService.numOfProducts)) + this.productCounter;

    let listProductsInCartParse = this.cartEubeboService.getValue(this.cartEubeboService.productsList);
    let listProductsInCart: CartProductsList = {
      data: []
    };

    if (listProductsInCartParse) {
      listProductsInCart = JSON.parse(listProductsInCartParse);
    }

    let itemFound = false;

    for (let i = 0; i < listProductsInCart.data.length; i++) {
      if (item_id == listProductsInCart.data[i].product_id) {
        listProductsInCart.data[i].number_of_products = listProductsInCart.data[i].number_of_products + this.productCounter

        this.cartEubeboService.setValue(this.cartEubeboService.numOfProducts, atualCartNumber);
        this.cartEubeboService.setValue(this.cartEubeboService.productsList, JSON.stringify(listProductsInCart));

        itemFound = true;
        break;
      }
    }



    if (!listProductsInCart.data[0] && !itemFound || listProductsInCart.data[0] && listProductsInCart.data[0].product_seller_id == item_seller_id && !itemFound) {

      listProductsInCart.data.push({
        product_id: item_id,
        product_name: item_name,
        product_base_price: item_base_price,
        product_seller_id: item_seller_id,
        product_current_offer: current_offer,
        number_of_products: this.productCounter
      });

      this.cartEubeboService.setValue(this.cartEubeboService.numOfProducts, atualCartNumber);
      this.cartEubeboService.setValue(this.cartEubeboService.productsList, JSON.stringify(listProductsInCart));

    } else if (listProductsInCart.data[0].product_seller_id != item_seller_id ) {
      const dialogRef = this.dialog.open(AddProductErrorDialogComponent, {
        data: {
          item_id: item_id
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    }
  }

  getSellerStore() {
    this.sellerEubeboApiService.getById(this.current_store_id, null, null).subscribe({
      next: (res: any) => {
        this.current_store = res
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  openStorePage(display_name: string, seller_id: string): void {
    if (this.current_store.display_name) {
      this.router.navigate([`lojas/${display_name}/${seller_id}`])
    }
  }

  getUser(){
    if (this.user_id != null) {
      this.customersEubeboApiService.getUserById(this.user_id).subscribe({
        next: (res: any) => {
          this.favorites = res.favorite_products

          if(this.favorites.find((item: { product_id: any; }) => item.product_id === this.modalInfo.item_id)){
            this.favorite = true;
          } else {
            this.favorite = false;
          }
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }

  getProduct(id: string) {
    this.productsEubeboApiService.getProduct(id).subscribe({
      next: (res: any) => {
        let offer_value

        this.product = res
        offer_value = this.product.current_offer?.individual_discount_percentage ?? 0

        this.selectedImage = 0
        this.current_store_id = res.seller_id
        this.total_price = (this.product.base_price - (this.product.base_price * (offer_value / 100))) / 100
        this.getSellerStore()
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getProductsByCategory(): void {
    this.productsEubeboApiService.getAllProductsByCategoryId(this.product.category_id, 1, 9999, this.latitude, this.longitude, 'Delivery').subscribe((res: any) => {
      this.productsByCategory = res.items.filter((item: any) => {
        return item.is_active == true && item.category_id === this.product.category_id
      })
    })
  }
}
