import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';
import { ProdutoPageComponent } from '../produto-page/produto-page.component';

@Component({
  selector: 'app-favoritos-page',
  templateUrl: './favoritos-page.component.html',
  styleUrls: ['./favoritos-page.component.scss']
})
export class FavoritosPageComponent implements OnInit {

  isLoading = true

  user_id: string | null = null
  user_info: any
  user_favorite_products: any

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private authUserService: AuthUserService,
    private customersEubeboApiService: CustomersEubeboApiService,
  ) { }

  ngOnInit(): void {
    this.user_id = this.authUserService.getValue(this.authUserService.userId);

    if (this.user_id == null) {
      this.router.navigate(['/'])
    }

    this.getUserInfo()
  }

  getUserInfo(): any {

    if (this.user_id != null) {
      this.customersEubeboApiService.getUserById(this.user_id).subscribe({
        next: (res: any) => {
          this.user_info = res
          this.user_favorite_products = res.favorite_products
          console.log(this.user_favorite_products)
          this.isLoading = false
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }
  openProduct(product_id: string): void {
    const dialogRef = this.dialog.open(ProdutoPageComponent, {
      panelClass: 'container-product',
    
      data: {
        item_id: product_id
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })
  }
}
