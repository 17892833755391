import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
 name: 'card_credit'
})

export class CardCreditPipe implements PipeTransform {

transform(value: string, args: string[]): string {

    const mid = args.length > 1 ? args[0] : '************';
    const finalDigits = args.length > 2 ? parseInt(args[1], 10) : 4;
    return (value && value.length) ? mid + value.substring( value.length - finalDigits ,  value.length) : value;
   }
}
