import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';
import { CategoriesEubeboApiService } from 'src/app/services/categories-eubebo-api.service';
import { CurrentAddressService } from 'src/app/services/current-address.service';
import { ProductsEubeboApiService } from 'src/app/services/products-eubebo-api.service';
import { SubcategoriesEubeboApiService } from 'src/app/services/subcategories-eubebo-api.service';

@Component({
  selector: 'app-see-category',
  templateUrl: './see-category.component.html',
  styleUrls: ['./see-category.component.scss']
})
export class SeeCategoryComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/images/search.json',
  };

  seller_name: string = ''
  seller_id: string = ''
  category_id: string = ''

  category: any = {}
  allSubcategories: any = []

  

  latitude: string | null = null
  longitude: string | null = null

  loading = true

  hasItems = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private currentAddressService: CurrentAddressService,
    private productsEubeboApiService: ProductsEubeboApiService,
    private categoriesEubeboApiService: CategoriesEubeboApiService,
    private subcategoriesEubeboApiService: SubcategoriesEubeboApiService,
  ) { }

  ngOnInit(): void {
    const url = this.route.snapshot.url
    console.log(url)

    this.seller_name = decodeURI(url[1].path)
    this.seller_id = url[2].path
    this.category_id = url[3].path
    this.getCategoryById(this.category_id)
    this.getAllSubcategories()
   

    this.latitude = this.currentAddressService.getValue(this.currentAddressService.address_lat)
    this.longitude =  this.currentAddressService.getValue(this.currentAddressService.address_long)
  }

  onLoadingStateChanged(estado: boolean) {
    this.loading = estado;
  }

  getCategoryById(category_id: string): void {
    this.categoriesEubeboApiService.getCategoryById(category_id).subscribe({
      next: (res: any) => {
        this.category = res
        for (let i = 0; i < this.category.subcategories?.length; i++) {
          this.getProductsBySubcategory(this.seller_id, this.category.slug, this.category.subcategories[i].slug, 1, 999)
        }
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getAllSubcategories(): void {
    this.subcategoriesEubeboApiService.getAll().subscribe({
      next: (res: any) => {
        this.allSubcategories = res
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getProductsBySubcategory(seller_id: string, category_slug: string, subcategory_slug: string, page: number, limit: number) {
    this.productsEubeboApiService.getAllProductsBySellerCategoryAndSubcategory(seller_id,  category_slug, subcategory_slug, page, limit, this.latitude, this.longitude, 'Delivery').subscribe({
      next: (res: any) => {
        
        if(res?.meta?.totalItems){
          this.hasItems = true;
          this.loading = false;
        }
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  backPage() {
    console.log(`lojas/${this.seller_name}/${this.seller_id}`)
    this.router.navigate([`lojas/${this.seller_name}/${this.seller_id}`])
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.setSpeed(1);
  }
}
