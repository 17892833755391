<section *ngIf="larguraTela >= 993">

  <div class="destaques-container">
    <div *ngFor="let banner of allBanners.slice(0, 3)" style="cursor: pointer;">
      <img class="destaque-imagem" (click)="openBannerUrl(banner.target_url)"
        src="{{banner.background_image_mobile_url}}" alt="">
    </div>
  </div>

  <div *ngIf="latitude != null && longitude != null" class="bebida-container">
    <div class="bebida-icone-container" (click)="searchFor(categoria.name)" *ngFor="let categoria of allCategories"
      style="cursor: pointer;">
      <img class="bebida-icone" src="{{categoria.icon_url}}" alt="">
      <p class="bebida-text">{{categoria.name}}</p>
    </div>
  </div>

  <div *ngIf="allProductsMoreSale?.length > 0" class="carousel-container">
    <div class="header-container">
      <h5 class="header-text">Mais Vendidos</h5>
      <div class="header-text-bar"></div>
      <h5 class="header-see-more" style="cursor: pointer;" (click)="searchFor('')">Ver mais</h5>
    </div>
    <carousel class="row "
      [itemsPerSlide]="((larguraTela < 576) ? 2 : ((larguraTela < 768) ? 3 :(larguraTela < 992 ? 4 : 6 )))"
      [singleSlideOffset]="true" [showIndicators]="false"
      [ngClass]="(allProductsMoreSale?.length > ((larguraTela < 576) ? 2 : ((larguraTela < 768) ? 3 :(larguraTela < 992 ? 4 : 6 )))) ? '': 'hide-navigators'"
      [noWrap]="false" [startFromIndex]="0" [activeSlide]="0" [interval]="0">
      <slide class="carousel-slide col-6 col-sm-4 col-md-3 col-lg-2 m-0"
        *ngFor="let item of allProductsMoreSale; let index=index;" (click)="openDialog(item.id)">
        <div class="slide-content ">
          <div class="carousel-product-image-container position-relative">
            <span *ngIf="item?.is_just_liquid" style="background-color: #d8d8d8af; border-radius: 5px;" class="position-fixed top-0 ms-1 mt-1 p-1 ">
              <mat-icon svgIcon="retornavel"></mat-icon>
            </span>
            <img class="carousel-product-image" [src]="item?.pictures[0]?.url" alt="image slide"
              style="display: block; aspect-ratio: 1;">
            <span *ngIf="user_id" style=" border-radius: 5px;" (click)="togleFavority(item, $event)" class="position-absolute top-0 p-1 icon-heart">
              <mat-icon [svgIcon]="isFavorited(item)  ? 'heart-full': 'heart-empty'"></mat-icon>
            </span>
          </div>
        
          <div class="carousel-product-image-container" style=" text-align: left; padding: 1vh 0;">
            <p class="carousel-product-detail">{{item.display_name}}</p>
          </div>
        </div>
      </slide>
    </carousel>
  </div>

  <div *ngIf="allProductsWithImage?.length > 0" class="carousel-container">
    <div class="header-container">
      <h5 class="header-text">Ofertas</h5>
      <div class="header-text-bar"></div>
      <h5 class="header-see-more" style="cursor: pointer;" (click)="searchFor('')">Ver mais</h5>
    </div>
    <carousel class="row"
      [itemsPerSlide]="((larguraTela < 576) ? 2 : ((larguraTela < 768) ? 3 :(larguraTela < 992 ? 4 : 6 )))"
      [singleSlideOffset]="true" [showIndicators]="false"
      [ngClass]="(allProductsWithImage?.length > ((larguraTela < 576) ? 2 : ((larguraTela < 768) ? 3 :(larguraTela < 992 ? 4 : 6 ))))? '': 'hide-navigators'"
      [noWrap]="false" [startFromIndex]="0" [activeSlide]="0" [interval]="0">
      <slide class="carousel-slide col-6 col-sm-4 col-md-3 col-lg-2 m-0"
        *ngFor="let item of allProducts.slice(0, 15); let index=index;" (click)="openDialog(item.id)">
        <div class="slide-content ">
          <div class="carousel-product-image-container position-relative">
            <span *ngIf="item?.is_just_liquid" style="background-color: #d8d8d8af; border-radius: 5px;" class="position-fixed top-0 ms-1 mt-1 p-1 ">
              <mat-icon  svgIcon="retornavel"></mat-icon>
            </span>
            <img class="carousel-product-image" [src]="item?.pictures[0]?.url" alt="image slide"
              style="display: block; aspect-ratio: 1;">
            <span *ngIf="user_id" style=" border-radius: 5px;" (click)="togleFavority(item, $event)" class="position-absolute top-0 p-1 icon-heart">
              <mat-icon  [svgIcon]="isFavorited(item) ? 'heart-full': 'heart-empty'"></mat-icon>
            </span>
          </div>
          <div class="carousel-product-image-container" style=" text-align: left; padding: 1vh 0;">
            <p class="carousel-product-detail">{{item.display_name}}</p>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
  <div *ngIf="latitude != null && longitude != null" class="entrega-rapida-container">
    <div class="header-container">
      <h5 class="header-text">Entrega Rápida</h5>
      <div class="header-text-bar"></div>
      <h5 (click)="openExpressDelivery()" class="header-see-more">Ver mais</h5>
    </div>
    <div class="entrega-rapida-lista-container">
      <div *ngIf="allEntregaRapidaSellers.length <= 0"
        style="display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%;">
        <app-nothing-found-screen></app-nothing-found-screen>
      </div>
      <div *ngFor="let item of allEntregaRapidaSellers.slice(0, 3); let i = index"
        (click)="openStorePage(item.display_name, item.id)">
        <div *ngIf="i<3" class="entrega-rapida-lista-item" style="cursor: pointer;">
          <div class="entrega-rapida-imagem-container">
            <img class="entrega-rapida-imagem" [src]="item.logo_url" alt="">
          </div>
          <div class="entrega-rapida-content">
            <h2 class="entrega-rapida-nome">{{item.display_name}}</h2>
            <p *ngIf="item.seller_delivery">{{item.seller_delivery?.shipping_time ? item.seller_delivery.shipping_time :
              'Tempo indeterminado'}} - {{item.seller_delivery ? (item.seller_delivery.shipping_fee == 0 ? 'Entrega
              grátis' : item.seller_delivery.shipping_fee | dynamic: 'currency') : null}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="allProductsFeeFree?.length > 0" class="carousel-container">
    <div class="header-container">
      <h5 class="header-text">Promoções com entrega grátis</h5>
      <div class="header-text-bar"></div>
      <h5 class="header-see-more" style="cursor: pointer;" (click)="searchFor('')">Ver mais</h5>
    </div>
    <carousel class="row "
      [itemsPerSlide]="((larguraTela < 576) ? 2 : ((larguraTela < 768) ? 3 :(larguraTela < 992 ? 4 : 6 )))"
      [singleSlideOffset]="true" [showIndicators]="false"
      [ngClass]="(allProductsFeeFree?.length > ((larguraTela < 576) ? 2 : ((larguraTela < 768) ? 3 :(larguraTela < 992 ? 4 : 6 )))) ? '': 'hide-navigators'"
      [noWrap]="false" [startFromIndex]="0" [activeSlide]="0" [interval]="0">
      <slide class="carousel-slide col-6 col-sm-4 col-md-3 col-lg-2 m-0"
        *ngFor="let item of allProductsFeeFree; let index=index;" (click)="openDialog(item.id)">
        <div class="slide-content ">
          <div class="carousel-product-image-container position-relative">
            <span *ngIf="item?.is_just_liquid" style="background-color: #d8d8d8af; border-radius: 5px;" class="position-fixed top-0 ms-1 mt-1 p-1 ">
              <mat-icon  svgIcon="retornavel"></mat-icon>
            </span>
            <img class="carousel-product-image" [src]="item?.pictures[0]?.url" alt="image slide"
              style="display: block; aspect-ratio: 1;">
            <span *ngIf="user_id" style="border-radius: 5px;" (click)="togleFavority(item, $event)" class="position-absolute top-0 icon-heart p-1 ">
              <mat-icon  [svgIcon]="isFavorited(item) ? 'heart-full': 'heart-empty'"></mat-icon>
            </span>
          </div>
          <div class="carousel-product-image-container flex-column" style="min-height: 50px; text-align: left; padding: 1vh 0;">
            <p class="carousel-product-detail" >{{item.display_name}}</p>
           
          </div>

          
          
        </div>
      </slide>
    </carousel>
  </div>

  <div *ngIf="latitude != null && longitude != null" class="lojas-container">
    <div class="lojas-header-container">
      <h5 class="header-text">Lojas</h5>
      <div class="header-text-bar"></div>
      <h5 class="header-see-more" style="cursor: pointer;" (click)="searchFor('')">Ver mais</h5>
    </div>
    <div class="lojas-lista-container">
      <div *ngIf="allLojasProximasSellers.length <= 0"
        style="display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%;">
        <app-nothing-found-screen></app-nothing-found-screen>
      </div>
      <div class="row">
        <div class="lojas-item col-md-3" *ngFor="let item of allLojasProximasSellers.slice(0, 6); let i = index"
          (click)="openStorePage(item.display_name, item.id)" style="cursor: pointer;">
          <div class="lojas-item-imagem-container">
            <img class="lojas-item-imagem" src="{{item.logo_url}}" alt="">
          </div>
          <div class="lojas-texto-container ">
            <p class="lojas-texto-titulo ms-2">{{item.display_name}}</p>
            <div class="lojas-texto-sobre">
              <p class="d-flex justify-items-end align-items-end"> <mat-icon class="h-100">star</mat-icon> {{item.rate}}
              </p>
            </div>
            <div>
              <p class=" ms-2">{{item.isOpen ? "Aberto" : "Fechado"}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="latitude == null && longitude == null">
    <div class="d-flex align-items-center justify-content-center gap-5 mb-5">
      <p-skeleton height="20vh" width="25vw"></p-skeleton>
      <p-skeleton height="20vh" width="25vw"></p-skeleton>
      <p-skeleton height="20vh" width="25vw"></p-skeleton>
    </div>
    <div class="d-flex align-items-center justify-content-center gap-5">
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
    </div>
    <div>
      <div class="header-container">
        <h5 class="header-text">Mais Vendidos</h5>
        <div class="header-text-bar"></div>
      </div>
      <div class="d-flex align-items-center justify-content-center gap-5">
        <p-skeleton height="25vh" width="12.5vw"></p-skeleton>
        <p-skeleton height="25vh" width="12.5vw"></p-skeleton>
        <p-skeleton height="25vh" width="12.5vw"></p-skeleton>
        <p-skeleton height="25vh" width="12.5vw"></p-skeleton>
        <p-skeleton height="25vh" width="12.5vw"></p-skeleton>
        <p-skeleton height="25vh" width="12.5vw"></p-skeleton>
      </div>
    </div>
    <div>
      <div class="header-container">
        <h5 class="header-text">Entrega Rápida</h5>
        <div class="header-text-bar"></div>
        <h5 (click)="openExpressDelivery()" class="header-see-more">Ver mais</h5>
      </div>
      <div class="entrega-rapida-lista-container mb-5">
        <p-skeleton height="15vh" width="25vw"></p-skeleton>
        <p-skeleton height="15vh" width="25vw"></p-skeleton>
        <p-skeleton height="15vh" width="25vw"></p-skeleton>
      </div>
    </div>
    <div class="lojas-lista-container">
      <div class="lojas-header-container mb-1">
        <h5 class="header-text">Lojas</h5>
        <div class="header-text-bar"></div>
        <h5 class="header-see-more">Ver mais</h5>
      </div>
      <div class="row mb-3">
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
      </div>
    </div>
  </div>

  <div>
    <img [src]="pagamentoBandeiras" alt="">
  </div>
</section>


<!-- Tela Pequena -->

<section *ngIf="larguraTela <= 992">

  <div class="destaques-container">
    <carousel [itemsPerSlide]="1" [singleSlideOffset]="true" [showIndicators]="false" [noWrap]="false"
      [startFromIndex]="0" [activeSlide]="0" [interval]="0">
      <slide *ngFor="let banner of allBanners.slice(0, 3)" style="cursor: pointer;">
        <div class="slide-content">
          <img class="destaque-imagem" (click)="openBannerUrl(banner.target_url)"
            src="{{banner.background_image_mobile_url}}" alt="">
        </div>
      </slide>
    </carousel>
  </div>

  <div *ngIf="latitude != null && longitude != null" class="bebida-container">
    <div class="bebida-icone-container" (click)="searchFor(categoria.name)" *ngFor="let categoria of allCategories"
      style="cursor: pointer;">
      <img class="bebida-icone" src="{{categoria.icon_url}}" alt="">
      <p class="bebida-text">{{categoria.name}}</p>
    </div>
  </div>

  <div *ngIf="allProductsMoreSale?.length > 0" class="carousel-container">
    <div class="header-container">
      <h5 class="header-text">Mais Vendidos</h5>
      <div class="header-text-bar"></div>
      <h5 class="header-see-more" style="cursor: pointer;" (click)="searchFor('')">Ver mais</h5>
    </div>
    <carousel class="row"
      [itemsPerSlide]="((larguraTela < 576) ? 2 : ((larguraTela < 768) ? 3 :(larguraTela < 992 ? 4 : 6 )))"
      [singleSlideOffset]="true" [showIndicators]="false"
      [ngClass]="(allProductsMoreSale?.length > ((larguraTela < 576) ? 2 : ((larguraTela < 768) ? 3 :(larguraTela < 992 ? 4 : 6 ))))? '': 'hide-navigators'"
      [noWrap]="false" [startFromIndex]="0" [activeSlide]="0" [interval]="0">
      <slide class="carousel-slide col-6 col-sm-4 col-md-3 col-lg-2 m-0"
        *ngFor="let item of allProductsMoreSale.slice(0, 15); let index=index;" (click)="openDialog(item.id)">
        <div class="slide-content">
          <div class="carousel-product-image-container position-relative">
            <span *ngIf="item?.is_just_liquid" style="background-color: #d8d8d8af; border-radius: 5px;" class="position-fixed top-0 ms-1 mt-1 p-1 ">
              <mat-icon  svgIcon="retornavel"></mat-icon>
            </span>
            <img class="carousel-product-image" [src]="item?.pictures[0]?.url" alt="image slide"
              style="display: block; width: 100%;">
            <span *ngIf="user_id" style="border-radius: 5px;" (click)="togleFavority(item, $event)" class="position-absolute top-0 icon-heart p-1 ">
              <mat-icon  [svgIcon]="isFavorited(item) ? 'heart-full': 'heart-empty'"></mat-icon>
            </span>
              
          </div>
          <div class="carousel-product-image-container" style=" text-align: left; padding: 1vh 0;">
            <p class="carousel-product-detail">{{item.display_name}}</p>
          </div>
        </div>
      </slide>
    </carousel>
  </div>

  <div *ngIf="allProductsWithImage?.length > 0" class="carousel-container">
    <div class="header-container">
      <h5 class="header-text">Ofertas</h5>
      <div class="header-text-bar"></div>
      <h5 class="header-see-more" style="cursor: pointer;" (click)="searchFor('')">Ver mais</h5>
    </div>
    <carousel class="row"
      [itemsPerSlide]="((larguraTela < 576) ? 2 : ((larguraTela < 768) ? 3 :(larguraTela < 992 ? 4 : 6 )))"
      [singleSlideOffset]="true" [showIndicators]="false"
      [ngClass]="(allProductsWithImage?.length > ((larguraTela < 576) ? 2 : ((larguraTela < 768) ? 3 :(larguraTela < 992 ? 4 : 6 )))) ? '': 'hide-navigators'"
      [noWrap]="false" [startFromIndex]="0" [activeSlide]="0" [interval]="0">
      <slide class="carousel-slide col-6 col-sm-4 col-md-3 col-lg-2 m-0"
        *ngFor="let item of allProductsWithImage.slice(0, 15); let index=index;" (click)="openDialog(item.id)">
        <div class="slide-content">
          <div class="carousel-product-image-container position-relative">
            <span *ngIf="item?.is_just_liquid" style="background-color: #d8d8d8af; border-radius: 5px;" class="position-fixed top-0 ms-1 mt-1 p-1 ">
              <mat-icon  svgIcon="retornavel"></mat-icon>
            </span>
            <img class="carousel-product-image" [src]="item?.pictures[0]?.url" alt="image slide"
              style="display: block; width: 100%;">
            <span *ngIf="user_id" style="border-radius: 5px;" (click)="togleFavority(item, $event)" class="position-absolute top-0 icon-heart  p-1 ">
              <mat-icon  [svgIcon]="isFavorited(item) ? 'heart-full': 'heart-empty'"></mat-icon>
            </span>
          </div>
          <div class="carousel-product-image-container" style=" text-align: left; padding: 1vh 0;">
            <p class="carousel-product-detail">{{item.display_name}}</p>
          </div>
        </div>
      </slide>
    </carousel>
  </div>

  <div *ngIf="latitude != null && longitude != null" class="entrega-rapida-container">
    <div class="header-container">
      <h5 class="header-text">Entrega Rápida</h5>
      <mat-divider style="flex-grow: 1;"></mat-divider>
      <h5 (click)="openExpressDelivery()" class="header-see-more">Ver mais</h5>
    </div>
    <div class="entrega-rapida-lista-container">
      <div *ngIf="allEntregaRapidaSellers.length <= 0"
        style="display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%;">
        <app-nothing-found-screen></app-nothing-found-screen>
      </div>
      <div *ngFor="let item of allEntregaRapidaSellers.slice(0, 3); let i = index"
        (click)="openStorePage(item.display_name, item.id)">
        <div *ngIf="i<3" class="entrega-rapida-lista-item" style="cursor: pointer;">
          <div class="entrega-rapida-imagem-container">
            <img class="entrega-rapida-imagem" [src]="item.logo_url" alt="">
          </div>
          <div class="entrega-rapida-content">
            <h2 class="entrega-rapida-nome">{{item.display_name}}</h2>
            <p *ngIf="item.seller_delivery">{{item.seller_delivery?.shipping_time ? item.seller_delivery.shipping_time :
              'Tempo indeterminado'}} - {{item.seller_delivery ? (item.seller_delivery.shipping_fee == 0 ? 'Entrega
              grátis' : item.seller_delivery.shipping_fee | dynamic: 'currency') : null}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--   <div class="promocoes-entrega-container">
    <div class="header-container">
      <h5 class="header-text">Produtos com entrega grátis</h5>
      <div class="header-text-bar"></div>
      <h5 class="header-see-more">Ver mais</h5>
    </div>
    <div class="promocoes-entrega-lista-container">
      <div  *ngFor="let item of allProductsWithImage.slice(0, 5); let i = index" (click)="openDialog(item.id)">
        <div class="promocoes-entrega-lista-item" style="cursor: pointer;">
          <div class="promocoes-entrega-imagem-container">
            <img class="promocoes-entrega-imagem" [src]="item?.pictures[0]?.url" alt="">
            <h2 class="promocoes-entrega-nome">{{item.display_name}}</h2>
            <h3 class="promocoes-entrega-valor">R${{item.base_price}}</h3>
          </div>
          <div class="promocoes-entrega-content">
            <p style="font-size: 1.75vh;">Distância - 1.0Km</p>
            <div style="display: flex; flex-direction: row; gap: 0.5vw; font-size: 2vh;">
              <mat-icon>schedule</mat-icon>
              <p>40-50 min</p>
            </div>
          </div>
        </div>
    </div>
    </div>
  </div> -->

  <div *ngIf="latitude != null && longitude != null" class="lojas-container">
    <div class="lojas-header-container">
      <h5 class="header-text">Lojas</h5>
      <mat-divider style="flex-grow: 1;"></mat-divider>
      <h5 class="header-see-more" style="cursor: pointer;" (click)="searchFor('')">Ver mais</h5>
    </div>
    <div class="lojas-lista-container">
      <div *ngIf="allLojasProximasSellers.length <= 0"
        style="display: flex; flex-direction: column; align-items: center; justify-content: center; width: 100%;">
        <app-nothing-found-screen></app-nothing-found-screen>
      </div>
      <div class="row">
        <div class="lojas-item" *ngFor="let item of allLojasProximasSellers.slice(0, 3); let i = index"
          (click)="openStorePage(item.display_name, item.id)" style="cursor: pointer;">
          <div class="lojas-item-imagem-container">
            <img class="lojas-item-imagem" src="{{item.logo_url}}" alt="">
          </div>
          <div class="lojas-texto-container">
            <p class="lojas-texto-titulo ms-2">{{item.display_name}}</p>
            <div class="lojas-texto-sobre">
              <p class="d-flex justify-items-end align-items-end"> <mat-icon class="h-100">star</mat-icon> {{item.rate}}
              </p>
            </div>
            <div>
              <p class=" ms-2">{{item.isOpen ? "Aberto" : "Fechado"}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="latitude == null && longitude == null && larguraTela >= 576 && larguraTela <= 992">
    <div class="d-flex align-items-center justify-content-center gap-5 mb-5">
      <p-skeleton height="20vh" width="25vw"></p-skeleton>
      <p-skeleton height="20vh" width="25vw"></p-skeleton>
      <p-skeleton height="20vh" width="25vw"></p-skeleton>
    </div>
    <div class="d-flex align-items-center justify-content-center gap-5">
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
    </div>
    <div>
      <div class="header-container">
        <h5 class="header-text">Mais Vendidos</h5>
        <div class="header-text-bar"></div>
      </div>
      <div class="d-flex align-items-center justify-content-center gap-5">
        <p-skeleton height="25vh" width="12.5vw"></p-skeleton>
        <p-skeleton height="25vh" width="12.5vw"></p-skeleton>
        <p-skeleton height="25vh" width="12.5vw"></p-skeleton>
        <p-skeleton height="25vh" width="12.5vw"></p-skeleton>
        <p-skeleton height="25vh" width="12.5vw"></p-skeleton>
      </div>
    </div>
    <div>
      <div class="header-container">
        <h5 class="header-text">Entrega Rápida</h5>
        <div class="header-text-bar"></div>
        <h5 (click)="openExpressDelivery()" class="header-see-more">Ver mais</h5>
      </div>
      <div class="d-flex gap-5 mb-5">
        <p-skeleton height="15vh" width="25vw"></p-skeleton>
        <p-skeleton height="15vh" width="25vw"></p-skeleton>
        <p-skeleton height="15vh" width="25vw"></p-skeleton>
      </div>
    </div>
    <div class="lojas-lista-container">
      <div class="lojas-header-container mb-1">
        <h5 class="header-text">Lojas</h5>
        <div class="header-text-bar"></div>
        <h5 class="header-see-more">Ver mais</h5>
      </div>
      <div class="row mb-3">
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="latitude == null && longitude == null && larguraTela <= 575">
    <div class="d-flex align-items-center justify-content-center gap-5 mb-5">
      <p-skeleton height="20vh" width="90vw"></p-skeleton>
    </div>
    <div class="d-flex align-items-center justify-content-center gap-1">
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
      <p-skeleton shape="circle" size="8.5vh"></p-skeleton>
    </div>
    <div>
      <div class="header-container">
        <h5 class="header-text">Mais Vendidos</h5>
        <div class="header-text-bar"></div>
      </div>
      <div class="d-flex align-items-center justify-content-center gap-2">
        <p-skeleton height="25vh" width="30vw"></p-skeleton>
        <p-skeleton height="25vh" width="30vw"></p-skeleton>
        <p-skeleton height="25vh" width="30vw"></p-skeleton>
      </div>
    </div>
    <div>
      <div class="header-container">
        <h5 class="header-text">Entrega Rápida</h5>
        <div class="header-text-bar"></div>
        <h5 (click)="openExpressDelivery()" class="header-see-more">Ver mais</h5>
      </div>
      <div class="d-flex gap-5 mb-2">
        <p-skeleton height="15vh" width="30vw"></p-skeleton>
        <p-skeleton height="15vh" width="30vw"></p-skeleton>
        <p-skeleton height="15vh" width="30vw"></p-skeleton>
      </div>
    </div>
    <!--     <div class="lojas-lista-container">
      <div class="lojas-header-container mb-1">
        <h5 class="header-text">Lojas</h5>
        <div class="header-text-bar"></div>
        <h5 class="header-see-more">Ver mais</h5>
      </div>
      <div class="row mb-3">
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
        <div class="col">
          <p-skeleton height="15vh" width="25vw"></p-skeleton>
        </div>
      </div>
    </div> -->
  </div>
</section>