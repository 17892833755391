
<div mat-dialog-content class="d-flex align-items-center justify-content-center">
   <div class="container-fluid">
      <div class="justify-content-center text-center mb-2">
         <img width="100" height="100" src="assets/icons/reutilizavel.svg" alt="icone reutilizavel">
         <h5>Você escolheu apenas o líquido, portanto precisa ter garrafas retornaveis para a trocar</h5>
         
         <p>{{data.message}}</p>
      </div>
   </div>
</div>
<div class="d-flex align-items-center justify-content-center">
   <button mat-stroked-button color="warn" (click)="close()">Fechar</button>
   <button class="btn-information" mat-raised-button color="primary" (click)="confirm()">Confirmar</button>
   
</div>

 
 