import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { FaqEubeboApiService } from 'src/app/services/faq-eubebo-api.service';

@Component({
  selector: 'app-faq-page',
  templateUrl: './faq-page.component.html',
  styleUrls: ['./faq-page.component.scss']
})
export class FaqPageComponent implements OnInit {

  user_id: string | null = null

  faqList: any = []

  constructor(
    private router: Router,
    private authUserService: AuthUserService,
    private faqEubeboApiService: FaqEubeboApiService,
  ) { }

  ngOnInit(): void {
    // this.user_id = this.authUserService.getValue(this.authUserService.userId);

    // if (this.user_id == null) {
    //   this.router.navigate(['/'])
    // }

    this.getAllFaq()
  }

  getAllFaq() {
    this.faqEubeboApiService.getAll().subscribe({
      next: (res: any) => {
        this.faqList = res
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }
}
