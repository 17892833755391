<section>
  <div class="voltar-button-container">
    <button class="voltar-button" (click)="returnToDeliveryPage()">
      <mat-icon class="voltar-button-icon">arrow_back</mat-icon>
      Voltar
    </button>
  </div>
  <div *ngIf="larguraTela <= 992" class="opcoes-busca-container">
    <mat-select class="opcoes-busca distancia" [(value)]="selectOrderBy">
      <mat-option class="opcoes-busca-item" (click)="orderFilter(index)" *ngFor="let orderBy of orderByList; let index = index" [value]="orderBy.value">
        {{orderBy.viewValue}}
      </mat-option>
    </mat-select>
    <mat-select class="opcoes-busca distancia" [(value)]="category_slug">
      <mat-option class="opcoes-busca-item" (click)="categoryFilter('null')" value="null">Todas categorias</mat-option>
      <mat-option class="opcoes-busca-item" (click)="categoryFilter(category.slug, category.id)" *ngFor="let category of allCategories; let index = index" [value]="category.slug">
        {{category.name}}
      </mat-option>
    </mat-select>
    <mat-select class="opcoes-busca distancia" [(value)]="subcategory_slug">
      <mat-option class="opcoes-busca-item" (click)="subcategoryFilter('null')" value="null">Todas categorias</mat-option>
      <mat-option class="opcoes-busca-item" (click)="subcategoryFilter(subcategory.slug)" *ngFor="let subcategory of subcategoriesFiltered; let index = index" [value]="subcategory.slug">
        {{subcategory.name}}
      </mat-option>
    </mat-select>
  </div>
  <div *ngIf="larguraTela <= 992" class="opcoes-busca-container">
    <button (click)="entregaGratisFilter()" class="opcoes-busca entrega-gratis">
      Entrega Grátis
    </button>
    <button (click)="entregaExpressaFilter()" class="opcoes-busca entrega-gratis">
      Entrega Expressa
    </button>
    <button (click)="resetFilter()" class="opcoes-busca">
      Limpar
    </button>
  </div>
  <div class="opcoes-busca-container"  *ngIf="larguraTela >= 993">
    <mat-select class="opcoes-busca distancia" [(value)]="selectOrderBy">
      <mat-option class="opcoes-busca-item" (click)="orderFilter(index)" *ngFor="let orderBy of orderByList; let index = index" [value]="orderBy.value">
        {{orderBy.viewValue}}
      </mat-option>
    </mat-select>
    <mat-select class="opcoes-busca distancia" [(value)]="category_slug">
      <mat-option class="opcoes-busca-item" (click)="categoryFilter('null')" value="null">Todas categorias</mat-option>
      <mat-option class="opcoes-busca-item" (click)="categoryFilter(category.slug, category.id)" *ngFor="let category of allCategories; let index = index" [value]="category.slug">
        {{category.name}}
      </mat-option>
    </mat-select>
    <mat-select class="opcoes-busca distancia" [(value)]="subcategory_slug">
      <mat-option class="opcoes-busca-item" (click)="subcategoryFilter('null')" value="null">Todas categorias</mat-option>
      <mat-option class="opcoes-busca-item" (click)="subcategoryFilter(subcategory.slug)" *ngFor="let subcategory of subcategoriesFiltered; let index = index" [value]="subcategory.slug">
        {{subcategory.name}}
      </mat-option>
    </mat-select>
    <button (click)="entregaGratisFilter()" class="opcoes-busca entrega-gratis">
      Entrega Grátis
    </button>
    <button (click)="entregaExpressaFilter()" class="opcoes-busca entrega-gratis">
      Entrega Expressa
    </button>
    <button (click)="resetFilter()" class="opcoes-busca">
      Limpar
    </button>
  </div>
  <mat-tab-group [selectedIndex]="selectedIndex">
    <mat-tab>
      <ng-template mat-tab-label>
        <p class="tab-lable">
          Lojas
        </p>
      </ng-template>

      <div class="container">
        <div class="row" style="margin: 0;  justify-content: flex-start; align-items: center;">
          <div class="seller-container my-2 col-12 col-sm-12 col-md-4 " *ngFor="let item of allSellersToShow" (click)="openStorePage(item.display_name, item.id)">
            <div class="seller-image-container">
              <img class="seller-image" src="{{item.logo_url}}" alt="">
            </div>
            <div class="seller-info-container">
              <p class="seller-info-name">{{item.display_name}}</p>
              <div class="rate-and-distance-container">
                <mat-icon class="rate-text">star</mat-icon>
                <p class="rate-text rate-value">{{item.rate}}</p>
                <mat-icon class="separator">fiber_manual_record</mat-icon>
                <p>Bebidas</p>
                <mat-icon class="separator">fiber_manual_record</mat-icon>
                <p>{{item.distance.toFixed(2)}} Km</p>
              </div>
              <div class="status-and-price-container">
                <p class="status-text">{{item.isOpen ? "Aberto" : "Fechado"}}</p>
                <mat-icon class="separator">fiber_manual_record</mat-icon>
                <p>{{calcularShippingFee(item) | dynamic: 'currency'}}</p>
              </div>
            </div>
          </div>
          <div *ngIf="allSellersToShow.length == 0 && sellerRequestPage == 1" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <app-nothing-found-screen></app-nothing-found-screen>
          </div>
        </div>
        <div *ngIf="allSellersReqInfo?.meta?.currentPage < allSellersReqInfo?.meta?.totalPages" class="d-flex flex-column align-items-center mt-5">
          <button class="load-more-button" (click)="loadMoreSellers()">Carregar mais</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <ng-template class="tab-lable" mat-tab-label>
        <p class="tab-lable">
          Itens
        </p>
      </ng-template>

      <div class="container my-2">
        <!-- <div class="row" style="margin: 0; justify-content: start; ">
          <div class="seller-container my-2 col-12 col-sm-12 col-md-4" *ngFor="let item of allProductsToShow" (click)="openDialog(item.id)">
            <div class="seller-image-container">
              <img class="seller-image" src="{{item.pictures[0]?.url}}" alt="">
            </div>
            <div class="seller-info-container">
              <p class="item-info-nome">{{item?.display_name}}</p>
              <p style="min-height: 30px;" class="d-flex flex-column justify-content-end pb-0" [ngClass]="item?.current_offer ? 'h-auto': 'h-auto'">
                <span *ngIf="item?.current_offer" class="w-100 " style=" display: block;font-size: 0.8rem; color: #666">de
                  <span style="text-decoration: line-through; "> {{ (item?.base_price/100) | currency:"R$" }} </span> por
                </span>
                <span *ngIf="item?.current_offer" class="w-100" style="display: block; font-size: 1rem; color: #510c76" > {{  ( item?.base_price - (item?.base_price *(item?.current_offer?.individual_discount_percentage /100) )) / 100 | currency:"R$" }}</span>
                <span *ngIf="!item?.current_offer"  class="w-100" style="display: block; font-size: 1rem; color: #510c76"> {{ (item?.base_price/100) | currency:"R$" }}</span>
              </p>
              
              
            </div>
          </div>
         
        </div> -->

        <div class="row margin: 0;">
   
          <div class="col-6 col-sm-4 col-md-3 col-lg-2 mb-3" *ngFor="let itemProduct of allProductsToShow" (click)="openDialog(itemProduct.id)">
            <div class="position-relative">
              <span *ngIf="itemProduct?.is_just_liquid" style="background-color: #d8d8d8af; border-radius: 5px;" class="position-absolute top-1 ms-1 mt-1 p-1 ">
                <mat-icon  svgIcon="retornavel"></mat-icon>
              </span>
              <img class="card-product-image" src="{{itemProduct.pictures[0]?.url}}" alt="">
              <span *ngIf="user_id" style="border-radius: 5px;" (click)="togleFavority(itemProduct, $event)" class="position-absolute top-0 icon-heart p-1 ">
                <mat-icon  [svgIcon]="isFavorited(itemProduct) ? 'heart-full': 'heart-empty'"></mat-icon>
              </span>
            </div>
            <div class="card-product-detail">
              
              <p style="min-height: 50px;" class="d-flex flex-column justify-content-end mb-2 pb-0" [ngClass]="itemProduct?.current_offer ? 'h-auto': 'h-auto'">
                <span *ngIf="itemProduct?.current_offer" class="w-100 " style=" display: block;font-size: 0.8rem; color: #666">de
                  <span style="text-decoration: line-through; "> {{ (itemProduct?.base_price/100) | currency:"R$" }} </span> por
                </span>
                <span *ngIf="itemProduct?.current_offer" class="w-100" style="display: block; font-size: 1rem; font-weight: bold; color: #510c76" > {{  ( itemProduct?.base_price - (itemProduct?.base_price *(itemProduct?.current_offer?.individual_discount_percentage /100) )) / 100 | currency:"R$" }}</span>
                <span *ngIf="!itemProduct?.current_offer"  class="w-100" style="display: block; font-size: 1rem; font-weight: bold; color: #510c76"> {{ (itemProduct?.base_price/100) | currency:"R$" }}</span>
              </p>
              
              <p style="min-height: 75px;"class="item-info-nome">{{itemProduct?.display_name  | truncate:['40']}}</p>
              <mat-divider ></mat-divider>
              <div style="min-height: 50px;" class="d-flex justify-content-start align-items-center mt-1">
                <div>
                  <img width="40" height="40" style="aspect-ratio: 1; " src="{{'https://fs-vendola-images.s3.amazonaws.com/'+ itemProduct.seller.logo}}" alt="">
                </div>
                <div class="ps-2 ">
                  <span >{{itemProduct?.seller.seller_delivery.shipping_time}} </span>
                </div>
                
              </div>
              
            </div>
          </div>
          
        </div>

        <div *ngIf="allProductsToShow.length == 0 && productRequestPage == 1" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
          <app-nothing-found-screen></app-nothing-found-screen>
        </div>
      
        <div *ngIf="allProductsReqInfo?.meta?.currentPage < allProductsReqInfo?.meta?.totalPages" class="d-flex flex-column align-items-center mt-5">
          <button class="load-more-button" (click)="loadMoreProducts()">Carregar mais</button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</section>
