import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OrderStatus } from 'src/app/config/enum/order-status.enum';
import { MyOrder } from 'src/app/config/models/my_order';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { OrdersEubeboApiService } from 'src/app/services/orders-eubebo-api.service';
import { SellerEubeboApiService } from 'src/app/services/sellers-eubebo-api.service';
import { CartEubeboService } from 'src/app/services/cart-eubebo.service';
import { MatDialog } from '@angular/material/dialog';
import { AjudaModalComponent } from './ajuda-modal/ajuda-modal.component';
import { RatingComponent } from './rating/rating.component';


export interface listOrders {
  orders: MyOrder[],
  date:string,
  dateFormatted: string,
}

@Component({
  selector: 'app-pedidos-page',
  templateUrl: './pedidos-page.component.html',
  styleUrls: ['./pedidos-page.component.scss']
})
export class PedidosPageComponent implements OnInit {

  user_id: string | null = null

  orders: MyOrder[] = []
  orders_seller_images: any = []
  orders_in_progress: listOrders[] = []
  orders_completed: listOrders[] = []

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private authUserService: AuthUserService,
    private cartEubeboService: CartEubeboService,
    private ordersEubeboApiService: OrdersEubeboApiService,
    private sellerEubeboApiService: SellerEubeboApiService,
  ) { }

  ngOnInit(): void {
    this.user_id = this.authUserService.getValue(this.authUserService.userId);

    if (this.user_id == null) {
      this.router.navigate(['/'])
    }

    this.getOrdersByUser()
  }

  addProductToCart(order: any) {
    this.cartEubeboService.addItemToCart(order.items, order.seller.seller_id)
  }

  openHelpPage(order_id: string, customer_id: string) {
    const dialogRef = this.dialog.open(AjudaModalComponent, {
      disableClose: false,
      data: {
        order_id: order_id,
        customer_id: customer_id
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })
  }

  getStatusByStatusId(status: number): string {
    return OrderStatus[status];
  }

  getSellerImage() {
    for (let i = 0; i < this.orders.length; i++) {
      this.sellerEubeboApiService.getById(this.orders[i].seller.seller_id, null, null).subscribe({
        next: (res: any) => {
          this.orders_seller_images.push(res.logo_url)
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }

  getOrdersByUser(): void {
    if (this.user_id != null) {
      this.ordersEubeboApiService.getAllByCustomer(this.user_id, 'Delivery', [1,2,4,5,6,7,9,11]).subscribe({
        next: (res: any) => {
          console.log(res.items);
          this.orders_in_progress = []

          res.items.forEach((item: MyOrder) => {
            if(item.created_at){
              let date = new Date(item.created_at);
              console.log(date.toLocaleDateString('pt-BR'))
              let index = this.orders_in_progress.findIndex((item2: any)=> item2.dateFormatted == date.toLocaleDateString('pt-BR'))
              if(index != -1){
                this.orders_in_progress[index].orders.push(item);
              } else {
                this.orders_in_progress.push({
                  date: item.created_at,
                  dateFormatted: date.toLocaleDateString('pt-BR'),
                  orders: [item]
                })
              }
            }
          })

          console.log(res.items.length)
        },
        error: (err: any) => {
          console.log(err)
        }
      })

      this.ordersEubeboApiService.getAllByCustomer(this.user_id, 'Delivery', [3,8,10]).subscribe({
        next: (res: any) => {

          res.items.forEach((item: MyOrder) => {
            if(item.created_at){
              let date = new Date(item.created_at);
              console.log(date.toLocaleDateString('pt-BR'))
              let index = this.orders_completed.findIndex((item2: any)=> item2.dateFormatted == date.toLocaleDateString('pt-BR'))
              if(index != -1){
                this.orders_completed[index].orders.push(item);
              } else {
                this.orders_completed.push({
                  date: item.created_at,
                  dateFormatted: date.toLocaleDateString('pt-BR'),
                  orders: [item]
                })
              }
            }
          })
        
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }
  openchat(order_id: any, customer_id: any){

  }

  getStatus(number: any){
    return OrderStatus[number]
  }

  getMethodShipping(company: any, service: any) {
    let method;
    switch (company) {
      case 1:
        method = 'Correios';
        break;
      case 2:
        method = 'Jadlog'
        break;
    }
    switch (service) {
      case 1:
        method += ' - PAC';
        break;
      case 2:
        method += ' - SEDEX'
        break;
      case 3:
        method += ' - .Package'
        break;
      case 4:
        method += ' - .Com'
        break;
    }
    return method;
  }

  selectColorProcessing(status: any){
    if(status == 1) return 'border-radius: 4px; color: #fff; background-color: #1e96fc';
    if(status == 2) return 'border-radius: 4px; color: #fff; background-color: #0466c8';
    if(status == 3) return 'border-radius: 4px; color: #fff; background-color: #d90429';
    if(status == 4) return 'border-radius: 4px; color: #fff; background-color: #adb5bd';
    if(status == 5) return 'border-radius: 4px; color: #fff; background-color: #248232';
    return
  }

  selectColorOrder(status: any){
    if(status == 1) return 'min-width: 210px;border-radius: 4px; color: #fff ; background-color: #EEEE00';
    if(status == 2) return 'min-width: 210px;border-radius: 4px; color: #fff; background-color: #1e96fc';
    if(status == 3) return 'min-width: 210px;border-radius: 4px; color: #fff; background-color: #d90429';
    if(status == 4) return 'min-width: 210px;border-radius: 4px; color: #fff; background-color: #ffc300';
    if(status == 5) return 'min-width: 210px;border-radius: 4px; color: #fff; background-color: #ffc300';
    if(status == 7) return 'min-width: 210px;border-radius: 4px; color: #fff; background-color: #0466c8';
    if(status == 8) return 'min-width: 210px;border-radius: 4px; color: #fff; background-color: #d90429';
    if(status == 9) return 'min-width: 210px;border-radius: 4px; color: #fff; background-color: #ffc300';
    if(status == 10) return 'min-width: 210px;border-radius: 4px; color: #fff; background-color: #248232';
    if(status == 11) return 'min-width: 210px;border-radius: 4px; color: #fff; background-color: #38b000';
    return
  }

  
}
