<mat-card class="d-flex flex-column align-items-center justify-content-between  w-100 h-auto mb-3"
(mouseover)="mouseover=true" (mouseout)="mouseover=false" (click)="selectPayment(payment)"
[ngClass]="{'card-selected mat-elevation-z6': selected, 'mat-elevation-z4' : mouseover}">
    <span class="selection">
        <mat-icon>check_circle</mat-icon>
    </span>

    <div class="w-100 d-flex align-items-center justify-content-end">
        <h2 class="expired">{{payment.expired}}</h2>
    </div>

    <div *ngIf="payment?.type" class="w-100 d-flex align-items-center justify-content-center " >
        <mat-icon class="foo" *ngIf="payment.type === 'CREDIT_CARD'" svgIcon="credit-card" ></mat-icon>
        <mat-icon class="foo" *ngIf="payment.type === 'PIX'" svgIcon="pix" ></mat-icon>
        <mat-icon class="foo" *ngIf="payment.type === 'BANK_SLIP'" svgIcon="barcode" ></mat-icon>
    </div>


    <h6 *ngIf="payment?.name" class="display-name mt-3">{{payment.name}}</h6>
    <div *ngIf="payment?.payment_method" class="w-100 d-flex align-items-center justify-content-center " >
        <mat-icon class="foo" *ngIf="payment.payment_method === 'card'" svgIcon="credit-card" ></mat-icon>
        <mat-icon class="foo" *ngIf="payment.payment_method === 'bank_notes'" svgIcon="wallet" ></mat-icon>
    </div>

    <h6 *ngIf="payment?.payment_method === 'card'" class="display-name mt-3">Cartão</h6>
    <h6 *ngIf="payment?.payment_method === 'bank_notes'" class="display-name mt-3">Dinheiro</h6>


</mat-card>
