import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatComponent } from './chat.component';

const routes: Routes = [
  { path: ':order_id', children: [
    { path: ':seller_id', children: [
      { path: ':product_id', component: ChatComponent},
      { path: '', component: ChatComponent},
    ]},
  ] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChatRoutingModule { }
