import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrdersEubeboApiService } from 'src/app/services/orders-eubebo-api.service';
import { RatingComponent } from '../rating/rating.component';

@Component({
  selector: 'app-ajuda-modal',
  templateUrl: './ajuda-modal.component.html',
  styleUrls: ['./ajuda-modal.component.scss']
})
export class AjudaModalComponent implements OnInit {

  seller: any

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AjudaModalComponent>,
    private ordersEubeboApiService: OrdersEubeboApiService,
  ) { }

  ngOnInit(): void {
    this.getOrderInfo()
  }

  getOrderInfo() {
    this.ordersEubeboApiService.getById(this.data.order_id).subscribe({
      next: (res: any) => {
        this.seller = res.seller
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  cancelOrder(): void {
    this.ordersEubeboApiService.cancelOrderById(this.data.order_id, this.data.customer_id).subscribe({
      next: (res: any) => {
      },
      error: (err: any) => {
        console.log(err)
      },
    })
  }

  openRatingPage(customer_id: string) {
    const dialogRef = this.dialog.open(RatingComponent, {
      disableClose: false,
      data: {
        seller_id: this.seller.seller_id,
        customer_id: customer_id
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })
  }

  closeModal(): void {
    this.dialogRef.close()
  }
}
