import { FormControl } from "@angular/forms";

/**
 * Faz a verificação do cpf no formulário
 * @param cpf
 */
export function ValidatorCpf(control: FormControl) {
  let cpf = control.value;
  if (!cpf) return null

  let Soma: number;
  let Resto: number;
  Soma = 0;

  if (cpf == "00000000000" || cpf == "11111111111" || cpf == "22222222222" ||
    cpf == "33333333333" || cpf == "44444444444" || cpf == "55555555555" ||
    cpf == "66666666666" || cpf == "77777777777" || cpf === "88888888888" ||
    cpf == "99999999999") return {
      cpfValidator: true,
      message: "CPF inválido, sequência de caracteres inválida"
    };

  for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto == 10) || (Resto == 11)) Resto = 0;
  if (Resto != parseInt(cpf.substring(9, 10))) return {
    cpfValidator: true,
    message: "CPF inválido."
  };

  Soma = 0;
  for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  Resto = (Soma * 10) % 11;

  if ((Resto == 10) || (Resto == 11)) Resto = 0;
  if (Resto != parseInt(cpf.substring(10, 11))) return {
    cpfValidator: true,
    message: "CPF inválido."
  };
  return null;
}

