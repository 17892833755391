
   <h3 mat-dialog-title>Troco?</h3>
   <mat-dialog-content>
      <div class="d-flex align-items-center justify-content-start w-100">
         <div class="w-100" [formGroup]="form">
   
            <p>Seu pedido deu <strong>{{totalValue | dynamic: 'currency'}}</strong>.<br>
               <small><strong>Obs: </strong>Lembre-se de conferir seu troco ao receber o pedido.</small>
            </p>
            
            <section class="example-section mb-2">
               <mat-checkbox color="primary" [formControlName]="'has_exchange'" class="example-margin">Precisa de troco?</mat-checkbox>
            </section>
            <mat-form-field class="example-form-field w-100" appearance="fill">
               <mat-label>Troco pra quanto?</mat-label>
               <input matInput currencyMask type="text" [formControlName]="'exchange_amount'">
               <mat-error *ngIf="form.controls['exchange_amount'].invalid">
                  <span *ngIf="form.controls['exchange_amount'].hasError('required')">Troco obrigatorio</span>
                  <span *ngIf="form.controls['exchange_amount'].hasError('min')">precisa ser maior que o valor da compra</span>
               </mat-error>
            </mat-form-field>
            
         </div>
         
      </div>
     
      
   </mat-dialog-content>
  
   <mat-dialog-actions>
      <div  class="d-flex align-items-center justify-content-center mt-2">
         <button class="close-button p-2 ms-1"(click)="close()">Fechar</button>
         <button class="confirm-button p-2 ms-1"(click)="save()">Confirmar</button>
      </div>
    
   </mat-dialog-actions>
  