import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventEmitService {
  private hasAddress = new EventEmitter<boolean>(false)

  constructor() { }

  emit(type: string, value: boolean) {
    if (type === 'hasAddress') {
      this.hasAddress.emit(value)
    }
  }

  capture(type: string) {
    if (type === 'hasAddress') {
      return this.hasAddress.asObservable();
    } else {
      return
    }
  }
}
