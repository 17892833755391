import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardAddressSelectedComponent } from './card-address-selected.component';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';



@NgModule({
  declarations: [
    CardAddressSelectedComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatDividerModule,

  ],
  exports: [
    CardAddressSelectedComponent
  ]
})
export class CardAddressSelectedModule { }
