
<section class="">
  <h2 mat-dialog-title class="text-center">Pagamento no pix</h2>
  <!-- <img src="{{'https://api.qrserver.com/v1/create-qr-code/?size=250x250&data=' + qrcode}}" alt=""> -->
  <div class="d-flex justify-content-center">
    <img [src]="data.res.payment_data.pix_qrcode_url"  width="250" height="250" style="aspect-ratio: 1/1" alt=""/>
  </div>
  <div class="copy-and-paste w-100 btn d-flex justify-content-between flex-row "  (click)="copyQrCodeToClipboard()">
    <span class="w-75 pe-3">{{qrcode | truncate:['40']}}</span>

    <span style="border-radius: 50%; background-color: #e2e2e2ec; aspect-ratio: 1/1; padding: 2%;" ><mat-icon >content_copy</mat-icon></span>
    
  </div>
  <div class="d-flex flex-column">
    <p>Pagamento realizado?</p>
    <button class="redirect" (click)="redirectToOrders()">Acompanhar pedido</button>
  </div>
</section>
