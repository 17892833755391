<section *ngIf="!loading">
  <div *ngIf="latitude && longitude && latitude != 0 && longitude != 0 && !outside_radius">
    <h5>Selecionar endereço?</h5>
    <div class="w-100 d-flex justify-content-center gap-3">
      <button class="button button-confirm" (click)="close(true)">Confirmar</button>
      <button class="button button-cancel" (click)="close(false)">Voltar</button>
    </div>
  </div>
  <div *ngIf="latitude && longitude && latitude != 0 && longitude != 0 && outside_radius">
    <h5>{{seller?.display_name}} não entrega no endereço selecionado.</h5>
    <p>ao confirmar seu carrinho será esvaziado!</p>
    <div class="w-100 d-flex justify-content-center gap-3">
      <button class="button button-confirm" (click)="close(true)">Confirmar</button>
      <button class="button button-cancel" (click)="close(false)">Voltar</button>
    </div>
  </div>
  <div *ngIf="!latitude || !longitude || !latitude && !longitude || latitude == 0 && longitude == 0">
    <h5>Este endereço precisa ser atualizado</h5>
    <div>
      <div class="w-100 d-flex justify-content-center gap-3">
        <button class="button button-confirm" (click)="editAddress(data.address._id)">Confirmar</button>
        <button class="button button-cancel" (click)="close(false)">Fechar</button>
      </div>
    </div>
  </div>
</section>
<span class="p-3" *ngIf="loading">Carregando</span>
