<section>
  <div class="carousel-container">
    <carousel [showIndicators]="false">
      <slide *ngFor="let slide of slides">
        <img [src]="slide.image" alt="image slide" style="display: block; width: 100%;">
      </slide>
    </carousel>
  </div>

  <div class="menu-container">
    <div class="menu-icone-container" *ngFor="let item of menu">
      <a class="menu-link" routerLink="/{{item.url}}">
        <img class="menu-icone" src="{{item.imagem}}" alt="" />
        <p class="menu-text">{{item.texto}}</p>
      </a>
    </div>
  </div>

  <div>
    <carousel class="carousel-item-cafe-container" [showIndicators]="false" [itemsPerSlide]="5" [singleSlideOffset]="true" >
      <slide *ngFor="let item of cafes">
        <div class="carousel-item-cafe-item">
          <div style="display: flex; flex-direction: column;" (click)="openDialog(
            item.nome,
            'descricao',
            item.imagem,
            'vendedor_id',
            10,
            5,
            999,
            500,
            'g'
            )">
            <div class="upper-part">
              <img class="upper-part-image" src="{{item.imagem}}" alt="" style="display: block; width: 100%;">
            </div>
            <div class="middle-part">
              <h2 class="middle-part-nome">{{item.nome}}</h2>
              <h3 class="middle-part-valor">R${{item.valor}}</h3>
            </div>
          </div>
          <div class="bottom-part">
            <div class="bottom-part-number-container">
              <button class="bottom-part-number-minus-button"> - </button>
              <p class="bottom-part-number-counter">{{productCounter}}</p>
              <button class="bottom-part-number-plus-button"> + </button>
            </div>
            <button class="bottom-part-add-button">Adicionar</button>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</section>
