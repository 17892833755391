import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_URL = environment.url_api

@Injectable({
  providedIn: 'root'
})
export class SellerEubeboApiService {

  constructor(private http: HttpClient) {}

  getAll(): Observable<any> {
    return this.http.get( API_URL + 'sellers')
  }

  getById(id: string, lat: string | null, long: string | null): Observable<any> {

    let params =  new HttpParams();

    if (lat) {
      params = params.append('lat', lat)
    }

    if (long) {
      params = params.append('long', long)
    }

    return this.http.get(`${API_URL}sellers/${id}`, {params})
  }

  getByName(name: string, page: number, limit: number, lat: string | null, long: string | null): Observable<any> {

    let params =  new HttpParams();

    if(name) {
      params = params.append('search_term', name)
    }

    if (lat) {
      params = params.append('lat', lat)
    }

    if (long) {
      params = params.append('long', long)
    }

    if (page) {
      params = params.append('page', page)
    }

    if (limit) {
      params = params.append('limit', limit)
    }

    return this.http.get(`${API_URL}sellers/search`, {params})
  }

  getBySearch(page: number, limit: number, lat: string | null, long: string | null, is_open: boolean | null, is_express: boolean | null, platform_type: string | null, acceptance_status: number | null): Observable<any> {
    let params =  new HttpParams();

    if (lat) {
      params = params.append('lat', lat)
    }

    if (long) {
      params = params.append('long', long)
    }

    if (page) {
      params = params.append('page', page)
    }

    if (limit) {
      params = params.append('limit', limit)
    }

    if (platform_type) {
      params = params.append('platform_type', platform_type)
    }

    if (acceptance_status) {
      params = params.append('acceptance_status', acceptance_status)
    }

    if (is_open) {
      if (is_open == true) {
        params = params.append('isOpen', 1)
      } else if (is_open == false) {
        params = params.append('isOpen', 0)
      }
    }

    if (is_express) {
      if (is_express == true) {
        params = params.append('is_express', 1)
      } else if (is_express == false) {
        params = params.append('is_express', 0)
      }
    }

    return this.http.get(`https://apiteste.eubebo.com.br/sellers/search`, {params})
  }

  getBySearchTerm(page: number, limit: number, search_term: string | null, lat: string | null, long: string | null, is_open: boolean | null, is_express: boolean | null, platform_type: string | null, acceptance_status: number | null): Observable<any> {
    let params =  new HttpParams();

    if(search_term && search_term != null) {
      params = params.append('search_term', search_term)
    }

    if (lat) {
      params = params.append('lat', lat)
    }

    if (long) {
      params = params.append('long', long)
    }

    if (page) {
      params = params.append('page', page)
    }

    if (limit) {
      params = params.append('limit', limit)
    }

    if (platform_type) {
      params = params.append('platform_type', platform_type)
    }

    if (acceptance_status) {
      params = params.append('acceptance_status', acceptance_status)
    }

    if (is_open) {
      if (is_open == true) {
        params = params.append('isOpen', 1)
      } else if (is_open == false) {
        params = params.append('isOpen', 0)
      }
    }

    if (is_express != null) {
      if (is_express == true) {
        params = params.append('is_express', 1)
      } else if (is_express == false) {
        params = params.append('is_express', 0)
      }
    }

    return this.http.get(`https://apiteste.eubebo.com.br/sellers/search`, {params})
  }

  rate(id: string, body: any): Observable<any> {
    return this.http.post(`${API_URL}sellers/${id}/rating`, body)
  }

  getAllRatingPaginate(seller_id: string, page: number, limit: number, ): Observable<any> {

    let params =  new HttpParams();

    if (page) {
      params = params.append('page', page)
    }

    if (limit) {
      params = params.append('limit', limit)
    }

    return this.http.get(`${API_URL}sellers/${seller_id}/rating`, {params})
  }

  
}
