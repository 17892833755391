import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class ChatsService extends CrudService{

  api_url: any = environment.url_api;
  endpoint = 'chats';

  constructor(http: HttpClient, dialog: MatDialog, private socketService: SocketService ) {
    super(http, dialog);
  }

  getChat(order_id: any, customer_id: any, seller_id: any ) : Promise <any>{
    let params = new HttpParams();

    if(order_id){
      params = params.append('order_id', order_id);
    }
    if(customer_id){
      params = params.append('customer_id', customer_id);
    }
    if(seller_id){
      params = params.append('seller_id', seller_id);
    }

    return this.http.get(`${this.url}${this.endpoint}`, { params }).toPromise();
  }

  sendMessage(chat_id: any, message: any) : Promise <any> {
    return this.http.post(`${this.url}${this.endpoint}/${chat_id}/messages`, message).toPromise()
  }

  getChatById(chat_id: any) : Promise <any> {
    return this.http.get(`${this.url}${this.endpoint}/${chat_id}/messages`).toPromise()
  }

  observeChat(chat_id: any) {
    return this.socketService.socketChatId(chat_id)
  }

  unsubscribeChat(chat_id: any){
    this.socketService.unsubscribeChat(chat_id);
  }
}
