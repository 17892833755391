<section>
  <h3>{{data.product.product_name}}</h3>
  <div class="d-flex justify-content-between w-100">
    <div class="subsection">
      <img [src]="product_by_id.pictures[0]?.url" alt="">
    </div>
    <div class="subsection" style="display: flex; flex-direction: column; padding: 2vh 0; gap: 1.5vh;">
      <h5>{{data.product.product_base_price | dynamic: 'currency'}} x {{productCounter}}</h5>
      <h4>{{data.product.product_base_price * productCounter | dynamic: 'currency'}}</h4>
      <div class="number_products_container">
        <div class="number_products_button" (click)="decrementCounter()"> - </div>
        <h1 class="number_products_text">{{productCounter}}</h1>
        <div class="number_products_button" (click)="increaseCounter()"> + </div>
      </div>
    </div>
  </div>
</section>
