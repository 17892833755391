import { Component, OnInit } from '@angular/core';

import { SellerEubeboApiService } from 'src/app/services/sellers-eubebo-api.service';
import { ProdutoPageComponent } from '../produto-page/produto-page.component';
import { MatDialog } from '@angular/material/dialog';
import { CartEubeboService } from 'src/app/services/cart-eubebo.service';

@Component({
  selector: 'app-inicio-page',
  templateUrl: './inicio-page.component.html',
  styleUrls: ['./inicio-page.component.scss']
})
export class InicioPageComponent implements OnInit {

  larguraTela: number = window.innerWidth;

  productCounter: number = 0
  productsInCart: any = 0

  slides = [
    {
      image: 'https://fs-vendola-images.s3.amazonaws.com/5d04a92a46e07252c712-4.png'
    },
    {
      image: 'https://fs-vendola-images.s3.amazonaws.com/ebc4e777f3dc04495e24-Site2.png'
    }
  ]

  menu = [
    {
      texto: "Delivery",
      imagem: "../../../assets/images/delivery.png",
      url: "delivery"
    },
    {
      texto: "Cafés",
      imagem: "https://fs-vendola-images.s3.amazonaws.com/76379a413a557ec5fba5-10.png",
      url: "busca/cafe"
    },
    {
      texto: "Cervejas",
      imagem: "https://fs-vendola-images.s3.amazonaws.com/fd950d98908024724b09-9.png",
      url: "busca/cerveja"
    },
    {
      texto: "Destilados",
      imagem: "https://fs-vendola-images.s3.amazonaws.com/6464e8973e455b5830dd-8.png",
      url: "busca/destilado"
    },
    {
      texto: "Hard Seltzer",
      imagem: "https://fs-vendola-images.s3.amazonaws.com/63c9f09490919b235034-7.png",
      url: "busca/seltzer"
    },
    {
      texto: "Vinhos",
      imagem: "https://fs-vendola-images.s3.amazonaws.com/16790bdea2b757792dfc-6.png",
      url: "busca/vinho"
    },
    {
      texto: "Águas",
      imagem: "https://fs-vendola-images.s3.amazonaws.com/a698c967b41ec08d2216-5.png",
      url: "busca/agua"
    },
    {
      texto: "Acessórios",
      imagem: "https://fs-vendola-images.s3.amazonaws.com/5964988c6a120eaa6649-2.png",
      url: "busca/acessorio"
    },
    {
      texto: "Energéticos",
      imagem: "https://fs-vendola-images.s3.amazonaws.com/682bf05f0b636c115e9b-3.png",
      url: "busca/energetico"
    },
    {
      texto: "Sucos",
      imagem: "https://fs-vendola-images.s3.amazonaws.com/48f3df622e014293ea25-4.png",
      url: "busca/suco"
    },
    {
      texto: "Whiskies",
      imagem: "https://fs-vendola-images.s3.amazonaws.com/7064a3bfa89e942887f0-1.png",
      url: "busca/whisky"
    }
  ]

  cafes = [
    {
      nome: "Café Trindade Lata Grãos Catucai Amarelo - 250g",
      valor: 19.99,
      imagem: "https://fs-vendola-images.s3.amazonaws.com/63a1ad05e27984edfb8d-63eaf4a26b99228ff8baDesignsemnome54.png",
    },
    {
      nome: "Café Tiaki Orgânico Torrado Moído - 250g",
      valor: 19.90,
      imagem: "https://fs-vendola-images.s3.amazonaws.com/697fb48f74a8d2b8964a-2eda7cd7e41382de51c838bf76f6050177d57d0c35e141a3eb764d16a08be72055628ac5.jpg",
    },
    {
      nome: "Café Tiaki Gourmet Torrado em Grão - 250g",
      valor: 16.90,
      imagem: "https://fs-vendola-images.s3.amazonaws.com/646ffbd4f51e645814c9-de7cba14f32bb60cd685515fff4313561eb97fdd4e066691586858d39bd009e0f31206564a329951a5904753e6a0.jpg",
    },
    {
      nome: "Café Trindade Drip Arara - 10un",
      valor: 26.99,
      imagem: "https://fs-vendola-images.s3.amazonaws.com/7c5120a9f480f9d297eb-48cd617b32c2e1dbf2d7Designsemnome56.png",
    },
    {
      nome: "Café Trindade Lata Grãos Catucai Amarelo - 250g",
      valor: 19.99,
      imagem: "https://fs-vendola-images.s3.amazonaws.com/63a1ad05e27984edfb8d-63eaf4a26b99228ff8baDesignsemnome54.png",
    },
    {
      nome: "Café Tiaki Orgânico Torrado Moído - 250g",
      valor: 19.90,
      imagem: "https://fs-vendola-images.s3.amazonaws.com/697fb48f74a8d2b8964a-2eda7cd7e41382de51c838bf76f6050177d57d0c35e141a3eb764d16a08be72055628ac5.jpg",
    },
    {
      nome: "Café Tiaki Gourmet Torrado em Grão - 250g",
      valor: 16.90,
      imagem: "https://fs-vendola-images.s3.amazonaws.com/646ffbd4f51e645814c9-de7cba14f32bb60cd685515fff4313561eb97fdd4e066691586858d39bd009e0f31206564a329951a5904753e6a0.jpg",
    },
    {
      nome: "Café Trindade Drip Arara - 10un",
      valor: 26.99,
      imagem: "https://fs-vendola-images.s3.amazonaws.com/7c5120a9f480f9d297eb-48cd617b32c2e1dbf2d7Designsemnome56.png",
    },
  ]

  constructor(
    public dialog: MatDialog,
    private sellerEubeboApiService: SellerEubeboApiService,
    private cartEubeboService: CartEubeboService
    ) { }

  ngOnInit(): void {
  }

  openDialog(
    nome: string,
    descricao: string,
    imagem: string,
    vendedor_id: string,
    valor: number,
    rate: number,
    quantidade: number,
    volume: number,
    volume_tipo: string
    ) {
    const dialogRef = this.dialog.open(ProdutoPageComponent, {
      panelClass: 'container-product',
     
      data: {
        nome: nome,
        descricao: descricao,
        imagem: imagem,
        vendedor_id: vendedor_id,
        valor: valor,
        rate: rate,
        quantidade: quantidade,
        volume: volume,
        volume_tipo: volume_tipo
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })
  }

  increaseCounter(): void {
    this.productCounter ++
  }

  decrementCounter(): void {
    this.productCounter --
  }

  defineCounter(value: number): void {
    this.productCounter == value
  }

  addProductToCart() {
    const atualCartNumber = Number(this.productsInCart) + this.productCounter
    this.cartEubeboService.setValue(this.cartEubeboService.numOfProducts, atualCartNumber)
  }
}
