import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit {

  qrcode: any

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<QrCodeComponent>,
    private router: Router,
  ) {
    this.qrcode = this.data.res.payment_data.pix_qrcode
  }

  ngOnInit(): void {

  }

  copyQrCodeToClipboard() {
    navigator.clipboard.writeText(this.qrcode)
  }

  redirectToOrders() {
    this.router.navigate(['/pedidos'])
    this.dialogRef.close()
  }
}
