import { Component, OnInit } from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-nothing-found-screen',
  templateUrl: './nothing-found-screen.component.html',
  styleUrls: ['./nothing-found-screen.component.scss']
})
export class NothingFoundScreenComponent implements OnInit {

  options: AnimationOptions = {
    path: '/assets/images/search.json',
  };

  constructor() { }

  ngOnInit(): void {
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.setSpeed(1);
  }

}
