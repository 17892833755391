<section>
<!--   <div class="close-button">
    <mat-icon>close</mat-icon>
  </div> -->
  <div class="uppon-container">
    <div (click)="close(data.screen)" class="back-button">
      <mat-icon class="back-button-icon">arrow_back_ios</mat-icon>
      <p class="back-button-text">Cupom</p>
    </div>
  </div>
  <div class="bottom-container-menu" *ngIf="isOnMenu">
    <button class="button button-edit" (click)="editCupom()">Editar cupom</button>
    <button class="button button-delete" (click)="removeCupom()">Remover cupom</button>
  </div>
  <form *ngIf="isEditingCupom" [formGroup]="cupomForm" class="bottom-container">
    <input formControlName="cupom" [placeholder]="cupom" class="cupom-input" type="text">
    <button class="button-add" (click)="adicionarCupom()">Adicionar</button>
  </form>
</section>
