import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-product-error-dialog',
  templateUrl: './add-product-error-dialog.component.html',
  styleUrls: ['./add-product-error-dialog.component.scss']
})
export class AddProductErrorDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddProductErrorDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  closeModal(): void {
    this.dialogRef.close(false)
  }

  acceptModal(): void{
    this.dialogRef.close(true)
  }


}
