import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OrdersEubeboApiService } from 'src/app/services/orders-eubebo-api.service';
import { QrCodeComponent } from '../qr-code/qr-code.component';
import { SuccessTrasitionComponent } from '../success-trasition/success-trasition.component';
import { CartEubeboService } from 'src/app/services/cart-eubebo.service';
import { DynamicPipe } from 'src/app/config/pipe/dynamic-pipes/dynamic.pipe';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  providers: [DynamicPipe]
})
export class ConfirmModalComponent implements OnInit {

  paymentMethodTitle: string = ''
  paymentMethodText: string = ''

  seller: any

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ConfirmModalComponent>,
    private ordersEubeboApiService: OrdersEubeboApiService,
    private cartService: CartEubeboService,
    private dynamicPipe: DynamicPipe,
  ) {
    console.log(data);
    this.syncPaymentMethod()
  }

  ngOnInit(): void {
    this.seller = this.data.seller
  }

  createOrder() {

    if(this.data.body){
      this.data.body.payment_data['exchange_amount'] = (this.data.body.payment_data.exchange_amount * 100)
    }

    this.ordersEubeboApiService.createOrder(this.data.body).subscribe({
      next: (res: any) => {

        this.cartService.removeAllValues();

        if (this.data.body.payment_data?.method == 'PIX') {
          const dialogRef = this.dialog.open(QrCodeComponent, {
            disableClose: true,
            data: {
              res: res[0]
            }
          })

          dialogRef.afterClosed().subscribe((result) => {
            this.dialogRef.close()
          })
        } else if (this.data.body.payment_data?.method == 'CREDIT_CARD' || this.data.body.payment_data?.method == 'ON_DELIVERY') {
          const dialogRef = this.dialog.open(SuccessTrasitionComponent, {
            disableClose: true,
            data: {
              res: res[0]
            }
          })

          dialogRef.afterClosed().subscribe((result) => {
            this.dialogRef.close()
          })
        }
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  syncPaymentMethod() {

    if (this.data.body.payment_data?.method == 'PIX') {
      this.paymentMethodTitle = 'Pagamento com Pix'
      this.paymentMethodText = 'QR Code ou código copia e cola'
    } else if (this.data.body.payment_data?.method == 'CREDIT_CARD') {
      this.paymentMethodTitle = 'Pagamento com Cartão de Crédito'
      this.paymentMethodText = `Cartão com final ${this.data.last_four_digits}`
    } else if (this.data.body.payment_data?.method == 'ON_DELIVERY') {
      this.paymentMethodTitle = 'Pagamento na entrega'
      this.paymentMethodText = 'Pagamento será realizado na entrega'
      if(this.data.body.payment_data.delivery_payment_method == "bank_notes") {
        if(this.data.body.payment_data.has_exchange){
          this.paymentMethodText+= ' através de dinheiro, e com torco para ' + this.dynamicPipe.currency(this.data.body.payment_data.exchange_amount);
        } else {
          this.paymentMethodText+= ' através de dinheiro, e sem troco'
        }
      } else if(this.data.body.payment_data.delivery_payment_method == "card"){
        this.paymentMethodText+= ' atraves de maquina de cartão da propria loja'
      }

    
    }
  }

  closeModal(): void {
    this.dialogRef.close()
    // location.reload()
  }
}
