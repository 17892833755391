<mat-card class="card-body m-2" (mouseover)="mouseover=true" (mouseout)="mouseover=false" (click)="selectCreditCard(credit_card)" [ngClass]="{'card-selected mat-elevation-z6': selected, 'mat-elevation-z4' : mouseover}">
  <span class="selection">
      <mat-icon>check_circle</mat-icon>
  </span>

  <div class="card-container">
   
    <h6>{{credit_card?.brand}}</h6>
    <h6 *ngIf="credit_card?.lastFourDigits" class="display-name mt-2">**** {{credit_card.lastFourDigits}}</h6>
    <small style="font-size: 0.8rem; text-align: end;">{{credit_card?.expMonth.toString().padStart(2, '0')}}/{{credit_card?.expYear}}</small>
    
  </div>

  

  <!-- <h2 className="full_address card-text">{{address.address?.full_address}}</h2> -->
</mat-card>


