import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DeliveryPageComponent } from "../pages/delivery-page/delivery-page.component";
import { BuscaPageComponent } from "../pages/busca-page/busca-page.component";
import { SeeCategoryComponent } from "../pages/lojas-page/see-category/see-category.component";
import { LojasPageComponent } from "../pages/lojas-page/lojas-page.component";
import { ProdutoSinglepageComponent } from "../pages/produto-singlepage/produto-singlepage.component";
import { CarrinhoPageComponent } from "../pages/carrinho-page/carrinho-page.component";
import { CheckoutPageComponent } from "../pages/checkout-page/checkout-page.component";
import { PedidosPageComponent } from "../pages/pedidos-page/pedidos-page.component";
import { EnderecosPageComponent } from "../pages/enderecos-page/enderecos-page.component";
import { FavoritosPageComponent } from "../pages/favoritos-page/favoritos-page.component";
import { EditarPerfilPageComponent } from "../pages/editar-perfil-page/editar-perfil-page.component";
import { AtendimentoAoClientePageComponent } from "../pages/atendimento-ao-cliente-page/atendimento-ao-cliente-page.component";
import { FaqPageComponent } from "../pages/faq-page/faq-page.component";
import { NotFoundPageComponent } from "../pages/not-found-page/not-found-page.component";
import { NavigationComponent } from './navigation.component';
import { PaymentMethodsPageComponent } from "../pages/payment-methods-page/payment-methods-page.component";
import { ChatComponent } from "../pages/chat/chat.component";

const routes: Routes = [
  {
    path: '',
    component: NavigationComponent,
    children: [
      {
        path: '',
        component: DeliveryPageComponent
      },
      {
        path: 'busca',
        component: BuscaPageComponent
      },
      {
        path: 'busca/:slug',
        component: BuscaPageComponent,
      },
      {
        path: 'lojas/:slug/:id/:category_id',
        component: SeeCategoryComponent
      },
      {
        path: 'lojas/:slug/:id',
        component: LojasPageComponent,
        pathMatch: 'full'
      },
      {
        path: 'produto/:slug/:id',
        component: ProdutoSinglepageComponent
      },
      {
        path: 'delivery',
        component: DeliveryPageComponent
      },
      {
        path: 'carrinho',
        component: CarrinhoPageComponent
      },
      {
        path: 'metodos-pagamento',
        component: PaymentMethodsPageComponent
      },
      {
        path: 'checkout',
        component: CheckoutPageComponent
      },
      {
        path: 'pedidos',
        children: [
          {
            path: '',
            component: PedidosPageComponent,
          },
          {
            path: ':order_id',
            loadChildren: () => import('../pages/pedidos-page/order-detail/order-detail.module').then(m => m.OrderDetailModule)
            
          }
        ]
      },
      {
        path: 'chat',
        loadChildren: () => import('../pages/chat/chat.module').then(m => m.ChatModule)
      },
      {
        path: 'enderecos',
        component: EnderecosPageComponent
      },
      {
        path: 'favoritos',
        component: FavoritosPageComponent
      },
      {
        path: 'editar-perfil',
        component: EditarPerfilPageComponent
      },
      {
        path: 'atendimento-ao-cliente',
        component: AtendimentoAoClientePageComponent
      },
      {
        path: 'faq',
        component: FaqPageComponent
      },
      {
        path: 'nao-encontrado',
        component: NotFoundPageComponent
      },
      {
        path: 'venda-conosco',
        loadChildren: () =>
        import('../pages/sale-with-us/sale-with-us.module').then(
          m => m.SaleWithUsModule,
          ),
      },
      {
        path: '**',
        redirectTo: 'nao-encontrado',
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NavigationRoutingModule { }
