<div class="container mt-4">
  <div class="row" *ngFor="let row of rows; let index = index">
    <div class="item-container col-md-2 m-2 text-center border p-2" (click)="openProduct(item.product_id)" *ngFor="let item of row">
      <img class="item-image" [src]="item.product_picture_url != '' ? item.product_picture_url : 'https://fs-vendola-images.s3.amazonaws.com/15e54fb25457ccf6d461-vendola9.png'" alt="">
      <mat-divider></mat-divider>
      <h4 class="item-product-name">{{ item.product_name }}</h4>
      <div className="mt-2">
        <h5 *ngIf="item.product_base_price / 100 != calculateSubmenuItemsPrice(item.product_base_price, item.product_id)">de <span style="text-decoration: line-through;">{{item.product_base_price / 100 | dynamic: 'currency'}}</span> por</h5>
        <h4 *ngIf="item.product_base_price / 100 != calculateSubmenuItemsPrice(item.product_base_price, item.product_id)" class="item-product-price">{{calculateSubmenuItemsPrice(item.product_base_price, item.product_id) | dynamic: 'currency'}}</h4>
        <h5 *ngIf="item.product_base_price / 100 == calculateSubmenuItemsPrice(item.product_base_price, item.product_id)" class="item-product-price">{{item.product_base_price / 100 | dynamic: 'currency'}}</h5>
      </div>
    </div>
  </div>
</div>
