<div class="home-page__content messages-page">
  <div class="container-fluid h-100">
    <div class="row px-0 h-100">
     
      <div class="chat col-12 col-md-8 col-lg-8 col-xl-9 px-0 pl-md-1">
        
        <div class="chat__container">   
          <div class="back-button">
            <a class="d-block p-3 w-100" style="color: black; text-decoration: underline;" [routerLink]="['/pedidos', chat?.order?._id]">
              <mat-icon class="back-button-icon">arrow_back_ios</mat-icon>
              Voltar para pedido
            </a>
          </div>     
          <div  *ngIf="innerWidth < 768px" class="header-chat">
            <div class="d-flex align-items-center justify-content-center mb-2">
              <img class="image-seller" [src]="chat?.seller?.logo_url || '/assets/images/Sìmbolo-roxo.png'" [alt]="chat?.seller?.display_name" loading="lazy">

              <div>
                <h2 class="name-seller m-0">{{chat?.seller?.name || 'Sem nome'}}</h2>
                <div class="d-flex w-100 row align-items-center justify-content-start">
                  <app-start-rate [size]="15" [clickable]="false" [rate]="chat?.seller?.rate"></app-start-rate>
                  <span class="ml-2">{{chat?.seller?.rate | dynamic: 'decimal'}} ({{chat?.seller?.rateCount}})
                      Avaliações
                  </span>
                </div>
              </div>
            </div>
            <a [routerLink]="['/pedidos', chat?.order?._id]" class="card-order d-block p-3 w-100">
              Pedido: <span style="color: black; text-decoration: underline;" class="pl-2" > {{chat?.order?.order_number | dynamic: 'numPedido'}} </span>
            </a>
          </div>

          <div *ngIf="chat" class="chat__wrapper py-2 pt-mb-2 pb-md-3">
            <div class="chat__content pt-4 px-3">
               <ul class="chat__list-messages">
                <li *ngFor="let message of chat?.messages; let i = index">
                  <div *ngIf="checkChat(message, i)" class="chat__time">
                    {{message?.created_at | dynamic: 'fullDateTime'}}
                  </div>
                  <div class="chat__bubble" [ngClass]="{'chat__bubble--you': message.from === 'seller' , 'chat__bubble--me': message.from === 'customer' }">
                   {{message.message }}
                  </div>
                </li>


              </ul>
              <div *ngIf="chat.messages.length === 0" class=" d-flex justify-content-center align-items-center" >
                <h4> Sem mensagens</h4>
              </div>


            </div>
            <div class="chat__send-container px-2 px-md-3 pt-1 pt-md-3">
              <div class="custom-form__send-wrapper">
                <textarea type="text" [(ngModel)]="inputMessage" class="form-control custom-form" rows="2" id="message" placeholder="Digite uma mensagem" autocomplete="off" (keyup.enter)="sendMessage()"></textarea>
                <button (click)="sendMessage()" type="submit" class="custom-form__send-submit">
                  <svg xmlns="http://www.w3.org/2000/svg" class="svg-icon svg-icon--send" viewBox="0 0 45.6 45.6">
                    <g>
                      <path d="M20.7,26.7a1.4,1.4,0,0,1-1.2-.6,1.6,1.6,0,0,1,0-2.4L42.6.5a1.8,1.8,0,0,1,2.5,0,1.8,1.8,0,0,1,0,2.5L21.9,26.1A1.6,1.6,0,0,1,20.7,26.7Z" fill="#d87232" />
                      <path d="M29.1,45.6a1.8,1.8,0,0,1-1.6-1L19.4,26.2,1,18.1a1.9,1.9,0,0,1-1-1.7,1.8,1.8,0,0,1,1.2-1.6L43.3.1a1.7,1.7,0,0,1,1.8.4,1.7,1.7,0,0,1,.4,1.8L30.8,44.4a1.8,1.8,0,0,1-1.6,1.2ZM6.5,16.7l14.9,6.6a2,2,0,0,1,.9.9l6.6,14.9L41,4.6Z" fill="#d87232" />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="innerWidth >= 768px" class="col-12 col-md-4 col-lg-4 col-xl-3 px-4 px-sm-5 px-lg-4 user-profile">
        <div class="user-profile__wrapper">
          <div  class="user-profile__avatar">
            <img [src]="chat?.seller?.logo_url || '/assets/images/Sìmbolo-roxo.png'" [alt]="chat?.seller?.display_name" loading="lazy">
          </div>
          <div class="user-profile__details mt-1">
            <span class="user-profile__name">{{chat?.seller?.name || 'Sem nome'}}</span>
            <div class="user-profile__description">
              <div class="d-flex w-100 row align-items-center justify-content-start my-2">
                <app-start-rate [size]="15" [clickable]="false" [rate]="chat?.seller?.rate"></app-start-rate>
                <span class="ml-2">{{chat?.seller?.rate | dynamic: 'decimal'}} ({{chat?.seller?.rateCount}})
                    Avaliações
                </span>
              </div>
              <mat-divider class="my-2"></mat-divider>
            <a [routerLink]="['/pedidos', chat?.order?._id]" style="border-radius: 20px 0 20px 20px !important; background-color: #ccc; color: black; text-decoration: none;" class=" d-block p-3 w-100" >
              Pedido: <span style="color: black; text-decoration: underline;" class="pl-2" > {{chat?.order?.order_number | dynamic: 'numPedido'}} </span>
            </a>
            </div>
          </div>
          <mat-divider class="my-2"></mat-divider>
        </div>
      </div>
      <!-- end content section  -->
      <!-- start infos section  -->

      <!-- end infos section  -->
    </div>
  </div>
</div>
