<section *ngIf="larguraTela >= 993 || larguraTela >= 576 && larguraTela <= 992">
  <div class="div-conteudo">
    <div class="div-links">
      <h1>Central do cliente</h1>
      <a href="https://eubebo.com.br/politica-de-privacidade">Política de Privacidade</a>
      <a href="https://eubebo.com.br/termos-e-condicoes-de-uso">Termos e Condições de Uso</a>
      <a href="https://eubebo.com.br/politica-de-entrega">Política de Entrega</a>
      <a href="https://eubebo.com.br/formas-de-pagamento">Formas de Pagamento</a>
      <a href="https://eubebo.com.br/trocas-e-devolucoes">Trocas e devoluções</a>
    </div>
    <div class="div-sobre">
      <h1>Atendimento</h1>
      <p>(37) 99815-5803</p>
      <p>de Segunda a Sexta, das 08:00 às 18:00</p>
      <p>contato@eubebo.com.br</p>
      <p>eubebo - CNPJ: 41.172.138/0001-55</p>
      <p>Endereço: Rua Barão de Piumhi, 392 - </p>
      <p>Centro, Formiga/MG - CEP</p>
      <p>35.570-128</p>
    </div>
  </div>
  <div class="div-imagem">
    <img [src]="eubeboLogo" alt="">
  </div>
</section>
