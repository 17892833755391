import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthUserService {

  tokenKey = 'AUTH_TOKEN'
  userName = 'USER_NAME'
  userId = 'USER_ID'

  constructor() { }

  // Token

  setValue(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  getValue(key: string): string | null {
    return localStorage.getItem(key);
  }

  removeValue(key: string): void {
    localStorage.removeItem(key)
  }

  removeAll() {
    localStorage.clear()
  }
}
