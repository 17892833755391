<section class="container mb-2">
  <h3>Pedidos</h3>
  <div class="w-100">
    <mat-tab-group class="w-100" mat-align-tabs="center">

      <mat-tab class="w-100" label="Em andamento">

        <div class="container">
          <div *ngFor="let listOrders of orders_in_progress; " class="row mt-2">
            <h5>{{listOrders.date | dynamic: 'fullDateView' | titlecase}}</h5>
            <div class=" col-12 col-md-6 my-2" *ngFor="let order of listOrders.orders; let index = index">
              <div class="item-container p-2">
                <div class="upper-container">
                  <img
                    [src]="order.seller?.logo_url || 'https://fs-vendola-images.s3.amazonaws.com/e7d6cb58e66ff72da68d-4.png'"
                    alt="">
                  <div class="seller_infos">
                    <h5>{{order.seller.name}}</h5>
                    <small>{{getStatusByStatusId(order.status)}} - Nº {{order.order_number}}</small>
                  </div>
                </div>
                <mat-divider style="margin-bottom: 1vh;"></mat-divider>
                <div class="middle-container" >
                  <p class="order-item" >
                    <span class="order-item-quantity ">{{order.items[0].quantity}}</span> {{order.items[0].name}}
                  </p>
                  <small *ngIf="order?.items && order.items.length > 1">mais {{ order.items.length - 1}} itens</small>
                </div>
                <mat-divider ></mat-divider>
                <div class="bottom-container mt-1">
                  <button mat-button class="mr-1" color="primary" [routerLink]="['/chat/', order?.id,  order?.seller?.seller_id,  ]">Chat</button>
                  <button mat-button class="mx-1" color="primary" (click)="openHelpPage(order.id, order.customer.id)">Ajuda</button>
                  <button mat-button class="mx-1" color="primary" [routerLink]="['/pedidos', order?.id,  ]">Acompanhar</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab class="w-100" label="Concluído">
        <div class="container">
          <div *ngFor="let listOrders of orders_completed; " class="row mt-2">
            <h5>{{listOrders.date | dynamic: 'fullDateView' | titlecase}}</h5>
            <div class="col-12 col-md-6 my-2" *ngFor="let order of listOrders.orders; let index = index">
              <div class="item-container p-2">
                <div class="upper-container">
                  <img
                    [src]="order.seller?.logo_url || 'https://fs-vendola-images.s3.amazonaws.com/e7d6cb58e66ff72da68d-4.png'"
                    alt="">
                  <div class="seller_infos">
                    <h5>{{order.seller.name}}</h5>
                    <small>{{getStatusByStatusId(order.status)}} - Nº {{order.order_number}}</small>
                  </div>
                </div>
                <mat-divider style="margin-bottom: 1vh;"></mat-divider>
                <div class="middle-container" >
                  <p class="order-item" >
                    <span class="order-item-quantity ">{{order.items[0].quantity}}</span> {{order.items[0].name}}
                  </p>
                  <small *ngIf="order?.items && order.items.length > 1">mais {{ order.items.length - 1}} itens</small>
                </div>
                <mat-divider ></mat-divider>
                <div class="bottom-container mt-1">
                  <button mat-button class="mr-1" color="primary" [routerLink]="['/chat/', order?.id,  order?.seller?.seller_id,  ]">Chat</button>
                  <button mat-button class="mx-1" color="primary" (click)="openHelpPage(order.id, order.customer.id)">Ajuda</button>
                  <button mat-button class="mx-1" color="primary" [routerLink]="['/pedidos', order?.id,  ]">Acompanhar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>

    </mat-tab-group>
  </div>
</section>