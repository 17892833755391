import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-start-rate',
  templateUrl: './start-rate.component.html',
  styleUrls: ['./start-rate.component.scss']
})
export class StartRateComponent implements OnInit {

  @Input() clickable = false;
  @Input() size = 1;
  @Input() rate: number = 0;

  @Output() emitRate = new EventEmitter();
  public starsArrays: Array<any> = [
    { value: 1, typeStar: 'empty'},
    { value: 2, typeStar: 'empty'},
    { value: 3, typeStar: 'empty'},
    { value: 4, typeStar: 'empty'},
    { value: 5, typeStar: 'empty'},
  ];
  loading = false;
  constructor() { }

  ngOnInit(): void {
    this.setStars(this.rate, false);
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.setStars(this.rate, false);
  }

  setStars(rate: number , click?: boolean){
    this.loading = true;
    //se for clicavel e tive um click não executa
    if( !(!this.clickable && click) ){
      for(let s = 0 ; s < this.starsArrays.length; s++){
        let element = this.starsArrays[s];

        if ( rate > s && rate < s + 1){
          element.typeStar  = 'half';
        } else if(rate < s + 1){
          element.typeStar  = 'empty';
        }else {
          element.typeStar = 'full';
        }
      }
      this.starsArrays = this.starsArrays;
      this.emitRate.emit(rate);
    }
    this.loading = false;
  }
}
