import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentAddressService {

  state = 'STATE_ADDRESS'
  city = 'CITY_ADDRESS'
  zipcode = 'ZIPCODE_ADDRESS'
  neighborhood = 'NEIGHBORHOOD_ADDRESS'
  street = 'STREET_ADDRESS'
  street_number = 'STREET_NUMBER_ADDRESS'
  residence_number = 'RESIDENCE_NUMBER'
  complement = 'RESIDENCE_COMPLEMENT'
  reference = 'RESIDENCE_REFERENCE'
  address_lat = 'ADDRESS_LAT'
  address_long = 'ADDRESS_LONG'
  address_id = 'ADDRESS_ID'

  emitAddress= new EventEmitter();

  constructor(
    private http: HttpClient
  ) { }

  setValue(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  getValue(key: string): string | null {
    return localStorage.getItem(key);
  }

  removeValue(key: string): void {
    localStorage.removeItem(key)
  }

  removeAll() {
    localStorage.clear()
  }

  refreshAddress(res: any) {
    this.setValue(this.zipcode, res.address.zipcode)
    this.setValue(this.state, res.address.state)
    this.setValue(this.city, res.address.city)
    this.setValue(this.neighborhood, res.address.neighborhood)
    this.setValue(this.street, res.address.street)
    this.setValue(this.street_number, res.address.street_number)
    this.setValue(this.residence_number, res.address.complementary)
    this.setValue(this.address_lat, res.address.location.coordinates[0])
    this.setValue(this.address_long, res.address.location.coordinates[1])

    console.log(res)
    if(res?._id){
      this.setValue(this.address_id, res._id)
    }

    setTimeout(() => {
      this.emitAddress.emit(res);
    }, 500)
  }

  getAddressByCEP(cep: string): Observable<any> {
    return this.http.get(`https://viacep.com.br/ws/${cep}/json/`)
  }

  getAddressLatAndLong( city:string, street:string, street_number: string, neighborhood: string, uf: string) {
    const street_formated = street?.replace(' ', '+')
    let city_formated = city?.replace(' ', '+');
    let neighborhood_formated = neighborhood?.replace(' ', '+');

    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${street_number}+${street_formated},${neighborhood_formated}+${city_formated}&key=AIzaSyD4Dx_pOUPoDlTUQ1ZEov8__nfAvMHy4ao`)
  }
}
