<section>
  <div class="h-100" *ngIf="!isEdit">

    <div *ngIf="data?.deactivatable != false" (click)="closeModal()" class="close-button">
      <mat-icon>close</mat-icon>
    </div>

    <div class="upper-container">

      <div *ngIf="isOnFirstScreen && !isEdit || isOnSecondScreen && !isEdit" class="image-container">
        <img class="content-container-image" [src]="mapaImagem" alt="">
        <p style="width: 100%;">As melhores ofertas e condições de frete para a sua região</p>
      </div>

      <form [formGroup]="addressForm" class="input-container">
        <div *ngIf="isOnFirstScreen  && !isEdit" class="first-screen">
          <p>
            <mat-form-field class="input" appearance="outline">
              <mat-label>Digite seu CEP</mat-label>
              <input class="input" matInput mask="00000-000" formControlName="zipcode">
              <mat-error *ngIf="addressForm.get('zipcode')?.invalid ">
                <span *ngIf="addressForm.get('zipcode')?.hasError('required') ">Cep é obrigatório</span>
                <span *ngIf="addressForm.get('zipcode')?.hasError('minlength') ">Precisa ter 8 caracteres</span>
                <span *ngIf="addressForm.get('zipcode')?.hasError('maxlength') ">Precisa ter 8 caracteres</span>
              </mat-error>       
            </mat-form-field>
           
          </p>
          <button class="input-address-button" (click)="saveZipcode()">Buscar</button>
        </div>

        <div *ngIf="isOnSecondScreen  && !isEdit" class="second-screen">
          <div *ngIf="!isGenericZipcode  && !isEdit" class="second-screen">
            <p>
              <mat-form-field class="input" appearance="outline">
                <mat-label>Informe o número</mat-label>
                <input class="input" type="tel" mask="0*" matInput formControlName="num_street">
                <mat-error *ngIf="addressForm.get('num_street')?.invalid ">
                  <span *ngIf="addressForm.get('num_street')?.hasError('required') ">Número é obrigatório</span>
                </mat-error>  
              </mat-form-field>
            </p>

            <button class="input-address-button" (click)="saveStreetNumber()">
              Usar
            </button>
          </div>
          <div *ngIf="isGenericZipcode  && !isEdit" class="generic-zipcode-container">
            <p>
              <mat-form-field class="input" appearance="outline">
                <mat-label>Rua/Avenida/Via</mat-label>
                <input class="input" matInput formControlName="street">
                <mat-error *ngIf="addressForm.get('street')?.invalid ">
                  <span *ngIf="addressForm.get('street')?.hasError('required') ">Logradouro é obrigatório</span>
                </mat-error>  
              </mat-form-field>
            </p>

            <p>
              <mat-form-field class="input" appearance="outline">
                <mat-label>Informe o número</mat-label>
                <input class="input" type="tel" matInput mask="0*" formControlName="num_street">
                <mat-error *ngIf="addressForm.get('num_street')?.invalid ">
                  <span *ngIf="addressForm.get('num_street')?.hasError('required') ">Número é obrigatório</span>
                </mat-error>  
              </mat-form-field>
            </p>

            <p>
              <mat-form-field class="input" appearance="outline">
                <mat-label>Bairro</mat-label>
                <input class="input" matInput formControlName="neighborhood">
                <mat-error *ngIf="addressForm.get('neighborhood')?.invalid ">
                  <span *ngIf="addressForm.get('neighborhood')?.hasError('required') ">Logradouro é obrigatório</span>
                </mat-error> 
              </mat-form-field>
            </p>

            <!-- trocar botão -->
            <button class="input-address-button" (click)="saveStreetNumber()">
                Usar
            </button>
          </div>

        </div>

        <div *ngIf="isOnThirdScreen  && !isEdit" class="third-screen">

          <span class="current-address">
            <h4>
              {{addressInfo.logradouro != "" && addressInfo.logradouro != null ?
              addressInfo.logradouro + ', ' + addressForm.value.num_street :
              addressForm.value.street + ', ' + addressForm.value.num_street
              }}
            </h4>
            <small>
              {{addressInfo.bairro != null && addressInfo.bairro != '' ?
                addressInfo.bairro + ' - ' + addressInfo.localidade :
                addressForm.value.neighborhood + ' - ' + addressInfo.localidade
              }}
            </small>
          </span>

          <div class="input-and-checkbox-container">
            <p>
              <mat-form-field class="input" appearance="outline">
                <mat-label>Complemento</mat-label>
                <input class="input" matInput formControlName="complement">
              </mat-form-field>
            </p>
            <mat-checkbox (change)="markCheckbox($event, 'complement-address')" color="primary">Não possui</mat-checkbox>
          </div>

          <div class="input-and-checkbox-container">
            <p>
              <mat-form-field class="input" appearance="outline">
                <mat-label>Ponto de Referência</mat-label>
                <input class="input" matInput formControlName="reference">
              </mat-form-field>
            </p>
            <mat-checkbox (change)="markCheckbox($event, 'reference-address')" color="primary">Não possui</mat-checkbox>
          </div>

          <div class="fav-address">
            <p>Favoritar endereço</p>
            <div class="button-address-name-container">
              <button [class]="isCasaSelected ? 'button-selected button-address-name' : 'button-address-name'" (click)="selectAddressName('Casa')">Casa</button>
              <button [class]="isTrabalhoSelected ? 'button-selected button-address-name' : 'button-address-name'" (click)="selectAddressName('Trabalho')">Trabalho</button>
            </div>
          </div>

          <!-- <div  *ngIf="user_id" class="save-address-checkbox">
            <mat-checkbox color="primary" (change)="markCheckbox($event, 'save-address')">Salvar este endereço</mat-checkbox>
          </div> -->

          <button class="input-address-save-button" (click)="saveAddress()">Selecionar endereço</button>

        </div>

        <div *ngIf="isOnDeliveryInfoScreen  && !isEdit" class="delivery-info-screen">

          <div>
            <img class="content-container-image" [src]="mapaImagem" alt="">
          </div>

          <p>
            <mat-form-field class="input-large" appearance="outline">
              <mat-label>Nome de quem irá receber</mat-label>
              <input class="input" matInput formControlName="receiver_name">
            </mat-form-field>
          </p>

          <p>
            <mat-form-field class="input-large" appearance="outline">
              <mat-label>Telefone fixo</mat-label>
              <input mask="(00) 0000-00000" class="input" matInput formControlName="phone_number">
            </mat-form-field>
          </p>

          <p>
            <mat-form-field class="input-large" appearance="outline">
              <mat-label>Telefone celular</mat-label>
              <input mask="(00) 0000-00000" class="input" matInput formControlName="cellphone_number">
            </mat-form-field>
          </p>

          <button style="margin-top: 1.5vh;" class="input-address-save-button" (click)="goToMap()">Salvar Endereço</button>
        </div>

        <div *ngIf="isOnFourthScreen  && !isEdit" class="fourth-screen">
          <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" [streetViewControl]="false" class="map-style">
            <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
            (dragEnd)="markerDragEnd($event)"></agm-marker>
          </agm-map>


          <button class="confirm-location" (click)="finalizeModal(false)">Confirmar localização</button>
        </div>

      </form>

    </div>

    <div *ngIf="isOnFirstScreen  && !isEdit || isOnSecondScreen && !isGenericZipcode  && !isEdit" class="division my-3">

      <div class="division-bar"></div>
      <p>ou</p>
      <div class="division-bar"></div>

    </div>

    <div *ngIf="isOnFirstScreen && user_id == null  && !isEdit || isOnSecondScreen && !isGenericZipcode && user_id == null  && !isEdit" class="bottom-container">

      <p>Já tem um endereço salvo?</p>
      <p>Entre na sua conta para selecionar seu endereço.</p>
      <button (click)="openModal('login')" class="login-button">Entrar na Conta</button>
      <button (click)="redirectToRegisterPage()" class="register-button">Criar Conta</button>

    </div>
    <div *ngIf="isOnFirstScreen && user_id != null && !isEdit|| isOnSecondScreen && !isGenericZipcode && user_id != null && !isEdit" class="bottom-container">


      <mat-accordion class="w-100 gap-3" style="overflow-y: scroll;">

        <mat-expansion-panel
        class="mb-2"
        *ngFor="let addressInfo of userAddresses, let index = index"
        [class]="(isAddressSelected(index) || addressInfo._id == address_id) ? 'address-info-container-selected' : 'address-info-container'"
        hideToggle>

        
          <mat-expansion-panel-header>
            <span class="selection">
              <mat-icon>check_circle</mat-icon>
            </span>
            <mat-panel-title class="col-5">
              
              <small >{{addressInfo.address.street.replace('Rua', 'R.')}} - {{addressInfo.address.street_number}}</small>
            </mat-panel-title>
            <mat-panel-description class="col-7">
              <p >{{addressInfo.address?.neighborhood}}, {{addressInfo.address.city}}</p>
              
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="d-flex justify-content-between">
            
            <button class="address-content-right-button edit-button" (click)="selectAddress(index, addressInfo)">Selecionar</button>
            <div class="d-flex gap-2">
              <button class="address-content-right-button edit-button" (click)="editAddress(addressInfo._id)">Editar</button>
              <button class="address-content-right-button delete-button" (click)="deleteAddress(user_id!, addressInfo._id)  ">Remover</button>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

  </div>
  <div *ngIf="isEdit">

    <form [formGroup]="addressForm">

      <div *ngIf="isOnFirstScreen && isEdit" class="screen-edit">

        <div class="image-container">
          <img class="content-container-image" [src]="mapaImagem" alt="">
          <p style="width: 100%;">As melhores ofertas e condições de frete para a sua região</p>
        </div>

        <p>
          <mat-form-field class="input-edit" appearance="outline">
            <mat-label>Nome do recebedor</mat-label>
            <input class="input" matInput formControlName="receiver_name">
          </mat-form-field>
        </p>

        <p>
          <mat-form-field class="input-edit" appearance="outline">
            <mat-label>Telefone celular</mat-label>
            <input class="input" matInput mask="(00) 00000-0000" formControlName="cellphone_number">
          </mat-form-field>
        </p>

        <p>
          <mat-form-field class="input-edit" appearance="outline">
            <mat-label>Telefone fixo</mat-label>
            <input class="input" matInput mask="(00) 00000-0000" formControlName="phone_number">
          </mat-form-field>
        </p>

        <p>
          <mat-form-field class="input-edit" appearance="outline">
            <mat-label>Digite seu CEP</mat-label>
            <input class="input" matInput mask="00000-000" formControlName="zipcode">
          </mat-form-field>
        </p>
      </div>
      <div *ngIf="isOnSecondScreen  && isEdit" class="screen-edit">

        <p>
          <mat-form-field class="input-edit" appearance="outline">
            <mat-label>Nome do endereço</mat-label>
            <input class="input" matInput formControlName="address_name">
          </mat-form-field>
        </p>

        <div class="d-flex gap-3">
          <mat-form-field class="select-edit" appearance="outline">
            <mat-label>País</mat-label>
            <mat-select formControlName="country">
              <mat-option *ngFor="let pais of paisesDoMundo" [value]="pais.sigla.toLowerCase()" >{{pais.nome}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="select-edit" appearance="outline">
            <mat-label>Estado</mat-label>
            <mat-select formControlName="state">
              <mat-option *ngFor="let estado of estadosBrasileiros" [value]="estado.sigla.toLowerCase()" >{{estado.nome}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <p>
          <mat-form-field class="input-edit" appearance="outline">
            <mat-label>Rua</mat-label>
            <input class="input" matInput formControlName="street">
          </mat-form-field>
        </p>

        <p>
          <mat-form-field class="input-edit" appearance="outline">
            <mat-label>Número</mat-label>
            <input class="input" matInput formControlName="num_street">
          </mat-form-field>
        </p>

        <p>
          <mat-form-field class="input-edit" appearance="outline">
            <mat-label>Bairro</mat-label>
            <input class="input" matInput formControlName="neighborhood">
          </mat-form-field>
        </p>

        <p>
          <mat-form-field class="input-edit" appearance="outline">
            <mat-label>Cidade</mat-label>
            <input class="input" matInput formControlName="city">
          </mat-form-field>
        </p>

        <p>
          <mat-form-field class="input-edit" appearance="outline">
            <mat-label>Complemento</mat-label>
            <input class="input" matInput formControlName="complement">
          </mat-form-field>
        </p>

        <p>
          <mat-form-field class="input-edit" appearance="outline">
            <mat-label>Referência</mat-label>
            <input class="input" matInput formControlName="reference">
          </mat-form-field>
        </p>

      </div>

      <div *ngIf="isOnThirdScreen" class="fourth-screen">
        <agm-map [latitude]="latitude_edit" [longitude]="longitude_edit" [zoom]="zoom" [streetViewControl]="false" class="map-style">
          <agm-marker [latitude]="latitude_edit" [longitude]="longitude_edit" [markerDraggable]="true"
          (dragEnd)="markerDragEnd($event)"></agm-marker>
        </agm-map>


        <button class="confirm-location" (click)="finalizeModal(true)">Confirmar localização</button>
      </div>

      <div class="d-flex justify-content-center gap-3 w-100  mt-3" *ngIf="isOnFirstScreen || isOnSecondScreen">
        <button class="edit-button address-content-right-button w-100" *ngIf="isOnFirstScreen" (click)="selectScreen(2)">Avançar</button>
        <button class="delete-button address-content-right-button w-50" *ngIf="isOnSecondScreen" (click)="selectScreen(1)">Voltar</button>
        <button class="edit-button address-content-right-button w-50" *ngIf="isOnSecondScreen" (click)="selectScreen(3)">Avançar</button>
      </div>

    </form>

  </div>

</section>
