<section>
  <h1 class="page-title">Endereços</h1>
  <ng-lottie width="{{larguraTela <= 575 ? '15vh' : '20vh'}}" height="{{larguraTela <= 575 ? '15vh' : '20vh'}}" [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
  <div class="address-container">
    <h1>Endereços salvos</h1>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of user_info.addresses; let index = index">
        <mat-expansion-panel-header class="address-header">
          <mat-panel-title class="address-header-title">
            <mat-icon style="font-size: 3vh; color: #3a8840;" *ngIf="item.main == true">check</mat-icon>
            {{item.receiver_name}}
          </mat-panel-title>
          <mat-panel-description class="address-header-text">
            {{item.address.full_address}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div class="address-content">
          <div class="address-content-left">
            <button  class="address-content-right-button" [disabled]="item.main" (click)="definirPrincipal(user_id!, item._id)">Salvar como principal</button>

          </div>
          <div class="address-content-right">
            <button class="address-content-right-button edit-button" (click)="editAddress(item._id)">Editar</button>
            <button class="address-content-right-button delete-button" (click)="deleteAddress(user_id!, item._id)  ">Remover</button>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <div class="new-address-container">
    <h1>Adicione um novo endereço</h1>
    <div class="division-bar"></div>
    <div>
      <button class="new-address-button" (click)="openDialog('new-address')" >
        <mat-icon>data_saver_on</mat-icon> Novo endereço
      </button>
    </div>
  </div>
</section>
