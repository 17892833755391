<section class="container p-5 d-flex flex-column gap-3">
  <h3>Métodos de pagamento</h3>

  <div class="w-100 d-flex">
    <button mat-raised-button class="h-50 d-flex justify-content-center align-items-center add-button" (click)="openModal('new-credit-card')"> <mat-icon class="mr-2 mb-3" svgIcon="credit-card"></mat-icon> <span >Novo cartão</span> </button>
  </div>


  <div *ngIf="credit_cards.length == 0">

  </div>

  <div class="row">
    <h4>Seus cartões</h4>

    <div *ngFor="let card of credit_cards" class="col-12 col-sm-6 col-md-4">
      <mat-card class="d-block mb-2 mr-2">

        <div class="d-flex align-items-start justify-content-end w-100">
          <!-- <button mat-icon-button color="primary"><mat-icon svgIcon="edit"></mat-icon></button> -->
          <mat-icon (click)="deleteCard(card._id)" style="cursor: pointer;" svgIcon="delete"></mat-icon>
        </div>
        <mat-card-header>
          <div mat-card-avatar style="font-size: 40px;">
            <mat-icon [svgIcon]="checkBrandCard(card.firstSixDigits)"  inline="true" ></mat-icon>
          </div>
          <mat-card-title>{{card.brand}} - {{card.lastFourDigits}}</mat-card-title>
          <mat-card-subtitle>{{card.expMonth.toString().padStart(2,'0') }}/{{card.expYear }}</mat-card-subtitle>

        </mat-card-header>

        <mat-card-content>
          <p>{{card.holder_name}}</p>
          <!-- <p>Av.21 de abril, 1594 - Divinópolis, MG</p> -->
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</section>
