<section>
  <div class="upper-container">
    <div class="d-flex flex-column gap-2">
      <div class="imagem-container">
        <lib-ngx-image-zoom
          [thumbImage]="product.pictures[selectedImage]?.url"
          [fullImage]="product.pictures[selectedImage]?.url"
          [enableScrollZoom]="true"
          [circularLens]="false"
          [magnification]="2"
          >
        </lib-ngx-image-zoom>
      </div>
      <div class="d-flex gap-1">
        <div class="mini-imagem" *ngFor="let image of product.pictures; let index = index">
          <img [src]="image.url" alt="" (click)="selectImage(index)">
        </div>
      </div>
    </div>
    <div class="info-container">
      <div class="d-flex flex-column gap-3">
        <h1 class="info-titulo">{{product.name}} - {{product.amount}} {{product.amount_type}}</h1>
<!--         {{current_store | json}} -->
        <h4 (click)="openStorePage(current_store.display_name, current_store_id)" style="cursor: pointer;">{{current_store.display_name}}</h4>
        <p class="info-descricao">{{product.description}}</p>
      </div>
      <div style="display: flex; flex-direction: row; justify-content: space-between;">
        <div class="info-valor-container">
          <p *ngIf="product.current_offer" class="discount-original-price">de <span class="discount-old-price">{{product.base_price / 100 | dynamic: 'currency' }}</span> por</p>
          <h2 class="info-valor">{{total_price | dynamic: 'currency'}}</h2>
        </div>
        <div>
          <div class="icons-container">
            <button (click)="shareProduct()">
              <mat-icon inline="true" style="width: 2.5vh; height: 2.5vh; font-size: 1rem;" svgIcon="share" color="primary"></mat-icon>
            </button>
            <button *ngIf="favorite" mat-icon-button (click)="notFavorite()">
              <mat-icon inline="true" style="width: 2.5vh; height: 2.5vh; font-size: 1rem;" svgIcon="heart-full"></mat-icon>
            </button>
            <button *ngIf="!favorite" mat-icon-button (click)="setFavorite()">
              <mat-icon inline="true" style="width: 2.5vh; height: 2.5vh; font-size: 1rem;" svgIcon="heart-empty" color="primary"></mat-icon>
            </button>
          </div>
          <div *ngIf="notifyCopyText" class="notification-copy-link mt-2 position-fixed">
            Link Copiado
          </div>
        </div>
      </div>
      <div *ngIf="productCounter == 0" class="w-100 d-flex align-items-center justify-content-between">
        <button (click)="increaseCounter()"  class="info-add-button">Adicionar</button>
      </div>
      <div *ngIf="productCounter > 0" class="w-100 d-flex justify-content-center">
        <div class="number-products-container" >
          <div class="number-products-counter-container">
            <button class="number-products-increment" (click)="decrementCounter()"> - </button>
            <div class="number-products-hud">{{productCounter}}</div>
            <button class="number-products-decrement" (click)="increaseCounter()"> + </button>
          </div>
          <div (click)="addProductToCart(product.id, product.name, total_price.toString(), product.seller_id, product.current_offer ?? null)" class="number-products-add-button">
            <p>Adicionar</p>
            <p>{{calculateSubmenuItemsPrice(product.base_price, product.current_offer?.individual_discount_percentage ?? 0) * productCounter | dynamic: 'currency'}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="larguraTela <= 545" class="bottom-container">
    <div class="item-container" *ngFor="let item of productsByCategory.slice(0, 2)" (click)="openDialog(item.id)">
      <div class="item-imagem-container">
        <img class="item-imagem" src="{{item.pictures[0]?.url}}" alt="">
      </div>
      <div class="item-info-container">
        <h1 class="item-info-valor">{{calculateSubmenuItemsPrice(item.base_price, item.current_offer?.individual_discount_percentage ?? 0) | dynamic: 'currency'}}</h1>
        <h2 class="item-info-nome">{{item.name}}</h2>
        <h2 class="item-info-volume">{{item.amount}} {{item.amount_type}}</h2>
      </div>
    </div>
  </div>
  <div *ngIf="larguraTela >= 546 && larguraTela <= 992" class="bottom-container">
    <div class="item-container" *ngFor="let item of productsByCategory.slice(0, 3)" (click)="openDialog(item.id)">
      <div class="item-imagem-container">
        <img class="item-imagem" src="{{item.pictures[0]?.url}}" alt="">
      </div>
      <div class="item-info-container">
        <h1 class="item-info-valor">{{calculateSubmenuItemsPrice(item.base_price, item.current_offer?.individual_discount_percentage ?? 0) | dynamic: 'currency'}}</h1>
        <h2 class="item-info-nome">{{item.name}}</h2>
        <h2 class="item-info-volume">{{item.amount}} {{item.amount_type}}</h2>
      </div>
    </div>
  </div>
  <div *ngIf="larguraTela >= 993" class="bottom-container">
    <div class="item-container" *ngFor="let item of productsByCategory.slice(0, 6)" (click)="openDialog(item.id)">
      <div class="item-imagem-container">
        <img class="item-imagem" src="{{item.pictures[0]?.url}}" alt="">
      </div>
      <div class="item-info-container">
        <div>
          <h5 *ngIf="item.current_offer?.individual_discount_percentage" class="item-info-discount">de <span class="item-info-old-price">{{item.base_price / 100 | dynamic: 'currency'}}</span> por</h5>
          <h3 class="item-info-valor">{{calculateSubmenuItemsPrice(item.base_price, item.current_offer?.individual_discount_percentage ?? 0) | dynamic: 'currency' }}</h3>
        </div>
        <h2 class="item-info-nome">{{item.name}}</h2>
        <h2 class="item-info-volume">{{item.amount}} {{item.amount_type}}</h2>
      </div>
    </div>
  </div>
</section>
