<div class="w-100 d-flex justify-content-end">
  <mat-icon style="cursor: pointer;" (click)="closeModal()">close</mat-icon>
</div>
<mat-dialog-content >

  <div class="upper-container align-items-start">
    <div class="d-flex flex-column gap-2">
      <div  class="imagem-container" style=" cursor: zoom-in;">
        <lib-ngx-image-zoom *ngIf="larguraTela >= 992" [thumbImage]="product?.pictures[selectedImage]?.url" 
          [fullImage]="product?.pictures[selectedImage]?.url" [enableScrollZoom]="true" [circularLens]="false"
          [magnification]="2">
        </lib-ngx-image-zoom>

        <img *ngIf="larguraTela < 992 " class="mat-elevation-z2" width="100%" height="auto"
        src="{{product?.pictures[selectedImage]?.url}}" alt="Imagem do produto {{product?.display_name}}">
      </div>
      
      <div class="d-flex gap-1">
        <div class="mini-imagem" *ngFor="let image of product.pictures; let index = index">
          <img [src]="image.url" alt="" (click)="selectImage(index)">
        </div>
      </div>
    </div>
    <div class="info-container" style="display: flex; flex-direction: column; justify-content: space-between;">
      <div class="d-flex flex-column gap-3">
        <h1 class="info-titulo">{{product?.display_name}}</h1>
       
        <h4 (click)="openStorePage(current_store?.display_name, current_store_id)" style="cursor: pointer;">
          {{current_store?.display_name}}</h4>
        <div>
          <span *ngIf="product?.is_just_liquid" class="tag">
            <mat-icon class="me-1" svgIcon="retornavel"></mat-icon> Produto Retornável
          </span>
        </div>
        <p class="info-descricao">{{product.description }}</p>
      </div>
      <div>

        <div class="mb-3" >
          <span class="me-1" *ngFor="let item of product?.temperatures?.split(',')">
            <span [ngSwitch]="item">
              <span *ngSwitchCase="'room_temperature'">
                <span [ngClass]="{'tag-selected': selectedTag == item}" (click)="selectedTag = item"  class="tag">Temperatura Ambiente</span>
              </span>
              <span *ngSwitchCase="'chilled'">
                <span [ngClass]="{'tag-selected': selectedTag == item}" (click)="selectedTag = item" class="tag">Gelado</span>
              </span>
            </span>
          </span>
        </div>
        <div style="display: flex; flex-direction: row; justify-content: space-between;">
          
          <div class="info-valor-container display-value-product-detail">
            <p class="mr-2 mb-0 pb-0">
              <span *ngIf="product?.current_offer" class="w-100 mb-1"
                style=" display: block;font-size: 0.8rem; color: #666">de
                <span style="text-decoration: line-through; "> {{ (product?.base_price/100) | currency:"R$" }} </span>
                por
              </span>
              <span *ngIf="product?.current_offer" class="w-100" style="display: block; color: #510c76"> {{ (
                product?.base_price - (product?.base_price *(product?.current_offer?.individual_discount_percentage
                /100) )) / 100 | currency:"R$" }}</span>
              <span *ngIf="!product?.current_offer" class="w-100" style="display: block; color: #510c76"> {{
                (product?.base_price/100) | currency:"R$" }}</span>
            </p>
          </div>
          <div>
            <div class="icons-container">
              <button (click)="shareProduct()">
                <mat-icon inline="true" style="width: 2.5vh; height: 2.5vh; font-size: 1rem;" svgIcon="share"
                  color="primary"></mat-icon>
              </button>
              <button *ngIf="favorite" mat-icon-button (click)="notFavorite()">
                <mat-icon inline="true" style="width: 2.5vh; height: 2.5vh; font-size: 1rem;"
                  svgIcon="heart-full"></mat-icon>
              </button>
              <button *ngIf="!favorite" mat-icon-button (click)="setFavorite()">
                <mat-icon inline="true" style="width: 2.5vh; height: 2.5vh; font-size: 1rem;" svgIcon="heart-empty"
                  color="primary"></mat-icon>
              </button>
            </div>
            <div *ngIf="notifyCopyText" class="notification-copy-link mt-2 position-fixed">
              Link Copiado
            </div>
          </div>
        </div>
       
        
        <div *ngIf="productCounter >= 0" class="w-100 d-flex justify-content-center">
          
          <div class="number-products-container">
            <div class="number-products-counter-container">
              <button class="number-products-increment" (click)="decrementCounter()"> - </button>
              <div class="number-products-hud">{{productCounter}}</div>
              <button class="number-products-decrement" (click)="increaseCounter()" > + </button>
            </div>
            <div
              (click)="addProductToCart(product.id, product.name, total_price.toString(), product.seller_id, product.current_offer ?? null)"
              class="number-products-add-button">
              <p>Adicionar</p>
              <p>{{calculateSubmenuItemsPrice(product.base_price, product.current_offer?.individual_discount_percentage
                ?? 0) * productCounter | dynamic: 'currency'}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--   <div *ngIf="larguraTela <= 545" class="bottom-container">
    <div class="item-container" *ngFor="let item of productsByCategory.slice(0, 6)" (click)="openDialog(item.id)">
      <div class="item-imagem-container">
        <img class="item-imagem" src="{{item.pictures[0]?.url}}" alt="">
      </div>
      <div class="item-info-container">
        <h1 class="item-info-valor">{{calculateSubmenuItemsPrice(item.base_price, item.current_offer?.individual_discount_percentage ?? 0) | dynamic: 'currency'}}</h1>
        <h2 class="item-info-nome">{{item.name}}</h2>
        <h2 class="item-info-volume">{{item.amount}} {{item.amount_type}}</h2>
      </div>
    </div>
  </div>
  <div *ngIf="larguraTela >= 546 && larguraTela <= 992" class="bottom-container">
    <div class="item-container" *ngFor="let item of productsByCategory.slice(0, 3)" (click)="openDialog(item.id)">
      <div class="item-imagem-container">
        <img class="item-imagem" src="{{item.pictures[0]?.url}}" alt="">
      </div>
      <div class="item-info-container">
        <h1 class="item-info-valor">{{calculateSubmenuItemsPrice(item.base_price, item.current_offer?.individual_discount_percentage ?? 0) | dynamic: 'currency'}}</h1>
        <h2 class="item-info-nome">{{item.name}}</h2>
        <h2 class="item-info-volume">{{item.amount}} {{item.amount_type}}</h2>
      </div>
    </div>
  </div> -->

  <h4 *ngIf="larguraTela >= 993">Peça também</h4>
  <div *ngIf="larguraTela >= 993" class="bottom-container ">

    <div class="item-container mx-1" *ngFor="let item of productsByCategory.slice(0, 3)"
      (click)="openDialog(item.id, 'product')" style=" cursor: pointer;">
      <div class="item-imagem-container position-relative">
        <span *ngIf="item?.is_just_liquid" style="background-color: #d8d8d8af; border-radius: 5px;" class="position-absolute top-0 start-0 ms-1 mt-1 p-1 ">
          <mat-icon  svgIcon="retornavel"></mat-icon>
        </span>
        <img class="item-imagem"  src="{{item.pictures[0]?.url}}" alt="">
      </div>
      <div class="item-info-container">

        <p style="min-height: 50px;" class="d-flex flex-column justify-content-end mb-2 pb-0"
          [ngClass]="item?.current_offer ? 'h-auto': 'h-auto'">
          <span *ngIf="item?.current_offer" class="w-100 " style=" display: block;font-size: 0.8rem; color: #666">de
            <span style="text-decoration: line-through; "> {{ (item?.base_price/100) | currency:"R$" }} </span> por
          </span>
          <span *ngIf="item?.current_offer" class="w-100" style="display: block; font-size: 1rem; color: #510c76"> {{ (
            item?.base_price - (item?.base_price *(item?.current_offer?.individual_discount_percentage /100) )) / 100 |
            currency:"R$" }}</span>
          <span *ngIf="!item?.current_offer" class="w-100" style="display: block; font-size: 1rem; color: #510c76"> {{
            (item?.base_price/100) | currency:"R$" }}</span>
        </p>

        <p class="item-info-nome">{{item?.display_name}}</p>

      </div>
    </div>
  </div>
</mat-dialog-content>