import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-credit-card-selector',
  templateUrl: './credit-card-selector.component.html',
  styleUrls: ['./credit-card-selector.component.scss']
})
export class CreditCardSelectorComponent implements OnInit {
  @Input() credit_card: any;
  @Input() selectedCreditCardId: any;
  @Output() emitCreditCard = new EventEmitter();

  selected = false;
  mouseover = false;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.selected = this.credit_card?._id == this.selectedCreditCardId ? true : false;
  }

  selectCreditCard(creditCard: any) {
    this.emitCreditCard.emit(creditCard);
  }
}
