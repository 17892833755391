import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { retry } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

export abstract class CrudService<T = any> {
  abstract endpoint: any;
  url = environment.url_api;
  pageable = 'pageable?';
  simplified = 'idsAndNames?';

  protected constructor(
    protected http: HttpClient,
    protected dialog: MatDialog,
  ) { }


  getUserById(id: string): Observable<any> {
    return this.http.get(`${this.url}${this.endpoint}/${id}`)
  }

  public async get<G>(request: string): Promise<G | null> {

    let response: any = null;
    try {
      response = await this.http
        .get<G>(`${this.url}${this.endpoint}/${request}`)
        .toPromise();

    } catch (error: any) {
      response = this.errorHandler('GET', error);

    }
    return response;
  }

  public async getAll<G>(): Promise<G[] | null> {

    let response: any = null;
    try {
      response = await this.http
        .get<G>(`${this.url}/${this.endpoint}`)
        .toPromise();

    } catch (error: any) {
      response = this.errorHandler('GET', error);

    }
    return response;
  }

  public async getLazy<G>(request: string): Promise<G | null> {

    let response: any = null;
    try {
      response = await this.http
        .get<G>(`${this.url}${this.endpoint}/${request}`)
        .pipe(retry(2))
        .toPromise();

    } catch (error: any) {
      response = this.errorHandler('GET', error);

    }
    return response;
  }

  public async getUrlParam<G>(request: string, rowSize: string, pagePosition: string, filterValueParam: string): Promise<G | null> {

    let response: any = null;
    try {
      response = await this.http
        .get<G>(`${this.url}${this.endpoint}/${request}/${this.pageable}` +
          `size=${rowSize}&page=${pagePosition}`)
        .toPromise();

    } catch (error: any) {
      response = this.errorHandler('GET', error);

    }
    return response;
  }

  public async getListSimplified(): Promise<T[] | null> {
    return this.get<T[]>(this.simplified);
  }

  public async getList(): Promise<T[] | null> {
    return this.get<T[]>('');
  }

  public async getPaginatedList(rows: number | string, page: number | string): Promise<T | null> {
    return this.getLazy<T>('pageable?' + 'page=' + page + '&' + 'size=' + rows);
  }

  public async getPaginatedListFiltered(rows: number | string, page: number | string, filterValue: number | string): Promise<T | null> {
    return this.getLazy<T>('page=' + page + '&' + 'limit=' + rows + '&' + 'search_term=' + filterValue);
  }

  public async getById(id: number | string): Promise<T | null> {
    return this.get<T>('' + id);
  }

  public async post(body: any): Promise<any> {

    let response = null;
    try {
      response = await this.http
        .post(`${this.url}${this.endpoint}`, body)
        .toPromise();

    } catch (error: any) {
      response = this.errorHandler('POST', error);

    }
    return response;
  }

  public async postWithPath(path: string, body: any): Promise<any> {

    let response = null;
    try {
      response = await this.http
        .post(`${this.url}${this.endpoint}/${path}`, body)
        .toPromise();

    } catch (error: any) {
      response = this.errorHandler('POST', error);

    }
    return response;
  }

  public async postWithParams(params: string, body?: string): Promise<T | null> {

    let response: any = null;
    try {
      response = await this.http
        .post(`${this.url}${this.endpoint}?${params}`, body)
        .toPromise();

    } catch (error: any) {
      response = this.errorHandler('POST', error);

    }
    return response;

  }

  public async updateById(body: any): Promise<any> {

    let response = null;
    try {
      response = await this.http
        .put(`${this.url}${this.endpoint}`, body)
        .toPromise();

    } catch (error: any) {
      response = this.errorHandler('PUT', error);

    }
    return response;
  }

  public async update(request: string, body: any): Promise<any> {

    let response = null;
    try {
      response = await this.http
        .put(`${this.url}${this.endpoint}/${request}`, body)
        .toPromise();

    } catch (error: any) {
      response = this.errorHandler('PUT', error);

    }
    return response;
  }

  public async deleteById(id: number | string): Promise<any> {

    let response = null;
    try {
      response = await this.http
        .delete(`${this.url}${this.endpoint}/${id}`)
        .toPromise();

    } catch (error: any) {
      response = this.errorHandler('DELETE', error);

    }
    return response;
  }
  // Deleta por ID e um segundo parametro
  public async deleteByIdAndSec(id: number | string, sec: number | string): Promise<any> {
    let response = null;
    try {
      response = await this.http
        .delete(`${this.url}${this.endpoint}/${id}/${sec}`)
        .toPromise();
    } catch (error: any) {
      response = this.errorHandler('DELETE', error);
    }
    return response;
  }

  public async delete(request: string, id: number | string): Promise<any> {
    let response = null;
    try {
      response = await this.http
        .delete(`${this.url}${this.endpoint}/${request}/${id}`)
        .toPromise();
    } catch (error: any) {
      response = this.errorHandler('DELETE', error);
    }
    return response;
  }

  public errorHandler(
    method: string,
    error: HttpErrorResponse,
  ): Promise<never> {
    console.error(
      `Error occurred during ${method} ${this.url}${this.endpoint}`,
      error,
    );
    return Promise.reject(error);
  }

  private handleObservableError<T>(method: string, result?: T) {
    return (error: any): Observable<T> => {

      console.error(`Error occurred during ${method} ${this.url}${this.endpoint}`, error);

      return of(result as T);
    };
  }
}
