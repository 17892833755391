<!-- <section>
  <div class="faq-container">
    <div class="question-and-answer-container">
      <h1 class="question">{{item.question}}</h1>
      <h3 class="answer">{{item.answer}}</h3>
    </div>
  </div>
</section> -->

<section>
  <h1 class="page-title">Perguntas frequentes</h1>
  <div class="faq-container">
    <mat-accordion>
      <mat-expansion-panel *ngFor="let item of faqList; let index = index">
        <mat-expansion-panel-header class="address-header">
          <mat-panel-title class="address-header-text">
            {{item.question}}
          </mat-panel-title>
        </mat-expansion-panel-header >
        <h3 class="answer">{{item.answer}}</h3>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</section>
