import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_URL = environment.url_api
@Injectable({
  providedIn: 'root'
})
export class OrdersEubeboApiService {

  endpoint = 'orders';

  constructor(private http: HttpClient) { }

  createOrder(body: any): Observable<any> {
    return this.http.post(API_URL + 'orders', body)
  }

  getById(order_id: string): Observable<any> {
    return  this.http.get(API_URL + `orders/${order_id}`)
  }

  getAllByCustomer(customer_id: string, platform_type: string, status?: any): Observable<any> {
    let params =  new HttpParams();

    if(customer_id){
      params = params.append('customer_id', customer_id)
    }

    if (platform_type){
      params = params.append('platform_type', platform_type)
    }

    if(status?.length > 0){
      status.forEach((element: any, i: any) => {
        params = params.append('statuses['+ i+ ']', element );
      });
    }

    params = params.append('limit', '1000');

    return this.http.get(API_URL + 'orders/myOrders/forCustomer', {params})
  }

  cancelOrderById(order_id: string, customer_id: string): Observable<any> {
    const body = {
      customer_id: customer_id
    }

    return this.http.post(API_URL + `orders/${order_id}/cancel`, body)
  }

  getChatByOrderAndSeller(order_id: any, seller_id: any): Promise<any> {
    return this.http.get(`${API_URL}${this.endpoint}/${order_id}/contactSeller/${seller_id}`).toPromise()
  }
}
