import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CartProducts, CartProductsList } from 'src/app/config/models/cart-products.model';
import { CartEubeboService } from 'src/app/services/cart-eubebo.service';
import { SellerEubeboApiService } from 'src/app/services/sellers-eubebo-api.service';
import { CupomDialogComponent } from 'src/app/navigation/cupom-dialog/cupom-dialog.component';
import { CurrentAddressService } from 'src/app/services/current-address.service';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { Products } from 'src/app/config/models/products-cart';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CouponsEubeboApiService } from 'src/app/services/coupons-eubebo-api.service';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { EditItemCartDialogComponent } from 'src/app/navigation/cart-dialog/edit-item-cart-dialog/edit-item-cart-dialog.component';
import { Location } from '@angular/common';
import { InformationComponent } from 'src/app/shared/dialogs/information/information.component';
import { PaymentMethodsEubeboApiService } from 'src/app/services/payment-methods-eubebo-api.service';
import { ExchangeAmountComponent } from './exchange-amount/exchange-amount.component';
import { CepDialogComponent } from 'src/app/navigation/cep-dialog/cep-dialog.component';
import { DynamicPipe } from 'src/app/config/pipe/dynamic-pipes/dynamic.pipe';

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss'],
  providers: [DynamicPipe]
})
export class CheckoutPageComponent implements OnInit {
  @ViewChild('metodoPagamentoGroup') metodoPagamentoGroup: any;

  options: AnimationOptions = {
    path: '/assets/images/shopping-bag-error.json',
  };

  cupomLogo: string = '../../../../assets/images/local_activity.png'
  listProductsInCart: CartProductsList = {
    data: []
  };

  currentStoreId: string = ''
  currentStore: any
  discount_value: number = 0
  shipping_value: number = 0
  cupom: any
  subtotal: number = 0
  total: number = 0

  street: string | null = null
  street_number: string | null = null
  neighborhood: string | null = null
  city: string | null = null
  state: string | null = null

  user_id: string | null = null
  user_info: any
  credit_cards: any[] = []
  installments: number = 1
  selectedCreditCard: any = null;
  addresses: any[] = []
  selectedPayment: any;
  paymentMethods: any[] = [];
  selectedAddress: any;

  isEntrega: boolean = false
  isRetirada: boolean = false

  isCreditCard: boolean = false
  isPix: boolean = false

  coupon: string | null = null
  couponId: string = ''
  coupons: any = [];

  innerWidth: any;

  lat: any;
  long: any;

  payment_on_delivery: any;
  selectedPaymentDelivery: any;

  payment_methods_delivery: any[] = [];

  paymentBankNotes: any = null;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private location: Location,
    private authUserService: AuthUserService,
    private cartEubeboService: CartEubeboService,
    private currentAddressService: CurrentAddressService,
    private sellerEubeboApiService: SellerEubeboApiService,
    private couponsEubeboApiService: CouponsEubeboApiService,
    private customersEubeboApiService: CustomersEubeboApiService,
    private paymentMethodsEubeboApiService: PaymentMethodsEubeboApiService,
    private dynamicPipe: DynamicPipe,
  ) {

  }

  ngOnInit(): void {
    this.lat = this.currentAddressService.getValue(this.currentAddressService.address_lat)
    this.long = this.currentAddressService.getValue(this.currentAddressService.address_long)
    
    this.innerWidth = window.innerWidth

    this.coupon = this.cartEubeboService.getValue(this.cartEubeboService.cupom)
    this.getAllCupom()
    this.getCustomerInfo()
    this.getAddress()
    this.getProductsInCart()
    this.currentStoreId = this.listProductsInCart.data[0].product_seller_id
    this.getSellerById();

    this.getPaymentMethods()
    this.currentAddressService.emitAddress.subscribe({
      next: (res: any) => {
        this.getCustomerInfo()
      }
    })
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.setSpeed(1);
  }

  verifyCoupon(): void {
    for (let i = 0; i < this.coupons.length; i++) {
      if (this.coupon == this.coupons[i].code) {
        this.couponId = this.coupons[i].id
      }
    }
  }

  editProductsFromCart(indexItem: number) {
    let listOfProductsParse =  this.cartEubeboService.getValue(this.cartEubeboService.productsList)
    let numOfProducts = Number(this.cartEubeboService.getValue(this.cartEubeboService.numOfProducts))
    let listOfProducts
    let listOfProductsLessIndex

    if (listOfProductsParse) {
      listOfProducts = JSON.parse(listOfProductsParse)
    }

    if (indexItem > -1) {

      numOfProducts = numOfProducts - Number(listOfProducts.data[indexItem]?.number_of_products)
      listOfProductsLessIndex = listOfProducts.data.filter((item: any, index: number) => index != indexItem)

      const data = {
        indexItem: indexItem,
        product: listOfProducts.data[indexItem],
        listOfProducts: listOfProductsLessIndex,
        numOfProducts: numOfProducts
      }

      this.openModal('edit-item', data)
    }
  }

  removeProductsFromCart(indexItem: number) {
    let listOfProductsParse =  this.cartEubeboService.getValue(this.cartEubeboService.productsList)
    let numOfProducts = Number(this.cartEubeboService.getValue(this.cartEubeboService.numOfProducts))
    let listOfProducts

    if (listOfProductsParse) {
      listOfProducts = JSON.parse(listOfProductsParse)
    }

    if (indexItem > -1) {
      numOfProducts = numOfProducts - Number(listOfProducts.data[indexItem]?.number_of_products)
      listOfProducts.data.splice(indexItem, 1)
    }

    if(listOfProducts.data.length === 0 ){
      this.backToLastPage();
    }

    this.cartEubeboService.setValue(this.cartEubeboService.productsList, JSON.stringify(listOfProducts));
    this.listProductsInCart = listOfProducts
    this.cartEubeboService.setValue(this.cartEubeboService.numOfProducts, numOfProducts);
  }

  getCupom() {
    this.cupom = this.cartEubeboService.getSession(this.cartEubeboService.cupomObject)
  }

  getSubtotal() {
    if (this.subtotal > 0) {
      this.subtotal = 0
    }

    for (let i = 0; i < this.listProductsInCart.data.length; i++) {
      this.subtotal = this.subtotal + (+this.listProductsInCart.data[i].number_of_products * +this.listProductsInCart.data[i].product_base_price)
    }
  }

  calcularShippingFee() {
    if (this.currentStore?.seller_delivery?.shipping_type == 1) {
      this.shipping_value = this.currentStore?.seller_delivery?.shipping_fee
    }
    if (this.currentStore?.seller_delivery?.shipping_type == 2) {
      this.shipping_value = this.currentStore?.seller_delivery?.shipping_fee * this.currentStore?.distance
    }
  }

  somarTotal() {
    this.getCupom()
    this.calcularShippingFee()

    if (this.total > 0) {
      this.total = 0
    }

    if (this.cupom && this.cupom?.type === 'percentage' && this.cupom?.applicable_on === 1) {
      if (this.currentStore?.seller_delivery) {
        this.discount_value = (this.subtotal * this.cupom?.discount / 100)
        this.total = (this.subtotal + this.shipping_value) - this.discount_value
      } else {
        this.discount_value = (this.subtotal * this.cupom?.discount) / 100
        this.total = this.subtotal - this.discount_value
      }
    }
    if (this.cupom && this.cupom?.type === 'percentage' && this.cupom?.applicable_on === 2 && this.cupom?.discount === 100) {
      this.discount_value = this.shipping_value
      this.total = this.subtotal
    }
    if (this.cupom && this.cupom?.type === 'percentage' && this.cupom?.applicable_on === 2 && this.cupom?.discount !== 100) {
      const discountValue = (this.shipping_value * this.cupom?.discount / 100);

      this.discount_value = discountValue;
      this.total = this.subtotal + (this.shipping_value - discountValue);
    }
    if (this.cupom && this.cupom?.type === 'amount' && this.cupom?.applicable_on === 1) {
      this.discount_value = this.cupom?.discount / 100
      this.total = (this.subtotal - this.discount_value) + this.shipping_value
    }
    if (this.cupom && this.cupom?.type === 'amount' && this.cupom?.applicable_on === 2) {
      this.discount_value = this.cupom?.discount / 100
      this.total = this.subtotal + (this.shipping_value - this.discount_value)
    }
    if (!this.cupom) {
      if (typeof this.shipping_value === 'number') {
        this.total = this.subtotal + Number(this.shipping_value)
      } else {
        this.total = this.subtotal
      }
    }
  }

  metodoDeRecebimento(metodo: string): void {
    if (metodo == 'entrega') {
      this.isEntrega = true
      this.isRetirada = false
    } else if (metodo == 'retirada') {
      this.isEntrega = false
      this.isRetirada = true
    }
  }

  openModal(component: string, data?: any): void {
    if (component == 'cupom') {
      const dialogRef = this.dialog.open(CupomDialogComponent, {
        data: {
          subtotal: this.subtotal,
          screen: 'checkout'
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    }
    if (component == 'edit-item') {
      const dialogRef = this.dialog.open(EditItemCartDialogComponent, {
        data: data
      })

      dialogRef.afterClosed().subscribe(result => {
        this.getProductsInCart()
        this.getSubtotal()
        this.somarTotal()
      })
    }
    if (component == 'confirm') {
      let body:any
      const productsCart: Products[] = []

      for (let i = 0; i < this.listProductsInCart.data.length; i++) {
        let product: Products = {
          product_id: this.listProductsInCart.data[i].product_id,
          quantity: this.listProductsInCart.data[i].number_of_products,
          temperature: this.listProductsInCart.data[i]?.temperature
        }

        productsCart.push(product)
      }

      if(this.currentStore?.seller_delivery?.has_minimum_order_amount && (this.currentStore?.seller_delivery?.minimum_order_amount > this.subtotal)){
        this.dialog.open(InformationComponent, {
          panelClass: 'container-add',
          disableClose: true,
          data: {
            error: true,
            message: `O pedido mínimo para essa loja é de ${this.dynamicPipe.currency(this.currentStore?.seller_delivery?.minimum_order_amount) }. não inclusa a taxa de entrega.`
          }
        })
        return;
      }

      if(!this.selectedAddress){
        this.dialog.open(InformationComponent, {
          disableClose: true,
          data: {
            error: true,
            message: 'Selecione um endereço!'
          }
        })
        return 
      }

      console.log("========================= começo ================================")
      console.log(this.selectedPayment);
      console.log(this.selectedPaymentDelivery);
      console.log(this.selectedCreditCard);
      console.log(this.paymentBankNotes);
      console.log("====================== fim =====================================")

      if(!this.selectedPayment){
        this.dialog.open(InformationComponent, {
          disableClose: true,
          data: {
            error: true,
            message: 'selecione o metodo de pagamento!'
          }
        })
        return 
      }

      if(this.selectedPayment.type === 'ON_DELIVERY' && (!this.selectedPaymentDelivery )){
        this.dialog.open(InformationComponent, {
          disableClose: true,
          data: {
            error: true,
            message: 'selecione o método de pagamento!'
          }
        })
        return 
      }

      if(this.selectedPayment.type === 'ON_DELIVERY' && this.selectedPaymentDelivery.payment_method == 'bank_notes' && (!this.paymentBankNotes) ){
        this.dialog.open(InformationComponent, {
          disableClose: true,
          data: {
            error: true,
            message: 'Para pagamentos no dinheiro é preciso informar se precisa de troco!'
          }
        })
        return 
      }

      if(this.selectedPayment.type === 'CREDIT_CARD' && !this.selectedCreditCard ){
        this.dialog.open(InformationComponent, {
          disableClose: true,
          data: {
            error: true,
            message: 'Para pagamentos no cartão de crédito é preciso selecionar um cartão!'
          }
        })
        return 
      }

      
      if(this.selectedPayment.type == 'ON_DELIVERY'){
        body = {
          customer_id: this.user_id,
          products_by_seller: [
            {
              seller_id: this.currentStoreId,
              products: productsCart,
              shipping_fee: this.currentStore.seller_delivery.shipping_fee,
              packages: []
            }
          ],
          payment_data: {
            method: this.selectedPayment.type,
            delivery_payment_method: this.selectedPaymentDelivery.payment_method
          },
          device_type: 1,
          shipping_address_id: this.selectedAddress?._id,
          platform_type: "Delivery"
        }

        if(this.selectedPaymentDelivery.payment_method == 'bank_notes'){
          body.payment_data['has_exchange'] = this.paymentBankNotes['has_exchange']
          body.payment_data['exchange_amount'] = this.paymentBankNotes['exchange_amount']
        }

        if(this.couponId){
          body['coupon_id'] = this.couponId
        }
      } else if ( this.selectedPayment.type == 'PIX') {
        body = {
          customer_id: this.user_id,
          products_by_seller: [
            {
              seller_id: this.currentStoreId,
              products: productsCart,
              shipping_fee: this.currentStore.seller_delivery.shipping_fee,
              packages: []
            }
          ],
          payment_data: {
            method: this.selectedPayment.type
          },
          device_type: 1,
          shipping_address_id: this.selectedAddress?._id,
          platform_type: "Delivery"
        }

        if(this.couponId){
          body['coupon_id'] = this.couponId
        }
        
      } else if (this.selectedPayment.type == 'CREDIT_CARD') {
        body = {
          customer_id: this.user_id,
          products_by_seller: [
            {
              seller_id: this.currentStoreId,
              products: productsCart,
              shipping_fee: this.currentStore.seller_delivery.shipping_fee,
              packages: []
            }
          ],
          payment_data: {
            method: this.selectedPayment.type,
            card: {
              id: this.selectedCreditCard?._id
            },
            installments: 1
          },
          device_type: 1,
          shipping_address_id: this.selectedAddress?._id,
          platform_type: "Delivery"
        }
          
        if(this.couponId){
          body['coupon_id'] = this.couponId
        }
      }

      console.log(body);

      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          body: body,
          seller: this.currentStore,
          customer_address: this.selectedAddress,
          last_four_digits: this.selectedCreditCard?.lastFourDigits,
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    }
  }

  respAddress(address: any){

    this.dialog.open(CepDialogComponent, {
      data: {
       
      }
    }).afterClosed().subscribe({
      next: (resp)=> {
        console.log(resp)
      }
    })

  }

  respCreditCard(creditCard: any){

    if(creditCard && creditCard?._id ){
      this.selectedCreditCard = creditCard
    } else {
      this.selectedCreditCard = null;
    }

  }

  openStorePage(display_name: string, seller_id: string): void {
    if (this.currentStore?.display_name) {
      this.router.navigate([`lojas/${display_name}/${seller_id}`])
    }
  }

  backToLastPage() {
    const lastPage = this.location.back()
  }

  getAllCupom(): void {
    this.couponsEubeboApiService.getAllCoupons().subscribe({
      next: (res: any) => {
        this.coupons = res.items

        this.verifyCoupon()
      },
      error: (err: any) => {}
    })
  }

  getCustomerInfo(): void {
    this.user_id = this.authUserService.getValue(this.authUserService.userId)

    if (this.user_id != null) {
      this.customersEubeboApiService.getUserById(this.user_id).subscribe({
        next: (res: any) => {
          this.user_info = res

          this.addresses = res.addresses
          let address_id = this.currentAddressService.getValue(this.currentAddressService.address_id)

          if(address_id){
            this.selectedAddress = res.addresses.find((item: any) => item._id == address_id);
            console.log(this.selectedAddress);
          }

          this.credit_cards = res.cards
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }

  getAddress(): void {
    this.state = this.currentAddressService.getValue(this.currentAddressService.state)
    this.city = this.currentAddressService.getValue(this.currentAddressService.city)
    this.neighborhood = this.currentAddressService.getValue(this.currentAddressService.neighborhood)
    this.street = this.currentAddressService.getValue(this.currentAddressService.street)
  }

  getProductsInCart() {
    let listProductsInCartParse = this.cartEubeboService.getValue(this.cartEubeboService.productsList);

    if (listProductsInCartParse) {
      this.listProductsInCart = JSON.parse(listProductsInCartParse);
    }

    if(!this.listProductsInCart || this.listProductsInCart?.data?.length === 0){
      this.backToLastPage();
    }

    this.cartEubeboService.setValue(this.cartEubeboService.productsList, JSON.stringify(this.listProductsInCart));
  }

  getSellerById() {
    this.sellerEubeboApiService.getById(this.currentStoreId, this.lat, this.long).subscribe({
      next: (res: any) => {
        console.log(res);
        this.currentStore = res

        this.payment_methods_delivery = this.currentStore?.seller_delivery?.payment_methods.filter((item: any) => item.accepted );

        this.getSubtotal()
        this.somarTotal()
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  isShippingFeeNumber(): boolean {
    return typeof this.currentStore?.seller_delivery?.shipping_fee === 'number';
  }

  navigateTo(page: string): void {
    this.router.navigate([`${page}`])
  }

  getPaymentMethods() {
    this.paymentMethodsEubeboApiService.getAll('Delivery').subscribe({
      next: (res) => {
        this.paymentMethods = res;
        console.log(res);
        this.payment_on_delivery = this.paymentMethods.find(item =>  item?.type === 'ON_DELIVERY');
        console.log(this.payment_on_delivery);
        this.paymentMethods = this.paymentMethods.filter(item => item?.type !== 'DEBIT_CARD' && item?.type !== 'ON_DELIVERY');

      
  
      }
    })
  }

  respPayment(resp: any){
    this.selectedPayment = resp;
    this.selectedPaymentDelivery = null;
    this.selectedCreditCard = null;
   
  
  }

  respPaymentDelivery(resp: any){

    if(resp.payment_method === 'bank_notes'){
      this.dialog.open(ExchangeAmountComponent, {
        disableClose: true,
        data: {
          totalValue: this.total
        },
        width: 'auto'
      }).afterClosed().subscribe(result => {
        
        if(result){
          this.selectedPayment = this.payment_on_delivery;
          this.selectedPaymentDelivery = resp;
          this.selectedCreditCard = null;
          this.paymentBankNotes = {  ...result }
        } else {
          this.selectedPayment = null;
          this.selectedPaymentDelivery = null;
          this.paymentBankNotes = null;
          this.selectedCreditCard = null;
        }
      })
    } else {
      this.selectedPayment = this.payment_on_delivery;
      this.selectedPaymentDelivery = resp;
      this.selectedCreditCard = null;
    }

   
  }

}
