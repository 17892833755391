<section>
  <div class="w-100 d-flex justify-content-end">
    <mat-icon (click)="closeModal()">close</mat-icon>
  </div>
  <div class="text-container">
    <h3>Você só pode adicionar itens de um restaurante ou mercado por vez</h3>
    <p>Deseja esvaziar a sacola e adicionar este item?</p>
  </div>
  <div class="button-container">
    <button (click)="acceptModal()" class="liberar-sacola">Esvaziar sacola e adicionar</button>
    <button (click)="closeModal()" class="cancelar">Cancelar</button>
  </div>
</section>
