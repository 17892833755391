import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SellerEubeboApiService } from 'src/app/services/sellers-eubebo-api.service';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {

  starRating = 0

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RatingComponent>,
    private sellerEubeboApiService: SellerEubeboApiService,
  ) { }

  ngOnInit(): void {
  }

  rate() {

    const body = {
      rate: this.starRating,
      customer_id: this.data.customer_id
    }

    this.sellerEubeboApiService.rate(this.data.seller_id, body).subscribe({
      next: (res: any) => {
      },
      error: (err: any) => {
        console.log(err)
      }
    })

  }

}
