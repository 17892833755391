import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-success-trasition',
  templateUrl: './success-trasition.component.html',
  styleUrls: ['./success-trasition.component.scss']
})
export class SuccessTrasitionComponent implements OnInit {

  email_image = '/assets/images/email-send.png'

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public dialogRef: MatDialogRef<SuccessTrasitionComponent>,
  ) { }

  ngOnInit(): void {
  }

  
  redirectToOrders() {
    this.router.navigate(['/pedidos'])
    this.dialogRef.close()
  }
}
