import { Component, OnInit, ViewChild } from '@angular/core';

import { AuthUserService } from 'src/app/services/auth-user.service';
import { CurrentAddressService } from 'src/app/services/current-address.service';
import { BannersEubeboApiService } from 'src/app/services/banners-eubebo-api.service';
import { SellerEubeboApiService } from 'src/app/services/sellers-eubebo-api.service';
import { ProductsEubeboApiService } from 'src/app/services/products-eubebo-api.service';
import { OffersEubeboApiService } from 'src/app/services/offers-eubebo-api.service';
import { CategoriesEubeboApiService } from 'src/app/services/categories-eubebo-api.service';
import { CarouselComponent } from 'ngx-bootstrap/carousel';
import { MatDialog } from '@angular/material/dialog';
import { ProdutoPageComponent } from '../produto-page/produto-page.component';
import { Router } from '@angular/router';
import { CepDialogComponent } from 'src/app/navigation/cep-dialog/cep-dialog.component';
import { MetaSeoService } from 'src/app/services/meta-seo.service';
import { EventEmitService } from 'src/app/services/event-emit.service';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';

@Component({
  selector: 'app-delivery-page',
  templateUrl: './delivery-page.component.html',
  styleUrls: ['./delivery-page.component.scss']
})
export class DeliveryPageComponent implements OnInit {

  @ViewChild(CarouselComponent) carousel!: CarouselComponent;
  larguraTela: number = window.innerWidth;

  user_id: string | null = null
  user_name: string | null = null
  user_zipcode: string | null = null

  allBanners: any = []
  allCategories: any = []
  allSellers: any = []
  allEntregaRapidaSellers: any = []
  allLojasProximasSellers: any = []
  allOffers: any = []
  allOffersFiltered: any = []
  allOffersList: any = []
  allProducts: any = []
  allProductsWithImage: any = []

  entregaRapidaPage: number = 0
  entregaRapidaLimit: number = 3

  lojasProximasPage: number = 0
  lojasProximasLimit: number = 6

  latitude: string | null = null
  longitude: string | null = null

  pagamentoBandeiras = "../../../assets/images/payments.png"

  responsiveOptions = [
    {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1,
    },
    {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1
    }
];
  allProductsMoreSale: any = [];
  allProductsFeeFree: any = [];
  favorites: any[] = [];


  constructor(
    public dialog: MatDialog,
    private router: Router,
    private authUserService: AuthUserService,
    private eventEmitService: EventEmitService,
    private currentAddressService: CurrentAddressService,
    private bannersEubeboApiService: BannersEubeboApiService,
    private productsEubeboApiService: ProductsEubeboApiService,
    private categoriesEubeboApiService: CategoriesEubeboApiService,
    private sellerEubeboApiService: SellerEubeboApiService,
    private offersEubeboApiService: OffersEubeboApiService,
    private customersEubeboApiService: CustomersEubeboApiService,
    private metaSeoService: MetaSeoService,
    ) { }

  ngOnInit(): void {
    this.setTitleInMetas('eubebo - Delivery')

    this.user_id = this.authUserService.getValue(this.authUserService.userId)
    this.user_name = this.authUserService.getValue(this.authUserService.userName)
    this.user_zipcode = this.currentAddressService.getValue(this.currentAddressService.zipcode)
    this.latitude = this.currentAddressService.getValue(this.currentAddressService.address_lat)
    this.longitude = this.currentAddressService.getValue(this.currentAddressService.address_long)

    this.eventEmitService.capture('hasAddress')?.subscribe({
      next: (res: any) => {
        if (res == true) {
          this.latitude = this.currentAddressService.getValue(this.currentAddressService.address_lat)
          this.longitude = this.currentAddressService.getValue(this.currentAddressService.address_long)

          this.getAllBanners();
          this.getAllCategories();
          this.getAllOffers();
          this.getEntregaRapida();
          this.getAllLojasProximas();
          this.getAllProducts();
          this.getAllProductsMoreSale();
          this.getAllProductsFeeFree();
          this.getUser();
        }
      },
      error: (err: any) => {
        console.log(err)
      }
    })

    if (this.latitude == null && this.longitude == null) {
      const dialogRef = this.dialog.open(CepDialogComponent, {
        disableClose: true,
        data: {
          zipcode: this.user_zipcode,
          user_name: this.user_name,
          deactivatable: false,
        }
      })

      dialogRef.afterClosed().subscribe(result => {

        this.latitude = this.currentAddressService.getValue(this.currentAddressService.address_lat)
        this.longitude = this.currentAddressService.getValue(this.currentAddressService.address_long)

        if (this.latitude != null && this.longitude != null) {

          this.getAllBanners();
          this.getAllCategories();
          this.getAllOffers();
          this.getEntregaRapida();
          this.getAllLojasProximas();
          this.getAllProducts();
          this.getAllProductsMoreSale();
          this.getAllProductsFeeFree();
          this.getUser();
        }
      })
    }

    if (this.user_zipcode != null) {

      this.getAllBanners();
      this.getAllCategories();
      this.getAllOffers();
      this.getEntregaRapida();
      this.getAllLojasProximas();
      this.getAllProducts();
      this.getAllProductsMoreSale();
      this.getAllProductsFeeFree();
      this.getUser();
    }
  }

  isFavorited(item: any){
  
    if(this.favorites && this.favorites.length != 0){
      return this.favorites.some((elem)=> item.id == elem.product_id);
    }
    return false;

  }

  togleFavority(item: any, event: any){

    event.stopPropagation();
    if(this.user_id != null){

      let favorited: any = this.favorites.find((elem)=> item.id == elem.product_id);
      if(!favorited){
        this.customersEubeboApiService.registerNewFavoriteProduct(this.user_id, {
          product_id: item.id
        }).subscribe({
          next: (res: any) => {
            this.getUser();
          },
          error: (err: any) => {
            console.log(err)
          }
        })
      } else {
        this.customersEubeboApiService.deleteFavoriteProduct(this.user_id, favorited._id).subscribe({
          next: (res: any) => {
            this.getUser();
          },
          error: (err: any) => {
            console.log(err)
          }
        })
      }
     
     
    } 
  }

  setTitleInMetas(newTitle: any) {
    this.metaSeoService.updateTitle(newTitle);
  }

  getAllBanners() {
    this.bannersEubeboApiService.getAllBanners().subscribe((res: any) => {
      this.allBanners = res
    })
  }

  getAllCategories() {
    this.categoriesEubeboApiService.getAll().subscribe({
      next: (res: any) => {
        this.allCategories = res
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getEntregaRapida() {
    this.sellerEubeboApiService.getBySearch(this.entregaRapidaPage, this.entregaRapidaLimit, this.latitude, this.longitude, true, true, 'Delivery', 2).subscribe({
      next: (res: any) => {
        this.allEntregaRapidaSellers = res.items
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getAllLojasProximas() {
    this.sellerEubeboApiService.getBySearch(this.lojasProximasPage, this.lojasProximasLimit, this.latitude, this.longitude, true, null, 'Delivery', 2).subscribe({
      next: (res: any) => {
        this.allLojasProximasSellers = res.items
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getAllOffers() {
    this.offersEubeboApiService.getAll().subscribe((res: any) => {
      this.allOffers = res.items.filter((item: any) => {
        return item.offer_products.length > 0;
      });

      this.allOffersList = this.allOffers.reduce((acc: any, offer: any) => acc.concat(offer.offer_products), []);
    });
  }

  getAllProducts() {
    this.productsEubeboApiService.getAllProducts(1, 999, this.latitude, this.longitude, null, null, null, null, null, null, null, null, 'Delivery').subscribe({
      next: (res: any) => {
        this.allProducts = res.items

        this.allProductsWithImage = res.items.filter((resp: any) => resp.pictures[0]?.url != null || resp.pictures[0]?.url != undefined)
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getAllProductsMoreSale() {
    this.productsEubeboApiService.getAllProducts(1, 15, this.latitude, this.longitude, null, null, null, null, null, null, null, null, 'Delivery', '1').subscribe({
      next: (res: any) => {
        this.allProductsMoreSale = res.items
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }
  
  getAllProductsFeeFree() {
    this.productsEubeboApiService.getAllProducts(1, 15, this.latitude, this.longitude, null, null, null, null, null, null, null, null, 'Delivery', null, '1').subscribe({
      next: (res: any) => {
        this.allProductsFeeFree = res.items
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }
  
  openDialog(item_id: string) {

    const dialogRef = this.dialog.open(ProdutoPageComponent, {
      panelClass: 'container-product',
      
      data: {
        item_id: item_id
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })
  }

  openBannerUrl(url: string) {
    location.href = url
  }

  openExpressDelivery(): void {
    this.router.navigate([`busca`], {queryParams: {is_express: true}})
  }

  openStorePage(slug: string, id: string): void {
    console.log(slug);
    this.router.navigate([`lojas/${slug}/${id}`])
  }

  searchFor(slug: string): void {
    this.router.navigate([`busca`], {queryParams: {search: slug}})
  }

  getUser(){
    if (this.user_id != null) {
      this.customersEubeboApiService.getUserById(this.user_id).subscribe({
        next: (res: any) => {
          this.favorites = res.favorite_products
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }
}
