import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthUserService } from 'src/app/services/auth-user.service';

@Component({
  selector: 'app-venda-conosco-page',
  templateUrl: './venda-conosco-page.component.html',
  styleUrls: ['./venda-conosco-page.component.scss']
})
export class VendaConoscoPageComponent implements OnInit {

  user_id: string | null = null

  constructor(
    private router: Router,
    private authUserService: AuthUserService
  ) { }

  ngOnInit(): void {
    this.user_id = this.authUserService.getValue(this.authUserService.userId);

    if (this.user_id == null) {
      this.router.navigate(['/'])
    }
  }
}
