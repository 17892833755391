import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ResultRequest } from 'src/app/shared/dialogs/information/_models/ResultRequest';

@Component({
  selector: 'app-exchange-amount',
  templateUrl: './exchange-amount.component.html',
  styleUrls: ['./exchange-amount.component.scss']
})
export class ExchangeAmountComponent implements OnInit {


  form!: FormGroup;
  totalValue!: any;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private exchangeAmount: MatDialogRef<ExchangeAmountComponent>,
    private router: Router,
    private fb:FormBuilder
  ) {
    this.totalValue = data.totalValue;
    this.form = this.fb.group({
      has_exchange: [true],
      exchange_amount: ['', [Validators.required, Validators.min(data.totalValue)] ]
    })

    this.form.get('has_exchange')?.valueChanges.subscribe({
      next: (resp) => {
        console.log(resp);
        if(resp){
          this.form.controls['exchange_amount'].addValidators([Validators.required])
        } else {
          this.form.controls['exchange_amount'].clearValidators();
        }

        this.form.controls['exchange_amount'].updateValueAndValidity();
      },
      error: (error) => {
        console.log(error);
      }
    })
  }


  ngOnInit(): void {
  }

  close() {
    this.exchangeAmount.close();
   
  }

  save(){
    if(this.form.invalid){
      this.form.markAllAsTouched();
      return 
    }
    this.exchangeAmount.close(this.form.value);
  }

}
