<section>
  <div class="w-100">
    <div (click)="backToLastPage()" class="return-button">
      <mat-icon>arrow_back</mat-icon>
      <p>Voltar</p>
    </div>
  </div>
  <div class="body">
    <div class="left-container"  style="min-width: 50%;">

      <h3>Finalize seu pedido</h3>
      <div style="width: 100%;">
        <mat-tab-group mat-align-tabs="start">
          <mat-tab (click)="metodoDeRecebimento('entrega')" label="Entrega">

            <div class="d-flex flex-column justify-content-center align-items-start gap-3 mt-3 w-100 py-2 pe-2">
              <div class="d-flex w-100">
                <app-card-address-selected [selectedAddressId]="selectedAddress?._id" [address]="selectedAddress" (emitAddress)="respAddress($event)" class="card w-100 p-0 m-0"></app-card-address-selected>
              </div>
            </div>

          
          </mat-tab>
          <!-- <mat-tab (click)="metodoDeRecebimento('retirada')" label="Retirada">

            <div style="display: flex; flex-direction: row; gap: 3vh;">
              <mat-icon style="font-size: 5vh; transform: translate(0, 50%)">location_on</mat-icon>
              <div>
                <h4>Endereço</h4>
                <p>{{currentStore?.address.street}}, {{currentStore?.address.street_number}} - {{currentStore?.address.neighborhood}}</p>
                <p>{{currentStore?.address.city}} - {{currentStore?.address.state}}</p>
              </div>
            </div>

          </mat-tab> -->
        </mat-tab-group>
      </div>

      <!-- <div class="division-bar"></div> -->
      <mat-divider class="division-bar"></mat-divider>

      <div style="width: 100%;">
        <mat-radio-group>
          <mat-tab-group mat-align-tabs="start" >
            <mat-tab *ngIf="currentStore?.seller_delivery?.has_payment_online" label="Pague pelo site">

              <div class="row d-flex align-items-center justify-content-start mt-4 p-1">
                <app-card-payment-selected class="col-xl-3 col-lg-3 col-md-4" *ngFor="let payment of paymentMethods" [selectedPaymentId]="selectedPayment?.id" [payment]="payment" (emitPayment)="respPayment($event)"></app-card-payment-selected>
              </div>
              
              <div *ngIf="selectedPayment?.type ==='CREDIT_CARD'" class="row d-flex align-items-center justify-content-start  p-1">
                <h5  [ngClass]="{'invalid': !selectedCreditCard}" >Selecione um cartão </h5>

                <div class="d-flex flex-wrap justify-content-between my-2" >
                  <div *ngFor="let card of credit_cards" class="col-12 col-sm-6 col-md-4 mb-3">
                    <app-credit-card-selector [selectedCreditCardId]="selectedCreditCard?._id" [credit_card]="card" (emitCreditCard)="respCreditCard($event)" class="card p-0 m-0 credit-card"></app-credit-card-selector>
                  </div>
                </div>
              </div>
             
            </mat-tab>
            <mat-tab *ngIf="payment_on_delivery && currentStore?.seller_delivery?.has_payment_delivery" label="Pague na entrega">
         
              <div class="row d-flex align-items-center justify-content-start mt-4 p-1">
                <app-card-payment-selected class="col-xl-3 col-lg-3 col-md-4" *ngFor="let payment of payment_methods_delivery" [selectedPaymentId]="selectedPaymentDelivery?.id" [payment]="payment" (emitPayment)="respPaymentDelivery($event)"></app-card-payment-selected>
              </div>

              <div *ngIf="selectedPaymentDelivery?.payment_method ==='bank_notes'" class="row d-flex align-items-center justify-content-start  p-1">
                
                <p *ngIf="paymentBankNotes?.has_exchange">Precisa de troco para {{ paymentBankNotes?.exchange_amount | dynamic: 'currency'}}</p>
                <p *ngIf="!paymentBankNotes?.has_exchange">Não precisa de Troco</p>
              </div>
            
            </mat-tab>
          </mat-tab-group>
        </mat-radio-group>
      </div>
    </div>
    <div class="right-container" style="min-width: 50%;">

      <div class="">
        <div class=" right-container-content">
          <div *ngIf="currentStore?.display_name" class="first-container row" >
            <div class="vendedor-info col-8">
              <h1 class="vendedor-titulo" >Seu pedido em</h1>
              <h5 class="vendedor-nome" >{{currentStore?.display_name}}</h5>
            </div>
            
            <button *ngIf="currentStore?.display_name" class="vendedor-cardapio col-4 py-2 " (click)="openStorePage(currentStore?.display_name, currentStoreId)">Ver cardápio</button>
            
          </div>
          <div class="empty-cart-div" *ngIf="!currentStore?.display_name">
            <div class="empty-cart-div-content">
              <ng-lottie width="35vh" height="35vh" [options]="options" (animationCreated)="animationCreated($event)"></ng-lottie>
              <h1 class="empty-cart-text" >Adicione produtos no carrinho</h1>
            </div>
          </div>
          <div class="second-container" *ngIf="listProductsInCart.data.length != 0">
            <div *ngFor="let item of listProductsInCart.data; let index = index">
              <p>{{item.number_of_products}}x {{item.product_name}}</p>
              <div class="second-bottom-container">
                <div class="second-button-container">
                  <button class="second-button ps-0 me-2" (click)="editProductsFromCart(index)">Editar</button>
                  <button class="second-button ps-0 me-2" (click)="removeProductsFromCart(index)">Remover</button>
                  <span *ngIf="item?.is_just_liquid" style="background-color: #d8d8d8af; border-radius: 5px;"
                    class=" ms-1 mb-1 p-1 ">
                    <mat-icon svgIcon="retornavel"></mat-icon>
                  </span>

                  <span [ngSwitch]="item?.temperature">
                    <span *ngSwitchCase="'room_temperature'">
                      <span class="tag mx-1 mb-1">Temperatura Ambiente</span>
                    </span>
                    <span *ngSwitchCase="'chilled'">
                      <span class="tag mx-1 mb-1">Gelado</span>
                    </span>
                  </span>
                </div>
                <p class="second-price" >{{+item.product_base_price * +item.number_of_products | dynamic: 'currency'}}</p>
              </div>
            </div>
          </div>
  
          <div class="third-container" (click)="openModal('cupom')" *ngIf="listProductsInCart.data.length > 0">
            <div class="cupom-upper-container" >
              <div class="cupom-image-container" >
                <img class="cupom-image" [src]="cupomLogo" alt="">
              </div>
              <div class="cupom-container" >
                <h1 class="cupom-title">Cupom</h1>
                <h2 class="cupom-text">{{cupom != null ? cupom.code : 'Código do cupom'}}</h2>
              </div>
            </div>
            <div class="cupom-button-container">
              <mat-icon class="cupom-button">arrow_forward_ios</mat-icon>
            </div>
          </div>
          <div class="fourth-container" *ngIf="listProductsInCart.data.length > 0">
            <textarea class="checkout-text" placeholder="Observações ..."></textarea>
            <div class="checkout-info-container">
              <div class="checkout-info-left">
                <p>Subtotal</p>
                <p>Taxa de entrega</p>
                <p *ngIf="cupom">Desconto</p>
                <p>Total</p>
              </div>
              <div class="checkout-info-right">
                <p>{{subtotal | dynamic: 'currency'}}</p>
                <p *ngIf="isShippingFeeNumber(); else notANumber">{{this.shipping_value | dynamic: 'currency'}}</p>
                <ng-template #notANumber>
                <p>{{ 0 | dynamic: 'currency' }}</p>
                </ng-template>
                <p *ngIf="cupom">- {{discount_value | dynamic: 'currency'}}</p>
                <p>{{total | dynamic: 'currency'}}</p>
              </div>
            </div>
          </div>
          <div class="fourth-container">
            <small *ngIf="currentStore?.seller_delivery?.has_minimum_order_amount && (currentStore?.seller_delivery?.minimum_order_amount > subtotal)" >
              O pedido mínimo para essa loja é de <span style="color: red;"> {{currentStore?.seller_delivery?.minimum_order_amount | currency: "R$"}}</span>, não inclusa a taxa de entrega.
            </small>
          </div>
         
        </div>
       
        <button *ngIf="innerWidth >= 993" class="confirm-button mt-3" 
           (click)="openModal('confirm')">Continuar</button>
        
      </div>

    </div>
  </div>
  <button *ngIf="innerWidth <= 992" class="confirm-button mt-2" 
    (click)="openModal('confirm')">Continuar</button>
</section>
