import { CardPaymentSelectedComponent } from './card-payment-selected.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';



@NgModule({
  declarations: [
    CardPaymentSelectedComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatDividerModule,
  ],
  exports: [
    CardPaymentSelectedComponent
  ]
})
export class CardPaymentSelectedModule { }
