
<div mat-dialog-title>
  <div class="w-100 d-flex justify-content-end">
    <mat-icon style="cursor: pointer;" (click)="close()">close</mat-icon>
  </div>
</div>
<mat-dialog-content>
  <div *ngIf="data.type != 'phone-alert'">
    
    <p>Ops! Seu login e sua senha não conferem.</p>
  </div>
  <div style="display: flex; flex-direction: column; align-items: center; gap: 1.5vh; max-width: 30vw;" *ngIf="data.type == 'phone-alert'">
    <h4 style="text-align: center;">{{data.error}}</h4>
    <button (click)="redirectTo('profile')">Redirecionar para perfil do usuário</button>
  </div>
</mat-dialog-content>
