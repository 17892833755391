import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartRateComponent } from './start-rate.component';



@NgModule({
  declarations: [StartRateComponent],
  imports: [
    CommonModule,
    MatIconModule
  ],
  exports: [
    StartRateComponent
  ]
})
export class StartRateModule { }
