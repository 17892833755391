import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer/footer.component';
import { InformationComponent } from './dialogs/information/information.component';
import { NothingFoundScreenComponent } from './nothing-found-screen/nothing-found-screen.component';

// Modulos Angular
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { LottieModule } from 'ngx-lottie';

@NgModule({
    declarations: [
        FooterComponent,
        NothingFoundScreenComponent,
        InformationComponent,
    ],
    exports: [
        FooterComponent,
        NothingFoundScreenComponent,
    ],
    imports: [
        ReactiveFormsModule,
        CommonModule,
        RouterModule,
        MatDialogModule,
        LottieModule
    ]
})
export class SharedModule { }
