<section>
  <div class="close-button-container">
    <mat-icon style="cursor: pointer;" (click)="closeModal()">
      close
    </mat-icon>
  </div>
  <div class="menu-item-container">
    <div (click)="cancelOrder()" class="menu-item">
      <h3>Cancelar pedido</h3>
      <mat-icon>arrow_forward_ios</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <div (click)="openRatingPage(data.customer_id)" class="menu-item">
      <h3>Avaliar a loja</h3>
      <mat-icon>arrow_forward_ios</mat-icon>
    </div>
    <mat-divider></mat-divider>
  </div>
</section>
