import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const API_URL = environment.url_api

@Injectable({
  providedIn: 'root'
})
export class CustomersEubeboApiService {

  constructor(private http: HttpClient) { }

  registerUser(body: any): Observable<any> {
    return this.http.post(`${API_URL}customers/`, body)
  }

  loginUser(body: any): Observable<any> {
    return this.http.post(`${API_URL}customers/auth`, body)
  }

  updateUser(id: string, body: any): Observable<any> {
    return this.http.put(`${API_URL}customers/${id}`, body)
  }

  getUserById(customer_id: string): Observable<any> {
    return this.http.get(`${API_URL}customers/${customer_id}`)
  }

  registerNewAddress(customer_id: string, body: any): Observable<any> {
    return this.http.post(`${API_URL}customers/${customer_id}/addresses`, body)
  }

  updateAddress(customer_id: string, address_id: string, body: any): Observable<any> {
    return this.http.put(`${API_URL}customers/${customer_id}/addresses/${address_id}`, body)
  }

  deleteAddress(customer_id: string, address_id: string) {
    return this.http.delete(`${API_URL}customers/${customer_id}/addresses/${address_id}`)
  }

  registerNewFavoriteProduct(customer_id: string, body: any): Observable<any> {
    return this.http.post(`${API_URL}customers/${customer_id}/favorites`, body)
  }

  deleteFavoriteProduct(customer_id: string, fav_product_id: string): Observable<any> {
    return this.http.delete(`${API_URL}customers/${customer_id}/favorites/${fav_product_id}`)
  }

  registerNewCreditCard(customer_id: string, body: any):Observable<any> {
    return this.http.post(`${API_URL}customers/${customer_id}/cards`, body)
  }

  deleteCreditCard(customer_id: string, cc_id: string) {
    return this.http.delete(`${API_URL}customers/${customer_id}/cards/${cc_id}`)
  }
}
