<section>
  <div class="first-container">
    <h2>Obrigado por comprar com a gente!</h2>
    <h4>Seu pedido foi concluído com sucesso.</h4>
  </div>
  <div class="second-container">
    <img src="{{email_image}}" alt="">
    <h3>Um e-mail incluíndo os detalhes de seu pedido foi enviado para o endereço de e-mail fornecido. Por favor, guarde para seus registros. Número do pedido: {{this.data.res.order_number}}</h3>
  </div>
  <h3>Você pode visitar a página de <strong>Meus Pedidos</strong> a qualquer momento para verificar o status</h3>
  <button (click)="redirectToOrders()">Ver pedido</button>
</section>
