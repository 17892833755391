import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';
import { CepDialogComponent } from '../cep-dialog.component';
import { SellerEubeboApiService } from 'src/app/services/sellers-eubebo-api.service';
import { CurrentAddressService } from 'src/app/services/current-address.service';
import { CartEubeboService } from 'src/app/services/cart-eubebo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-address',
  templateUrl: './confirm-address.component.html',
  styleUrls: ['./confirm-address.component.scss']
})
export class ConfirmAddressComponent implements OnInit {

  latitude: any
  longitude: any
  currentStoreId: any ;
  number_of_products: any;
  outside_radius: boolean = false;
  seller: any = null;
  loading: boolean = true;
  

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ConfirmAddressComponent>,
    private sellerEubeboApiService: SellerEubeboApiService,
    private router: Router,
    private cartEubeboService: CartEubeboService
    ) {
      this.latitude = this.data.address.address.location.coordinates[0];
      this.longitude = this.data.address.address.location.coordinates[1];

      let listOfProductsParse =  this.cartEubeboService.getValue(this.cartEubeboService.productsList)
      let numOfProducts = Number(this.cartEubeboService.getValue(this.cartEubeboService.numOfProducts))
      let listOfProducts

      if (listOfProductsParse) {
        listOfProducts = JSON.parse(listOfProductsParse)
      }
      console.log(listOfProducts)
      if(listOfProducts && listOfProducts?.data && listOfProducts.data.length > 0){
        this.currentStoreId = listOfProducts.data[0].product_seller_id
        if(this.currentStoreId && numOfProducts > 0){
          this.getSellerById()
        } else {
          this.loading = false;
        }
      } else {
        this.loading = false;
      }
      
    }

  ngOnInit(): void {
  }

  editAddress(address_id: string) {
    this.dialogRef.close(false)
    const dialogRef = this.dialog.open(CepDialogComponent, {
      autoFocus: false,
      data: {
        type: 'edit-address',
        user_id: this.data.user_id,
        address_id: address_id
      }
    })

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(result)
    })
  }

  close(status: boolean) {

    
    if(status && this.outside_radius){
      this.cartEubeboService.removeAllValues();
      this.router.navigate(['/'])
    }
    this.dialogRef.close(status);
  }

  getSellerById() {
    this.sellerEubeboApiService.getById(this.currentStoreId, this.latitude, this.longitude).subscribe({
      next: (res: any) => {
        console.log(res);
        this.seller = res;
        if(res?.distance > res?.seller_delivery?.radius){
          this.outside_radius = true;
        }

        this.loading = false;
        
      },
      error: (err: any) => {
        console.log(err)
        this.loading = false;
      }
    })
  }
}
