import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-store-infos-dialog',
  templateUrl: './store-infos-dialog.component.html',
  styleUrls: ['./store-infos-dialog.component.scss']
})
export class StoreInfosDialogComponent implements OnInit {
  seller: any
  openingHoursMonday: any[] = []
  openingHoursTuesday: any[] = []
  openingHoursWednesday: any[] = []
  openingHoursThursday: any[] = []
  openingHoursFriday: any[] = []
  openingHoursSaturday: any[] = []
  openingHoursSunday: any[] = []

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    this.seller = this.data.seller
    this.openingHoursMonday = this.data.monday
    this.openingHoursTuesday = this.data.tuesday
    this.openingHoursWednesday = this.data.wednesday
    this.openingHoursThursday = this.data.thursday
    this.openingHoursFriday = this.data.friday
    this.openingHoursSaturday = this.data.saturday
    this.openingHoursSunday = this.data.sunday
  }

}
