<mat-sidenav-container class="sidenav-container example-sidenav-container mb-2" *ngIf="innerWidth <= 992" [hasBackdrop]="true">
  <mat-sidenav class="custom-sidenav" #sidenav mode="over">
    <div class="d-flex justify-content-between align-items-center p-2">
      <h1 *ngIf="user_name">Olá {{user_name}}</h1>
      <h1 *ngIf="!user_name">Faça o login!</h1>
      <mat-icon (click)="sidenav.toggle()">menu_open</mat-icon>
    </div>
    <mat-divider></mat-divider>
    <div *ngIf="user_name">
      <div><a class="burguer-menu" routerLink="pedidos"><mat-icon>receipt_long</mat-icon>Pedidos</a></div>
      <div><a class="burguer-menu" routerLink="metedos-pagamento"><mat-icon>account_balance_wallet</mat-icon>Métodos de pagamento</a></div>
      <div><a class="burguer-menu" (click)="openDialog('enderecos')"><mat-icon>map</mat-icon>Endereços</a></div>
      <div><a class="burguer-menu" routerLink="favoritos"><mat-icon>favorite</mat-icon>Favoritos</a></div>
      <div><a class="burguer-menu" routerLink="editar-perfil"><mat-icon>settings</mat-icon>Editar perfil</a></div>
      <div><a class="burguer-menu" href="#" (click)="logoutUser()"><mat-icon>logout</mat-icon>Fazer logout</a></div>
    </div>
    <mat-divider></mat-divider>
    <div>
      <div *ngIf="!user_name"><a class="burguer-menu" (click)="openDialog('login')"><mat-icon>login</mat-icon>Login</a></div>
      <div *ngIf="!user_name"><a class="burguer-menu" href="https://teste.eubebo.com.br/autenticacao/cadastrar"><mat-icon>how_to_reg</mat-icon>Cadastrar-se</a></div>
      <div><a class="burguer-menu" href="https://teste.eubebo.com.br/venda-conosco"><mat-icon>storefront</mat-icon>Venda com a gente</a></div>
      <div><a class="burguer-menu" routerLink="atendimento-ao-cliente"><mat-icon>contact_mail</mat-icon>Atendimento ao cliente</a></div>
      <div><a class="burguer-menu" routerLink="faq"><mat-icon>quiz</mat-icon>FAQ</a></div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <nav *ngIf="innerWidth <= 575">
      <div class="icons-and-logo-tela-pequena">
        <img [src]="eubeboLogoBranca" (click)="redirecionaMenu()" class="logo-tela-pequena">
        <div class="icons-tela-pequena">
          <mat-icon *ngIf="this.zipcode == null" class="icon-location-tela-pequena" (click)="openDialog('cep')">location_on</mat-icon>
          <div *ngIf="this.user_name == null" class="btn-group icon-login-container-tela-grande">
            <button type="button" (click)="sidenav.toggle()" class="icon-login-container">
                    <mat-icon class="icon-login">account_circle</mat-icon>
            </button>
          </div>
          <div *ngIf="this.user_name != null" class="btn-group" dropdown>
            <button type="button" (click)="sidenav.toggle()" class="icon-login-container">
                    <mat-icon class="icon-login">account_circle</mat-icon>
            </button>
          </div>
          <mat-icon class="icon-shopping-tela-pequena" [matBadge]="number_of_products > 0 ? number_of_products : null" matBadgeColor="warn" (click)="openDialog('carrinho')" >shopping_cart</mat-icon>
        </div>
      </div>

      <form [formGroup]="searchBar" *ngIf="innerWidth <= 576">
        <div class="search-button-tela-pequena" (click)="onSearch()">
          <mat-icon class="search-icon-tela-pequena">search</mat-icon>
        </div>
        <!-- <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of options" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete> -->
        <input type="text"
          matInput
          placeholder="Buscar"
          formControlName = "busca"
          (keyup.enter)="onSearch()"
          >
      </form>
    </nav>
    <nav *ngIf="innerWidth >= 576 && innerWidth <= 992">
        <!--Tela media-->
      <img [src]="eubeboLogo" (click)="redirecionaMenu()" class="logo-tela-media" >

      <div class="icons-and-searchbar-tela-media">
        <form [formGroup]="searchBar">
          <div class="search-button-tela-media" (click)="onSearch()">
            <mat-icon class="search-icon-tela-media">search</mat-icon>
          </div>
         
          <input type="text"
            matInput
            placeholder="Buscar"
            formControlName = "busca"
            (keyup.enter)="onSearch()"
            >
        </form>
        <div class="icon-home-container-tela-media">
          <mat-icon *ngIf="this.zipcode == null" (click)="openDialog('cep')" class="icon-home-tela-media">map</mat-icon>
        </div>
        <div *ngIf="this.user_name == null" class="btn-group icon-login-container-tela-grande">
          <button (click)="sidenav.toggle()" type="button" class="icon-login-container">
                  <mat-icon class="icon-login">account_circle</mat-icon>
          </button>
        </div>
        <div *ngIf="this.user_name != null" class="btn-group">
          <button (click)="sidenav.toggle()" type="button" class="icon-login-container">
                  <mat-icon class="icon-login">account_circle</mat-icon>
          </button>
        </div>
        <mat-icon class="icon-shopping-cart-tela-media" [matBadge]="number_of_products > 0 ? number_of_products : null" matBadgeColor="warn" (click)="openDialog('carrinho')">shopping_cart</mat-icon>
      </div>
    </nav>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>

<div  *ngIf="innerWidth >= 993">
  <nav style="width: innerWidth;">

    <!--Tela grande-->
    <div class="icons-and-searchbar-tela-grande" *ngIf="innerWidth >= 993">
      <img [src]="eubeboLogo" (click)="redirecionaMenu()" class="logo-tela-grande" *ngIf="innerWidth >= 993">

      <form [formGroup]="searchBar">
        <div class="search-button-tela-grande" (click)="onSearch()">
          <mat-icon class="search-icon-tela-grande">search</mat-icon>
        </div>
        <!-- <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of options" [value]="option">
            {{option}}
          </mat-option>
        </mat-autocomplete> -->
        <input type="text"
          matInput
          formControlName = "busca"
         
          (keyup.enter)="onSearch()">
      </form>
      <div class="navbar-buttons-container">
        <!-- <div *ngIf="this.zipcode == null" (click)="openDialog('cep')" class="icon-home-container-tela-grande">
          <mat-icon class="icon-home-tela-grande">map</mat-icon>
          <p class="icon-home-text-tela-grande">Informe seu CEP</p>
        </div> -->
  <!--       <div *ngIf="this.user_name == null" (click)="openDialog('login')" class="icon-login-container-tela-grande">
          <mat-icon class="icon-login-tela-grande">account_circle</mat-icon>
          <p class="icon-login-text-tela-grande">Login</p>
        </div> -->

        <button [matMenuTriggerFor]="menuAccount" class="w-auto pl-0 ml-0 button-transparent" style="color: #fff;">
          <span class="d-flex align-items-center align-content-center justify-content-center ">
            <mat-icon style="margin-bottom: 2px;" mat-list-icon svgIcon="user"></mat-icon>
            <span class=" p-0 m-0 ms-1">  {{user_name ? ('Olá, ' + user_name) : 'Olá, faça seu login'}} </span>
          </span>
        </button>
        <!-- <div *ngIf="this.user_name == null" class="btn-group icon-login-container-tela-grande" dropdown>
          <button id="button-basic" dropdownToggle type="button" class="icon-login-container-tela-grande"
                  aria-controls="dropdown-basic">
                  <mat-icon class="icon-login-tela-grande h-100">account_circle</mat-icon>
                  <p class="icon-login-text-tela-grande">Olá, faça o login</p>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="user-dropdown-container dropdown-menu custom-dropdown-menu mt-4"
              role="menu" aria-labelledby="button-basic">
            <li role="menuitem"><a class="user-dropdown-item dropdown-item" (click)="openDialog('login')"><mat-icon>login</mat-icon>Login</a></li>
            <li role="menuitem"><a class="user-dropdown-item dropdown-item" href="https://teste.eubebo.com.br/autenticacao/cadastrar"><mat-icon>how_to_reg</mat-icon>Cadastrar-se</a></li>
            <li role="menuitem"><a class="user-dropdown-item dropdown-item" href="https://teste.eubebo.com.br/venda-conosco"><mat-icon>storefront</mat-icon>Venda com a gente</a></li>
            <li role="menuitem"><a class="user-dropdown-item dropdown-item" routerLink="atendimento-ao-cliente"><mat-icon>contact_mail</mat-icon>Atendimento ao cliente</a></li>
            <li role="menuitem"><a class="user-dropdown-item dropdown-item" routerLink="faq"><mat-icon>quiz</mat-icon>FAQ</a></li>
          </ul>
        </div>
        <div *ngIf="this.user_name != null" class="btn-group h-100" dropdown>
          <button id="button-basic" dropdownToggle type="button" class="icon-login-container-tela-grande"
                  aria-controls="dropdown-basic">
                  <mat-icon class="icon-login-tela-grande h-100">account_circle</mat-icon>
                  <p class="icon-login-text-tela-grande">Olá, {{user_name}}</p>
          </button>
          <ul id="dropdown-basic" *dropdownMenu class="user-dropdown-container dropdown-menu custom-dropdown-menu mt-4"
              role="menu" aria-labelledby="button-basic">
              <div class="user-dropdown-title-container">
                <h3>Minha conta</h3>
              </div>
            <li role="menuitem"><a class="user-dropdown-item dropdown-item" routerLink="pedidos"><mat-icon>receipt_long</mat-icon>Pedidos</a></li>
            <li role="menuitem"><a class="user-dropdown-item dropdown-item" routerLink="metodos-pagamento"><mat-icon>account_balance_wallet</mat-icon>Métodos de pagamento</a></li>
            <li role="menuitem"><a class="user-dropdown-item dropdown-item" (click)="openDialog('enderecos')"><mat-icon>map</mat-icon>Endereços</a></li>
            <li role="menuitem"><a class="user-dropdown-item dropdown-item" routerLink="favoritos"><mat-icon>favorite</mat-icon>Favoritos</a></li>
            <li role="menuitem"><a class="user-dropdown-item dropdown-item" routerLink="editar-perfil"><mat-icon>settings</mat-icon>Editar perfil</a></li>
            <li role="menuitem"><a class="user-dropdown-item dropdown-item" href="#" (click)="logoutUser()"><mat-icon>logout</mat-icon>Fazer logout</a></li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem"><a class="user-dropdown-item dropdown-item" href="https://teste.eubebo.com.br/venda-conosco"><mat-icon>storefront</mat-icon>Venda com a gente</a></li>
            <li role="menuitem"><a class="user-dropdown-item dropdown-item" routerLink="atendimento-ao-cliente"><mat-icon>contact_mail</mat-icon>Atendimento ao cliente</a></li>
            <li role="menuitem"><a class="user-dropdown-item dropdown-item" routerLink="faq"><mat-icon>quiz</mat-icon>FAQ</a></li>
          </ul>
        </div> -->
        <div [matBadge]="number_of_products > 0 ? number_of_products : null" matBadgeColor="warn" class="cart-container h-100" (click)="openDialog('carrinho')">
          <mat-icon class="icon-shopping-cart-tela-grande h-100">shopping_cart</mat-icon>
  <!--         <div *ngIf="number_of_products != null" class="cart-num-of-products"><p>{{number_of_products}}</p></div> -->
        </div>
      </div>
    </div>
    <!--Tela grande-->

    <!--Tela pequena-->
  </nav>
  <!--Tela pequena-->

  <!--Barra adicional tela grande e media-->
  <section #menuBar class="under-nav-container" [ngClass]="{'under-nav-center' : innerWidth >= innerHeight * 1.4, 'under-nav-end' : innerWidth <= innerHeight * 1.4}" *ngIf="innerWidth >= 993">


          <!-- ======== BUY CATEGORY ======== -->
          <div *ngIf="innerWidth >= innerHeight * 1.75 && long && lat" class="order-1" >

            <!-- ======== BUTTON BUY CATEGORY ======== -->
            <button class="buy-category" [ngClass]="{'arrow-open' : panelOpenState}"
              [style]="'height:' + menuBar.offsetHeight + 'px;width:' + panelCategory.offsetWidth + 'px'"
              (click)="this.panelOpenState=!this.panelOpenState;this.panelSubOpenState=false;" (mouseover)="awaitDropDown()"
              (mouseleave)="panelOpenState=false;panelSubOpenState=false;statusAwaitDropDown=false">
              <mat-icon class="mr-2" style="color: #510c76">menu</mat-icon>
              Compre por categoria
            </button>

            <!-- ======== DROPDOWN PANEL 1 ======== -->
            <ul #panelCategory class="dropdown-list-categories" [ngClass]="{'active-dropdown-menu' : panelOpenState}"
              (mouseover)="panelOpenState=true;panelSubOpenState=true" >
              <li *ngFor="let category of categoriesAllList"
                class="dropdown-item-categories" (click)="searchCategory(category.name)"
                (mouseover)="changeCategoryDropDown(category.id, category.name, category.subcategories);">
                <a class="item-buy-categories">{{category.name}}</a>
                <mat-icon *ngIf="category.subcategories.length > 0" class="mr-2">chevron_right</mat-icon>
              </li>
              <li id="item-all-categories" class="dropdown-item-categories" (click)="searchAllCategories()"
                (mouseover)="changeCategoryDropDown(null, 'Todas as categorias', categoriesAllList)">
                <a class="item-buy-categories">Todas as categorias</a>
                <mat-icon class="mr-2">chevron_right</mat-icon>
              </li>
            </ul>

            <!-- ======== DROPDOWN PANEL 2 ======== -->
            <div *ngIf="panelSubOpenState && categoryDropDown.subtitles.length > 0" class="dropdown-subcategory"
              [style]="'max-width:' + (1140 - panelCategory.offsetWidth) + 'px;transform: translate(' + panelCategory.offsetWidth +'px,0);'"
              (mouseover)="panelOpenState=true;" (mouseleave)="panelOpenState=false;panelSubOpenState=false;statusAwaitDropDown=false">
              <h2 (click)="searchCategory(categoryDropDown.title)" class="buy-link-cat-dropdown">{{categoryDropDown.title}}</h2>
              <ul class="panel-buy-subcategory">
               <li *ngFor="let subtitle of categoryDropDown.subtitles" style="padding: 0.5vh;">
<!--                 {{subtitle | json}} -->
                 <a *ngIf="categoryDropDown.id !== null" (click)="searchSubcategory(subtitle.category_id, subtitle.id)" class="buy-link-sub-dropdown">{{subtitle.name}}</a>
                 <a *ngIf="categoryDropDown.id === null" (click)="searchCategory(subtitle.id)" class="buy-link-sub-dropdown">{{subtitle.name}}</a>
               </li>
              </ul>
            </div>

         </div>

         <div *ngIf="street != null" style="cursor: pointer;" class="location-button" (click)="openDialog('cep')">
            <mat-icon class="location-button-icon">location_on</mat-icon>
            <p class="location-button-text" >{{street}}, {{street_number}}
              <span *ngIf="residence_number != null && residence_number != '' && residence_number !== 'Sem complemento' && residence_number !== 'null'">,</span> 
              {{residence_number? ((residence_number === 'Sem complemento' || residence_number === 'null')? '': residence_number ): ''}} - {{neighborhoodAddress}}
            </p>
         </div>
         <div *ngIf="innerWidth >= innerHeight * 1.4" class="sub-menu-button-container">
          <button *ngFor="let categoria of categoriesAllList.slice(0, 6)" class="sub-menu-button" (click)="redirecionaParaCategoria(categoria.id)">{{categoria.name}}</button>
        </div>
  </section>

  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>


<!-- ======= MENU ACCOUNT ======= -->
<mat-menu #menuAccount="matMenu" xPosition="before" class="custom d-flex justify-content-start" >
  <div class="w-100 d-flex align-items-center justify-content-center mt-1 px-3 pt-3" style="font-size: 1.5rem;">
    <!-- <p *ngIf="isLogged" > Olá, <strong>{{clientName}}</strong></p>
    <p *ngIf="!isLogged" style="font-size: 1.15rem;" class="text-center" > Olá, faça seu login ou cadastre-se</p> -->
    <p>{{isLogged?'Minha conta': 'Acesse sua conta'}}</p>
  </div>
  <button  class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  (click)="openDialog('login')" [hidden]="isLogged">
    <mat-icon mat-list-icon svgIcon="login" ></mat-icon>
    Entrar
  </button>
  <a class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  [href]="'https://teste.eubebo.com.br/autenticacao/cadastrar'" [hidden]="isLogged">
    <mat-icon mat-list-icon svgIcon="add-user" ></mat-icon>
    Criar minha conta
  </a>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  [routerLink]="['/pedidos']" [hidden]="!isLogged">
    <mat-icon svgIcon="invoice"></mat-icon>
    Pedidos
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true" [routerLink]="['/metodos-pagamento']" [hidden]="!isLogged">
    <mat-icon mat-list-icon svgIcon="wallet"></mat-icon>
    Métodos de pagamento
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  (click)="openDialog('enderecos')" [hidden]="!isLogged">
    <mat-icon svgIcon="map"></mat-icon>
    Endereços
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  [routerLink]="['/favoritos']" [hidden]="!isLogged">
    <mat-icon mat-list-icon svgIcon="heart"></mat-icon>
    Favoritos
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  [routerLink]="['/editar-perfil']" [hidden]="!isLogged">
    <mat-icon svgIcon="settings"></mat-icon>
    Editar perfil
  </button>
  <button class="button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  (click)="logoutUser()" [hidden]="!isLogged">
    <mat-icon class="item" svgIcon="logout"></mat-icon>
    Sair
  </button>
  <mat-divider class="my-2"></mat-divider>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  [routerLink]="['/venda-conosco']">
    <mat-icon mat-list-icon svgIcon="united"></mat-icon>
    <span>Venda com a gente</span>
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  [routerLink]="['/atendimento-ao-cliente']" >
    <mat-icon mat-list-icon svgIcon="call-center"></mat-icon>
    <span>Atendimento ao cliente</span>
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  [routerLink]="['/faq']" >
    <mat-icon mat-list-icon svgIcon="faq"></mat-icon>
    <span>Faq</span>
  </button>

</mat-menu>


<!-- ======= ACCOUNT OPTION MOBILE ======= -->
<mat-menu #menu="matMenu" xPosition="before" class="custom">
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true" (click)="openSidenav = false" [routerLink]="['/cliente/perfil']">
    Editar perfil
  </button>
  <button class=" button-transparent user-menu w-100" mat-menu-item [disableRipple]="true"  (click)="logout()" [hidden]="!isLogged" >
    Sair
  </button>
</mat-menu>

<!-- ======= COOKIES ======= -->
<!-- <div *ngIf="!acceptCookies" class="container card-cookies flex-column flex-sm-row">
  <p class="m-0 text-justify"><b>Cookies:</b> a gente guarda estatísticas de visitas para melhorar sua experiência no site. Ao continuar
      navegando, você concorda com a nossa  <a class="link-privacidade" href="/politica-de-privacidade">Política de Privacidade</a>.
  </p>
  <button class="btn-cookies mt-2 mt-sm-0" (click)="cookiesAccept()">
      continuar e fechar
  </button>
</div>

 -->
