<mat-drawer-container class="example-container" [hasBackdrop]="true">
  <mat-drawer style="position: fixed;" #drawer mode="side" position="start">
    <div class="d-flex flex-column">
      <div class="w-100 d-flex justify-content-end p-3"><mat-icon (click)="drawer.toggle()">close</mat-icon></div>
      <button class="store-products-category-button">Toda Loja</button>
      <button class="store-products-category-button" *ngFor="let item of getUniqueSellerCategories(allCategories)"
        (click)="openCategoryPage(item.id)">
        {{item.display_name?.replace('-', ' ')}}
      </button>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <section>
      <div *ngIf="innerWidth >= 576" class="upper-container">
        <!-- <div class="w-100" style="height: 50px; background-size: cover; background-repeat: no-repeat; background-image: url({{seller?.logo_url}});">

        </div> -->
        <div *ngIf="seller?.background_banner_url" class="banner-image" [attr.style]="'background-image: url('+ seller?.background_banner_url +')' ">

        </div>
        <div class="under-banner-container">

         
          <div class="under-banner-left-container">
            <div class="d-flex flex-row justify-content-start align-items-start ">
              <img class="under-banner-left-image" src="{{seller.logo_url}}" alt="">
              <div class="d-flex flex-column justify-content-start">
                <h1 class="ms-2 my-0" > {{seller.display_name}} </h1>
                <p class="ms-2 my-0" style="font-size: 1rem !important; transform: translateX(2px); "> Distancia: {{seller?.distance | number:"1.0-2"}} km</p>
                <p *ngIf="seller?.seller_delivery?.has_minimum_order_amount"  class="ms-2"> Pedido minimo: {{seller?.seller_delivery?.minimum_order_amount | currency: "R$"}} </p>
                <div class="ms-2 rate-container" style="cursor: pointer; "  (click)="onViewRatings()">
                  <mat-icon>star</mat-icon>
                  <span class="ms-1">{{seller.rate}} ({{seller.rateCount}})</span>
                </div>
                <p *ngIf="seller?.seller_delivery?.shipping_time" style="transform: translateX(2px);" class="ms-2">Entrega: {{seller?.seller_delivery?.shipping_time }} 
                  <span  *ngIf="seller?.seller_delivery?.shipping_fee === 0">
                   | <span style="color: #2f851a;">Grátis</span>
                  </span>
                  <span *ngIf="seller?.seller_delivery?.shipping_fee !== 0">
                    <span [ngSwitch]="seller?.seller_delivery?.shipping_type">
                      <span *ngSwitchCase="1">
                        <span>| {{seller?.seller_delivery?.shipping_fee | currency: "R$"}}</span>
                      </span>
                      <span *ngSwitchCase="2">
                        <span>| {{(seller?.seller_delivery?.shipping_fee * seller?.distance) | currency: "R$"}}</span>
                      </span>
                    </span>
                  </span>
                 
                </p>

              </div>
              
            </div>
            
            <div class="h-100 d-flex justify-content-end align-items-center pb-2">
              
            </div>

          </div>
         
         
          <div ngbDropdown class="under-banner-right-container mb-2 align-items-start">
            <div class="open-store-button">{{seller.isOpen ? 'Loja Aberta' : 'Loja Fechada'}}</div>
            <button class="see-more-button" id="dropdownBasic1" ngbDropdownToggle>Ver Mais</button>
            <div class="p-3" ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <mat-tab-group>
                <mat-tab label="Sobre">
                  <p class="mt-2">Endereço:</p>
                  <small>
                    <address>{{seller.address?.full_address}}</address>
                  </small>
                  <p class="mt-2">Outras informações:</p>
                  <p>CNPJ: {{seller.cnpj | dynamic: 'cnpj'}}</p>
                </mat-tab>
                <mat-tab label="Horário">

                  <div class="p-3">
                    <div>
                      <p *ngIf="openingHoursMonday.length > 0" class="m-2">Segunda-feira:</p>
                      <div class="d-flex flex-column align-items-center" *ngFor="let time of openingHoursMonday">
                        {{time.start.substring(0, 5)}} às {{time.end.substring(0, 5)}}
                      </div>

                    </div>

                    <div>
                      <p *ngIf="openingHoursTuesday.length > 0" class="m-2">Terça-feira:</p>
                      <div class="d-flex flex-column align-items-center" *ngFor="let time of openingHoursTuesday">
                        {{time.start.substring(0, 5)}} às {{time.end.substring(0, 5)}}
                      </div>
                    </div>

                    <div>
                      <p *ngIf="openingHoursWednesday.length > 0" class="m-2">Quarta-feira:</p>
                      <div class="d-flex flex-column align-items-center" *ngFor="let time of openingHoursWednesday">
                        {{time.start.substring(0, 5)}} às {{time.end.substring(0, 5)}}
                      </div>
                    </div>

                    <div>
                      <p *ngIf="openingHoursThursday.length > 0" class="m-2">Quinta-feira:</p>
                      <div class="d-flex flex-column align-items-center" *ngFor="let time of openingHoursThursday">
                        {{time.start.substring(0, 5)}} às {{time.end.substring(0, 5)}}
                      </div>
                    </div>

                    <div>
                      <p *ngIf="openingHoursFriday.length > 0" class="m-2">Sexta-feira:</p>
                      <div class="d-flex flex-column align-items-center" *ngFor="let time of openingHoursFriday">
                        {{time.start.substring(0, 5)}} às {{time.end.substring(0, 5)}}
                      </div>
                    </div>

                    <div>
                      <p *ngIf="openingHoursSaturday.length > 0" class="m-2">Sábado:</p>
                      <div class="d-flex flex-column align-items-center" *ngFor="let time of openingHoursSaturday">
                        {{time.start.substring(0, 5)}} às {{time.end.substring(0, 5)}}
                      </div>
                    </div>

                    <div>
                      <p *ngIf="openingHoursSunday.length > 0" class="m-2">Domingo:</p>
                      <div class="d-flex flex-column align-items-center" *ngFor="let time of openingHoursSunday">
                        {{time.start.substring(0, 5)}} às {{time.end.substring(0, 5)}}
                      </div>
                    </div>
                  </div>

                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </div>
        
        
      </div>
      <div *ngIf="innerWidth <= 575" class="upper-container">
        <div class="under-banner-container">
          <div class="under-banner-left-container">
            <div>
              <img class="under-banner-left-image" src="{{seller.logo_url}}" alt="">
              <div class="rate-container" style="cursor: pointer;" (click)="onViewRatings()">
                <mat-icon>star</mat-icon>
                <p>{{seller.rate}}</p>
              </div>
            </div>
          </div>
          <div ngbDropdown class="under-banner-right-container">
            <div class="open-store-button">Loja&nbsp;Aberta</div>
            <button class="see-more-button" (click)="openModal('store-infos')">Ver Mais</button>

          </div>
        </div>
        <h1>{{seller.display_name}}</h1>
        <button class="categories-toggle-button" mat-raised-button (click)="drawer.toggle()"><mat-icon>menu</mat-icon>
          Categorias</button>
      </div>
      <div class="bottom-container">
        <div *ngIf="innerWidth >= 576" class="store-products-menu">
          <!--           <h1 class="store-products-minimum-purchase-value">Pedido Mínimo - R$30,00</h1> -->
          <button class="store-products-category-button">Toda Loja</button>
          <button class="store-products-category-button" *ngFor="let item of getUniqueSellerCategories(allCategories)"
            (click)="openCategoryPage(item.id)">
            {{item.slug}}
          </button>
        </div>
        <div class="store-products-container">
          <app-see-more *ngFor="let category of allCategories" [categoryData]="category" [seller_id]="seller_id">
          </app-see-more>
          <div *ngIf="allProductsBySeller.length == 0 && !loading">
            <div class="alert-container">
              <H2>Oops... parece que esta loja não possui nenhum produto.</H2>
              <ng-lottie width="30vh" height="30vh" [options]="options"
                (animationCreated)="animationCreated($event)"></ng-lottie>
            </div>
          </div>
          <div *ngIf="allProductsBySeller.length == 0 && loading">
            <div style="display: flex; align-items: center; justify-content: start; gap: 1.5vw; margin-top: 5vh;">
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
            </div>
            <div style="display: flex; align-items: center; justify-content: start; gap: 1.5vw; margin-top: 5vh;">
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
            </div>
            <div style="display: flex; align-items: center; justify-content: start; gap: 1.5vw; margin-top: 5vh;">
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
            </div>
            <div style="display: flex; align-items: center; justify-content: start; gap: 1.5vw; margin-top: 5vh;">
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
              <p-skeleton size="15vh"></p-skeleton>
            </div>
          </div>
        </div>
      </div>
    </section>
  </mat-drawer-content>
</mat-drawer-container>