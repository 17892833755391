import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute ,Router } from '@angular/router';
import { ProdutoPageComponent } from '../produto-page/produto-page.component';
import { ProductsEubeboApiService } from 'src/app/services/products-eubebo-api.service';
import { SellerEubeboApiService } from 'src/app/services/sellers-eubebo-api.service';
import { CurrentAddressService } from 'src/app/services/current-address.service';
import { CategoriesEubeboApiService } from 'src/app/services/categories-eubebo-api.service';
import { SubcategoriesEubeboApiService } from 'src/app/services/subcategories-eubebo-api.service';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';

@Component({
  selector: 'app-busca-page',
  templateUrl: './busca-page.component.html',
  styleUrls: ['./busca-page.component.scss']
})
export class BuscaPageComponent implements OnInit {

  larguraTela: number = window.innerWidth;

  searchedTerm: string | null = null
  newSearchedTerm: string | null = null
  isItem: boolean = false
  selectedIndex = 0
  priceTo: number | null = null
  priceFrom: number | null = null
  is_express: any
  search: string | null = null
  category_slug: string | null = "null"
  subcategory_id_list: string[] = []
  subcategory_slug: string | null = "null"

  shipping_value: any

  category_id: string = ''
  category_id_list: string[] = []

  productRequestPage: number = 1
  productRequestLimit: number = 12
  sellerRequestPage: number = 1
  sellerRequestLimit: number = 12

  allSellersReqInfo: any
  allSellers: any = []
  allSellersToShow: any = []
  allSellersFiltered: any = []
  allSellersSearch: any =  []
  allSellerUsers: any = []
  allProductsReqInfo: any
  allProducts: any = []
  allProductsToShow: any = []
  allProductsFiltered: any = []
  allProductsSearch: any = []
  allCategories: any = []
  allSubcategories: any = []
  subcategoriesFiltered: any = []

  selected = ''

  latitude: string | null = null
  longitude: string | null = null

  orderByList = [
    {value: 'ASC', viewValue: 'Menor preço'},
    {value: 'DESC', viewValue: 'Maior preço'}
  ]

  selectOrderBy = this.orderByList[0].value;
  user_id: any = null;
  favorites:any = []

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private currentAddressService: CurrentAddressService,
    private sellerEubeboApiService: SellerEubeboApiService,
    private productsEubeboApiService: ProductsEubeboApiService,
    private categoriesEubeboApiService: CategoriesEubeboApiService,
    private subcategoriesEubeboApiService: SubcategoriesEubeboApiService,
    private authUserService: AuthUserService,
    private customersEubeboApiService: CustomersEubeboApiService,
    ) {
      this.user_id = this.authUserService.getValue(this.authUserService.userId)
      this.latitude = this.currentAddressService.getValue(this.currentAddressService.address_lat)
      this.longitude = this.currentAddressService.getValue(this.currentAddressService.address_long)
    }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.is_express = params['is_express'];
      this.isItem = params['isItem'];
      this.search = params['search']
      this.category_id_list = [params['category_id']]
      this.subcategory_id_list = [params['subcategory_id']]
      this.subcategory_slug = params['subcategory']
      this.category_slug = this.search?.toLowerCase()!
      console.log(this.category_slug)
      console.log(this.subcategory_slug)
      if (this.category_id_list[0] != undefined) {
        console.log(this.category_id_list[0])
        this.getCategoryById(this.category_id_list[0])
      }
      if (this.subcategory_id_list[0] != undefined) {
        this.getSubcategoryById(this.subcategory_id_list[0])
      }
      if (this.category_slug == undefined) {
        this.category_slug = 'null'
      }
      if (this.subcategory_slug == undefined) {
        this.subcategory_slug = 'null'
      }

      this.route.params.subscribe(params => {
        this.searchedTerm = params['slug'];

        this.getAllProducts()
        this.getAllSellerBySearch()
        this.getUser();
        if (this.isItem) {
          this.selectedIndex = 1
        }
      });
    });

    this.getAllCategories()
    this.getAllSubcategories()
    this.getAllProducts()
    this.getAllSellerBySearch()
    this.getUser();
  }

  calcularShippingFee(seller: any) {
    if (seller?.seller_delivery?.shipping_type == 1) {
      return seller?.seller_delivery?.shipping_fee
    }
    if (seller?.seller_delivery?.shipping_type == 2) {
      return seller?.seller_delivery?.shipping_fee * seller?.distance
    }
  }


  loadMoreSellers() {
    this.sellerRequestPage++

    if (this.latitude && this.longitude) {
      this.sellerEubeboApiService.getBySearchTerm(this.sellerRequestPage, this.sellerRequestLimit, this.searchedTerm, this.latitude, this.longitude, null, this.is_express, 'Delivery', 2).subscribe({
        next: (res: any) => {
          this.allSellers = res.items

          if (this.searchedTerm == 'null') {
            this.searchedTerm = null
          }
          if (this.searchedTerm != null) {
            for (let i = 0; i < this.allSellersSearch.length; i++) {
              this.allSellersToShow.push(this.allSellersSearch[i])
            }
          } else if (this.searchedTerm  == null || this.searchedTerm == '') {
            for (let i = 0; i < this.allSellers.length; i++) {
              this.allSellersToShow.push(this.allSellers[i])
            }
          }
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }

  loadMoreProducts() {
    this.productRequestPage++

    this.productsEubeboApiService.getAllProducts(this.productRequestPage, this.productRequestLimit, this.latitude, this.longitude, this.searchedTerm, this.priceTo, this.priceFrom, this.category_slug, this.subcategory_slug, this.category_id_list, this.subcategory_id_list, this.selectOrderBy, 'Delivery').subscribe((res: any) => {
      this.allProducts = res.items.filter((item: any) => {
        return item.is_active == true
      })

      for (let i = 0; i < this.allProducts.length; i++) {
        this.allProductsToShow.push(this.allProducts[i])
      }
    })
  }

  categoryFilter(slug: string, category_id?: string) {
    this.category_slug = slug

    if(category_id) {
      this.category_id = category_id
    }

    this.subcategoriesFiltered = this.allSubcategories.filter((item: any) => {
      return item.category_id === category_id
    })

    this.getAllProducts()
  }

  subcategoryFilter(slug: string, category_id?: string) {
    this.subcategory_slug = slug
    this.getAllProducts()
  }

  orderFilter(index: number) {
    this.selectOrderBy = this.orderByList[index].value
    this.getAllProducts()
  }

  entregaGratisFilter() {
    this.allProducts = this.allProducts.filter((item: any) => {
      return item.seller.seller_delivery?.shipping_fee == 0
    })
    this.allSellersToShow = this.allSellersToShow.filter((item: any) => {
      return (item.seller_delivery && item.seller_delivery.shipping_fee == 0 || item.seller_delivery && item.seller_delivery.shipping_fee == null)
    })

  }

  entregaExpressaFilter() {
    this.is_express = true
    this.getAllSellerBySearch()
  }

  resetFilter() {
    this.selectOrderBy = this.orderByList[0].value
    this.category_slug = 'null'
    this.subcategory_slug = 'null'
    this.is_express = null

    this.getAllSellerBySearch()
    this.getAllProducts()
  }

  returnToDeliveryPage(): void {
    this.router.navigate([`delivery`])
  }

  calculateSubmenuItemsPrice(base_price: number, discount: number) {
    return ((base_price - (base_price * (discount / 100))) / 100).toString()
  }

  openStorePage(slug: string, id: string): void {
    this.router.navigate([`lojas/${slug}/${id}`])
  }

  openDialog(item_id: string) {
    const dialogRef = this.dialog.open(ProdutoPageComponent, {
      panelClass: 'container-product',
      
      data: {
        item_id: item_id
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })
  }

  getAllSellerBySearch(): void {
    if (this.latitude && this.longitude) {
      this.sellerEubeboApiService.getBySearchTerm(this.sellerRequestPage, this.sellerRequestLimit, this.searchedTerm, this.latitude, this.longitude, null, this.is_express, 'Delivery', 2).subscribe({
        next: (res: any) => {
          this.allSellersReqInfo = res
          this.allSellers = res.items

          if (this.searchedTerm == 'null') {
            this.searchedTerm = null
          }

          if (this.searchedTerm != null) {
            for (let i = 0; i < this.allSellers.length; i++) {
              const seller = this.allSellers[i];

              if (
                !this.allSellersToShow.some((existingSeller: any) => existingSeller.id === seller.id) &&
                seller.display_name.toLowerCase().includes(this.searchedTerm.toLowerCase())) {
                this.allSellersToShow.push(seller);
              }
            }
          } else if (this.searchedTerm  == null || this.searchedTerm == '') {
            this.allSellersToShow = this.allSellers
          }
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }

  getAllProducts(): void {
    this.productsEubeboApiService.getAllProducts(this.productRequestPage, this.productRequestLimit, this.latitude, this.longitude, this.searchedTerm, this.priceTo, this.priceFrom, this.category_slug, this.subcategory_slug, this.category_id_list, this.subcategory_id_list, this.selectOrderBy, 'Delivery').subscribe((res: any) => {
      this.allProductsReqInfo = res
      this.allProducts = res.items.filter((item: any) => {
        return item.is_active == true
      })

      this.allProductsToShow = this.allProducts
    })
  }

  getCategoryById(id: string) {
    this.categoriesEubeboApiService.getCategoryById(id).subscribe({
      next: (res: any) => {
        if(this.category_slug == null || this.category_slug == '') {
          this.category_slug = res.slug
        }
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getAllCategories(): void {
    this.categoriesEubeboApiService.getAll().subscribe({
      next: (res: any) => {
        this.allCategories = res
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getSubcategoryById(id: string) {
    this.subcategoriesEubeboApiService.getById(id).subscribe({
      next: (res: any) => {
        this.subcategory_slug = res.slug
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getAllSubcategories(): void {
    this.subcategoriesEubeboApiService.getAll().subscribe({
      next: (res: any) => {
        this.allSubcategories = res
        this.subcategoriesFiltered = this.allSubcategories
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  
  isFavorited(item: any){
  
    if(this.favorites && this.favorites.length != 0){
      return this.favorites.some((elem:any)=> item.id == elem.product_id);
    }
    return false;

  }

  togleFavority(item: any, event: any){

    event.stopPropagation();
    if(this.user_id != null){

      let favorited: any = this.favorites.find((elem:any)=> item.id == elem.product_id);
      if(!favorited){
        this.customersEubeboApiService.registerNewFavoriteProduct(this.user_id, {
          product_id: item.id
        }).subscribe({
          next: (res: any) => {
            this.getUser();
          },
          error: (err: any) => {
            console.log(err)
          }
        })
      } else {
        this.customersEubeboApiService.deleteFavoriteProduct(this.user_id, favorited._id).subscribe({
          next: (res: any) => {
            this.getUser();
          },
          error: (err: any) => {
            console.log(err)
          }
        })
      }
     
     
    } 
  }

  getUser(){
    if (this.user_id != null) {
      this.customersEubeboApiService.getUserById(this.user_id).subscribe({
        next: (res: any) => {
          this.favorites = res.favorite_products
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }

}
