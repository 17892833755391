import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { Subscription, timeout } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SocketService {

constructor( private socket: Socket) {
  this.connect();
 }



   socketChatId(chat_id: any){
    return this.socket.fromEvent<any>(`chats-${chat_id}-new-message`);
  }

  unsubscribeChat(chat_id: any){
    this.socket.removeListener(`chats-${chat_id}-new-message`, (res: any) => {

    })

  }

  disconnect(){
    this.socket.disconnect();
  }

  connect(){
    this.socket.connect();

  }

  printSocket(){
    console.log(this.socket)
  }

}
