import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirm-just-liquid',
  templateUrl: './confirm-just-liquid.component.html',
  styleUrls: ['./confirm-just-liquid.component.scss']
})
export class ConfirmJustLiquidComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private dialogConfirmJustLiquid: MatDialogRef<ConfirmJustLiquidComponent>,
    private router: Router,
  ) { }


  ngOnInit(): void {
  }

  close() {
    this.dialogConfirmJustLiquid.close();
  }

  confirm(){
    this.dialogConfirmJustLiquid.close(1);
  }


}
