export enum OrderStatus {
  "realizado" = 1,
  "aceito" = 2,
  "rejeitado" = 3,
  "aguardando pagamento" = 4,
  "processando o pagamento" = 5,
  "pagamento efetivado" = 7,
  "cancelado" = 8,
  "Pedido em Separação" = 9,
  "entregue" = 10,
  "A caminho" = 11,
}