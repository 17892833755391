import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CartProductsList } from 'src/app/config/models/cart-products.model';
import { AddProductErrorDialogComponent } from 'src/app/pages/produto-page/add-product-error-dialog/add-product-error-dialog.component';
import { CartEubeboService } from 'src/app/services/cart-eubebo.service';
import { ProductsEubeboApiService } from 'src/app/services/products-eubebo-api.service';

@Component({
  selector: 'app-edit-item-cart-dialog',
  templateUrl: './edit-item-cart-dialog.component.html',
  styleUrls: ['./edit-item-cart-dialog.component.scss']
})
export class EditItemCartDialogComponent implements OnInit {

  productCounter: number

  product_by_id: any

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditItemCartDialogComponent>,
    private productsEubeboApiService: ProductsEubeboApiService,
    private cartEubeboService: CartEubeboService,
  ) {
    this.productCounter = data.product.number_of_products
  }

  ngOnInit(): void {
    this.searchProductById()
  }

  searchProductById() {
    this.productsEubeboApiService.getProduct(this.data.product?.product_id).subscribe({
      next: (res: any) => {
        this.product_by_id = res
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  increaseCounter(): void {
    let listOfProductsParse =  this.cartEubeboService.getValue(this.cartEubeboService.productsList)
    let numOfProducts = Number(this.cartEubeboService.getValue(this.cartEubeboService.numOfProducts))
    let listOfProducts
    let listOfProductsLessIndex

    if (listOfProductsParse) {
      listOfProducts = JSON.parse(listOfProductsParse)
    }

    this.productCounter ++


    const atualCartNumber = numOfProducts + 1

    listOfProductsLessIndex = listOfProducts.data.filter((item: any) => item.product_id != this.data.product?.product_id)

    let listProducts: CartProductsList = {
      data: []
    };

    if (!listProducts.data[0] || listProducts.data[0] && listProducts.data[0].product_seller_id == this.data.product.product_seller_id) {

      listProducts.data.push(...listOfProductsLessIndex, {
        product_id: this.data.product.product_id,
        product_name: this.data.product.product_name,
        product_base_price: this.data.product.product_base_price,
        product_seller_id: this.data.product.product_seller_id,
        product_current_offer: this.data.product.product_current_offer,
        number_of_products: this.productCounter,
        temperature: this.data.product?.temperature || undefined,
        is_just_liquid: this.data.product?.is_just_liquid || undefined
      });

      this.cartEubeboService.setValue(this.cartEubeboService.numOfProducts, atualCartNumber);
      this.cartEubeboService.setValue(this.cartEubeboService.productsList, JSON.stringify(listProducts));
    }
  }

  decrementCounter(): void {
    let listOfProductsParse =  this.cartEubeboService.getValue(this.cartEubeboService.productsList)
    let numOfProducts = Number(this.cartEubeboService.getValue(this.cartEubeboService.numOfProducts))
    let listOfProducts
    let listOfProductsLessIndex

    if (listOfProductsParse) {
      listOfProducts = JSON.parse(listOfProductsParse)
    }

    if (this.productCounter >= 1) {

      this.productCounter --

      const atualCartNumber = numOfProducts - 1

      listOfProductsLessIndex = listOfProducts.data.filter((item: any) => item.product_id != this.data.product?.product_id)

      let listProducts: CartProductsList = {
        data: []
      };

      if (!listProducts?.data[0] || listProducts?.data[0] && listProducts.data[0]?.product_seller_id == this.data.product?.product_seller_id) {

        if (this.productCounter > 0) {
          listProducts.data.push(...listOfProductsLessIndex, {
            product_id: this.data.product.product_id,
            product_name: this.data.product.product_name,
            product_base_price: this.data.product.product_base_price,
            product_seller_id: this.data.product.product_seller_id,
            product_current_offer: this.data.product.product_current_offer,
            number_of_products: this.productCounter,
            temperature: this.data.product?.temperature || undefined,
            is_just_liquid: this.data.product?.is_just_liquid || undefined
          });
        }

        this.cartEubeboService.setValue(this.cartEubeboService.numOfProducts, atualCartNumber);
        this.cartEubeboService.setValue(this.cartEubeboService.productsList, JSON.stringify(listProducts));
      }
    }
  }
}
