import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ProdutoPageComponent } from '../../produto-page/produto-page.component';
import { ProductsEubeboApiService } from 'src/app/services/products-eubebo-api.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {

  @Input() items: any[] = [];
  @Input() itemsPerRow: number = 7;

  itemsInfo: any[] = []

  loading = true

  rows: any[][] = [];

  constructor(
    public dialog: MatDialog,
    private productsEubeboApiService: ProductsEubeboApiService,
  ) {}

  ngOnInit(): void {
    this.getProductsInfo()
    this.calculateGrid();
  }



  calculateSubmenuItemsPrice(base_price: any, id: any) {
    let discount = 0
    let calculate = 0

    for (let i = 0; i < this.itemsInfo.length; i++) {
      if (id === this.itemsInfo[i].id) {
        discount = (this.itemsInfo[i]?.current_offer?.individual_discount_percentage ?? 0)
      }
    }

    calculate = ((base_price - (base_price * (discount / 100))) / 100)
    return calculate
  }

  getProductsInfo() {
    for (let i = 0; i < this.items.length; i++) {
      this.productsEubeboApiService.getProduct(this.items[i].product_id).subscribe({
        next: (res: any) => {
          this.itemsInfo.push(res)
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }

  openProduct(product_id: string): void {
    const dialogRef = this.dialog.open(ProdutoPageComponent, {
      panelClass: 'container-product',
    
      data: {
        item_id: product_id
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })
  }

  calculateGrid(): void {
    const totalRows = Math.ceil(this.items?.length / this.itemsPerRow);
    let itemCounter = 0;

    for (let i = 0; i < totalRows; i++) {
      const row: any[] = [];

      for (let j = 0; j < this.itemsPerRow; j++) {
        if (itemCounter < this.items.length) {
          row.push(this.items[itemCounter]);
          itemCounter++;
        }
      }

      this.rows.push(row);
    }
  }
}
