import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    private customersEubeboApiService: CustomersEubeboApiService,
  ) { }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close()
  }

  deleteAddress() {
    this.customersEubeboApiService.deleteAddress(this.data.user_id, this.data.address_id).subscribe({
      next: (res: any) => {
        this.close()
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }
}
