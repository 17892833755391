import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodsEubeboApiService {

  constructor(private http: HttpClient) { }

  
  getAll(platform_type: string): Observable<any> {

    let params = new HttpParams();

    if(platform_type){
      params = params.append('platform_type', platform_type);
    }
    return this.http.get( `${environment.url_api}payment-methods`, {params})
  }

}
