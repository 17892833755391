import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationRoutingModule } from './navigation-routing.module';
import { SharedModule } from '../shared/shared.module';

//
import { NavigationComponent } from './navigation.component';
import { CepDialogComponent } from './cep-dialog/cep-dialog.component';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { CartDialogComponent } from './cart-dialog/cart-dialog.component';
import { CupomDialogComponent } from './cupom-dialog/cupom-dialog.component';
import { ErrorDialogComponent } from './login-dialog/error-dialog/error-dialog.component';
import { EditItemCartDialogComponent } from './cart-dialog/edit-item-cart-dialog/edit-item-cart-dialog.component';
import { ConfirmAddressComponent } from './cep-dialog/confirm-address/confirm-address.component';
import { CupomErrorComponent } from './cupom-dialog/cupom-error/cupom-error.component';
import { DeleteDialogComponent } from './cep-dialog/delete-dialog/delete-dialog.component';

// MatModules
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';

// Modules
import { DynamicPipeModule } from "../config/pipe/dynamic-pipes/dynamic.module";
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { AgmCoreModule } from '@agm/core';
import { NgxMaskModule } from 'ngx-mask';
import { LottieModule } from 'ngx-lottie';
import { ChatModule } from '../pages/chat/chat.module';
import { MatMenuModule } from '@angular/material/menu';
import {MatRippleModule} from '@angular/material/core';

@NgModule({
  declarations: [
    NavigationComponent,
    CepDialogComponent,
    LoginDialogComponent,
    CartDialogComponent,
    CupomDialogComponent,
    ErrorDialogComponent,
    EditItemCartDialogComponent,
    ConfirmAddressComponent,
    CupomErrorComponent,
    DeleteDialogComponent,
  ],
  imports: [
    NavigationRoutingModule,
    ReactiveFormsModule,
    CommonModule,
    ChatModule,
    SharedModule,
    RouterModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatChipsModule,
    MatBadgeModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSidenavModule,
    MatDividerModule,
    MatButtonModule,
    AgmCoreModule,
    NgxMaskModule,
    BsDropdownModule,
    DynamicPipeModule,
    LottieModule,
    MatMenuModule,
    MatRippleModule
  ],
  exports: [
    NavigationComponent,
  ]
})
export class NavigationModule { }
