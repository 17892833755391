<form [formGroup]="login">
  <div *ngIf="data?.deactivatable != false" (click)="closeModal()" class="close-button">
    <mat-icon>close</mat-icon>
  </div>
  <img class="eubebo-logo" [src]="eubeboLogo" alt="">
  <p class="aviso">Entrar na sua conta</p>
  <div class="input-container">
    <!-- <input formControlName="email" class="input-login" type="text" placeholder="Digite seu e-mail"> -->
    <p>
      <mat-form-field class="input" appearance="outline">
        <mat-label>Email</mat-label>
        <input class="input" matInput formControlName="email">
      </mat-form-field>
      <mat-error *ngIf="login.get('email')?.hasError('email') && !login.get('email')?.hasError('required')">
        Digite um email válido
      </mat-error>
      <mat-error *ngIf="login.get('email')?.hasError('required') && login.get('email')?.touched">
        O email é obrigatório
      </mat-error>
    </p>
    <p>
      <mat-form-field class="input" appearance="outline">
        <mat-label>Senha</mat-label>
        <input class="input" matInput formControlName="password" [type]=" showPassword ? 'text' : 'password'">
        <mat-icon (click)="showPassword = !showPassword" matSuffix>{{showPassword ? 'visibility' : 'visibility_off' }}</mat-icon>
      </mat-form-field>
      <mat-error *ngIf="login.get('password')?.hasError('required') && login.get('password')?.touched">
        A senha é obrigatório
      </mat-error>
    </p>
    <mat-hint style="cursor: pointer;" (click)="redirectToRegisterForgetPassword()">Esqueceu a senha?</mat-hint>
  </div>
  <div class="button-container">
    <button (click)="onSubmit()" class="login-button">Entrar na Conta</button>
    <button (click)="redirectToRegisterPage()" class="register-button">Criar Conta</button>
  </div>
</form>
