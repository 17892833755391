<section *ngIf="user_id != null">
  <span (click)="closeModal()" class="close-button">
    <mat-icon>close</mat-icon>
  </span>
  <div *ngIf="currentStoreName" class="first-container row">
    <div class="vendedor-info col-8">
      <span class="vendedor-titulo">Seu pedido em</span>
      <h4 class="vendedor-nome">{{currentStoreName}} </h4>

    </div>
    <button *ngIf="currentStoreName" class="vendedor-cardapio p-2 col-4"
      (click)="openStorePage(currentStoreName, currentStoreId)">Ver cardápio</button>
  </div>
  <div class="empty-cart-div" *ngIf="!currentStoreName || listProductsInCart.data.length == 0">
    <div class="empty-cart-div-content">
      <ng-lottie width="35vh" height="35vh" [options]="options"
        (animationCreated)="animationCreated($event)"></ng-lottie>
      <h1 class="empty-cart-text">Adicione produtos no carrinho</h1>
    </div>
  </div>
  <div class="second-container" style="overflow-y: auto; height: 30vh;" *ngIf="listProductsInCart.data.length != 0">
    <div *ngFor="let item of listProductsInCart.data; let index = index">

      <p>{{item.number_of_products}}x {{item.product_name}} </p>
     
      <div class="second-bottom-container">
        <div class="second-button-container">
          <button class="second-button ps-0 me-2" (click)="editProductsFromCart(index)">Editar</button>
          <button class="second-button ps-0 me-2" (click)="removeProductsFromCart(index)">Remover</button>

          <span *ngIf="item?.is_just_liquid" style="background-color: #d8d8d8af; border-radius: 5px;"
            class=" ms-1 mb-1 p-1 ">
            <mat-icon svgIcon="retornavel"></mat-icon>
          </span>

          <span [ngSwitch]="item?.temperature">
            <span *ngSwitchCase="'room_temperature'">
              <span class="tag ms-1 mb-1">Temperatura Ambiente</span>
            </span>
            <span *ngSwitchCase="'chilled'">
              <span class="tag ms-1 mb-1">Gelado</span>
            </span>
          </span>
        </div>
        <p class="second-price">{{+item.product_base_price * +item.number_of_products | dynamic: 'currency'}}</p>
      </div>
      <div class="offer-flag" *ngIf="item.product_current_offer != null">
        <mat-icon class="offer-icon">sell</mat-icon>
        <h3 class="offer-text">Item em oferta!</h3>
      </div>
    </div>
  </div>
  <div class="third-container" (click)="openModal('cupom')" *ngIf="listProductsInCart.data.length > 0">
    <div class="cupom-upper-container">
      <div class="cupom-image-container">
        <img class="cupom-image" [src]="cupomLogo" alt="">
      </div>
      <div class="cupom-container">
        <h1 class="cupom-title">Cupom</h1>
        <h2 class="cupom-text">{{cupom != null ? cupom.code : 'Código do cupom'}}</h2>
      </div>
    </div>
    <div class="cupom-button-container">
      <mat-icon class="cupom-button">arrow_forward_ios</mat-icon>
    </div>
  </div>
  <div class="fourth-container" *ngIf="listProductsInCart.data.length > 0">
    <textarea class="checkout-text" placeholder="Observações ..."></textarea>
    <div class="checkout-info-container">
      <div class="checkout-info-left">
        <p>Subtotal</p>
        <p>Taxa de entrega</p>
        <p *ngIf="cupom">Desconto</p>
        <p>Total</p>
      </div>
      <div class="checkout-info-right">
        <p>{{subtotal | dynamic: 'currency'}}</p>
        <p *ngIf="isShippingFeeNumber(); else notANumber">{{this.shipping_value | dynamic: 'currency'}}</p>
        <ng-template #notANumber>
          <p>{{ 0 | dynamic: 'currency' }}</p>
        </ng-template>
        <p *ngIf="cupom">- {{discount_value | dynamic: 'currency'}}</p>
        <p>{{total | dynamic: 'currency'}}</p>
      </div>
    </div>
    <small
      *ngIf="currentStore?.seller_delivery?.has_minimum_order_amount && (currentStore?.seller_delivery?.minimum_order_amount > subtotal)">
      O pedido mínimo para essa loja é de <span style="color: red;">
        {{currentStore?.seller_delivery?.minimum_order_amount | currency: "R$"}}</span>, não inclusa a taxa de entrega.
    </small>
    <button (click)="choosePaymentMethod()" class="checkout-button">Escolher forma de pagamento</button>
  </div>
</section>

<!-- <section *ngIf="user_id == null">
  <div (click)="closeModal()" class="close-button">
    <mat-icon>close</mat-icon>
  </div>

  <div>

    <p>Já tem um endereço salvo?</p>
    <p>Entre na sua conta para selecionar seu endereço.</p>
    <button (click)="openModal('login')" class="login-button">Entrar na Conta</button>
    <button (click)="redirectToRegisterPage()" class="register-button">Criar Conta</button>

  </div>
</section> -->