<mat-card class="card-body m-1"
(mouseover)="mouseover=true" (mouseout)="mouseover=false" (click)="selectAddress(address)"
[ngClass]="{'card-selected mat-elevation-z6': selected, 'mat-elevation-z4' : mouseover}">
    <span class="selection">
        <mat-icon>check_circle</mat-icon>
    </span>

   

    <div class="row">
        <div class="col-9 col-md-10">
            <div class="card-title">
                <h5>{{address?.receiver_name}}</h5>
            </div>
            <p class="full_address card-text">{{address?.address?.full_address}}</p>
        </div>
        
        <div class="col-3 col-md-2 d-flex align-items-center">
            <small>Alterar</small>
        </div>
    </div>
    

    
</mat-card>
