import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CurrentAddressService } from 'src/app/services/current-address.service';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LoginDialogComponent } from 'src/app/navigation/login-dialog/login-dialog.component';

@Component({
  selector: 'app-edit-endereco',
  templateUrl: './edit-endereco.component.html',
  styleUrls: ['./edit-endereco.component.scss']
})
export class EditEnderecoComponent implements OnInit {

  address_form: FormGroup

  address_info: any = {}

  user_info: any = {}

  name_state_formated: string = ''
  name_country_formated: string = ''

  hasSearchedAddress: boolean = false

  screenIndex: number = 1
  isOnFirstScreen: boolean = true
  isOnSecondScreen: boolean = false
  isOnThirdScreen: boolean = false
  isOnFourthScreen: boolean = false

  // Agm
  latitude: number = 0
  longitude: number = 0
  zoom: number = 15
  private geoCoder: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LoginDialogComponent>,
    private formBuilder: FormBuilder,
    private authUserService: AuthUserService,
    private currentAddressService: CurrentAddressService,
    private customersEubeboApiService: CustomersEubeboApiService,
  ) {
    this.address_form = this.formBuilder.group({
      // Geral
      main: [false],
      receiver_name: [null],
      phone_number: [null],
      cellphone_number: [null],
      // Address
      address_name: [null],
      address_zipcode: [null],
      address_street: [null],
      address_street_number: [null],
      address_neighborhood: [null],
      address_reference: [null],
      address_city: [null],
      address_state: [null],
      address_country: [null],
      address_complementary: [null],
      address_latitude: [null],
      address_longitude: [null]
    })
  }

  ngOnInit(): void {
    if (this.data.type == 'edit-address') {
      this.getCustomerInfo()
    }
  }

  inputHandle(event: any) {
    var number = event.target.value;

    if (number.length == 8) {
      this.searchAddress();
    }
  }

  markerDragEnd($event: any) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.currentAddressService.setValue(this.currentAddressService.address_lat, $event.coords.lat)
    this.currentAddressService.setValue(this.currentAddressService.address_long, $event.coords.lng)
  }

  selectScreen(screenIndex: number) {
    switch (screenIndex) {
      case 1:
        this.isOnFirstScreen = true
        this.isOnSecondScreen = false
        this.isOnThirdScreen = false
        this.isOnFourthScreen = false
        this.screenIndex = 1
        break
      case 2:
        this.isOnFirstScreen = false
        this.isOnSecondScreen = true
        this.isOnThirdScreen = false
        this.isOnFourthScreen = false
        this.screenIndex = 2
        break
      case 3:
        this.isOnFirstScreen = false
        this.isOnSecondScreen = false
        this.isOnThirdScreen = true
        this.isOnFourthScreen = false
        this.screenIndex = 3
        break
      case 4:
        this.isOnFirstScreen = false
        this.isOnSecondScreen = false
        this.isOnThirdScreen = false
        this.isOnFourthScreen = true
        this.screenIndex = 4
        break
    }
  }

  searchAddress() {
    if (this.address_form.value.address_zipcode.length == 8) {
      this.currentAddressService.getAddressByCEP(this.address_form.value.address_zipcode).subscribe({
        next: (res: any) => {
          if (res.uf === 'AC') {
            this.name_state_formated = 'Acre';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'AL') {
            this.name_state_formated = 'Alagoas';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'AP') {
            this.name_state_formated = 'Amapá';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'AM') {
            this.name_state_formated = 'Amazonas';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'BA') {
            this.name_state_formated = 'Bahia';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'CE') {
            this.name_state_formated = 'Ceará';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'DF') {
            this.name_state_formated = 'Distrito Federal';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'ES') {
            this.name_state_formated = 'Espírito Santo';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'GO') {
            this.name_state_formated = 'Goiás';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'MA') {
            this.name_state_formated = 'Maranhão';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'MT') {
            this.name_state_formated = 'Mato Grosso';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'MS') {
            this.name_state_formated = 'Mato Grosso do Sul';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'MG') {
            this.name_state_formated = 'Minas Gerais';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'PA') {
            this.name_state_formated = 'Pará';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'PB') {
            this.name_state_formated = 'Paraíba';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'PR') {
            this.name_state_formated = 'Paraná';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'PE') {
            this.name_state_formated = 'Pernambuco';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'PI') {
            this.name_state_formated = 'Piauí';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'RJ') {
            this.name_state_formated = 'Rio de Janeiro';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'RN') {
            this.name_state_formated = 'Rio Grande do Norte';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'RS') {
            this.name_state_formated = 'Rio Grande do Sul';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'RO') {
            this.name_state_formated = 'Rondônia';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'RR') {
            this.name_state_formated = 'Roraima';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'SC') {
            this.name_state_formated = 'Santa Catarina';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'SP') {
            this.name_state_formated = 'São Paulo';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'SE') {
            this.name_state_formated = 'Sergipe';
            this.name_country_formated = 'Brasil'
          } else if (res.uf === 'TO') {
            this.name_state_formated = 'Tocantins';
            this.name_country_formated = 'Brasil'
          } else {
            this.name_state_formated = '';
            this.name_country_formated = ''
          }

          if (this.name_country_formated == 'Brasil') {
            this.address_form.patchValue({
              address_street: res.logradouro,
              address_neighborhood: res.bairro,
              address_city: res.localidade,
              address_state: res.uf,
              address_country: 'BR'
            })
          }

          this.currentAddressService.getAddressLatAndLong(
            this.address_form.value.address_city,
            this.address_form.value.address_street,
            this.address_form.value.address_street_number,
            this.address_form.value.address_neighborhood,
            this.address_form.value.address_state,

            ).subscribe({
              next: (res: any) => {
                if (res.status == "OK") {
                  this.address_form.patchValue({
                    address_latitude: res.results[0]?.geometry.location.lat,
                    address_longitude: res.results[0]?.geometry.location.lng,
                  })
                }
              },
              error: (err: any) => {
                console.log(err)
              }
            })
        },
        error: () => {}
      })
    }
  }

  getCustomerInfo() {
    if (this.data.user_id) {
      this.customersEubeboApiService.getUserById(this.data.user_id).subscribe({
        next: (res: any) => {
          this.user_info = res

          for (let i = 0; i < this.user_info.addresses.length; i++) {
            if (this.data.address_id && this.data.address_id == this.user_info.addresses[i]._id) {
              this.address_form.patchValue({
                //
                main: this.user_info.addresses[i].main,
                receiver_name: this.user_info.addresses[i].receiver_name,
                phone_number: this.user_info.addresses[i].phone_number,
                cellphone_number: this.user_info.addresses[i].cellphone_number,
                //
                address_name: this.user_info.addresses[i].address.name,
                address_zipcode: this.user_info.addresses[i].address.zipcode,
                address_street_number: this.user_info.addresses[i].address.street_number,
                address_reference: this.user_info.addresses[i].address.reference,
                address_complementary: this.user_info.addresses[i].address.complementary,
                address_latitude: this.user_info.addresses[i].address.latitude,
                address_longitude: this.user_info.addresses[i].address.longitude,
                address_street: this.user_info.addresses[i].address.street,
                address_neighborhood: this.user_info.addresses[i].address.neighborhood,
                address_city: this.user_info.addresses[i].address.city,
                address_state: this.user_info.addresses[i].address.state,
                address_country: this.user_info.addresses[i].address.country,
              })
            }
          }
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }

  deleteAddress() {
    this.customersEubeboApiService.deleteAddress(this.data.user_id, this.data.address_id).subscribe({
      next: (res: any) => {
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  sendForm() {
    if (this.data.type == 'new-address') {
      const user_id = this.authUserService.getValue(this.authUserService.userId)

      const body = {
        receiver_name: this.address_form.value.receiver_name,
        phone_number: this.address_form.value.phone_number,
        cellphone_number: this.address_form.value.cellphone_number,
        main: this.address_form.value.main,
        address: {
          name: this.address_form.value.address_name,
          zipcode: this.address_form.value.address_zipcode,
          street: this.address_form.value.address_street,
          street_number: this.address_form.value.address_street_number,
          neighborhood: this.address_form.value.address_neighborhood,
          reference: this.address_form.value.address_reference,
          city: this.address_form.value.address_city,
          state: this.address_form.value.address_state,
          country: "BR",
          complementary: this.address_form.value.address_complementary,
          latitude: this.address_form.value.address_latitude,
          longitude: this.address_form.value.address_longitude,
        }
      }


      if (user_id) {
        this.currentAddressService.getAddressLatAndLong(
          this.address_form.value.address_city, 
          body.address.street, 
          body.address.street_number,
          body.address.neighborhood,
          body.address.state

          ).subscribe({
          next: (res: any) => {
            body.address.latitude = res.results[0].geometry.location.lat
            body.address.longitude = res.results[0].geometry.location.lng
            this.customersEubeboApiService.registerNewAddress(user_id, body).subscribe({
              next: (res: any) => {

                this.dialogRef.close()
                location.reload()
              },
              error: (err: any) => {
                console.log(err)
              }
            })
          },
          error: (err: any) => {
            console.log(err)
          }
        })
      }
    } else if (this.data.type == 'edit-address') {
      const user_id = this.authUserService.getValue(this.authUserService.userId)

      const body = {
        receiver_name: this.address_form.value.receiver_name,
        phone_number: this.address_form.value.phone_number,
        cellphone_number: this.address_form.value.cellphone_number,
        main: this.address_form.value.main,
        address: {
          name: this.address_form.value.address_name,
          zipcode: this.address_form.value.address_zipcode,
          street: this.address_form.value.address_street,
          street_number: this.address_form.value.address_street_number,
          neighborhood: this.address_form.value.address_neighborhood,
          reference: this.address_form.value.address_reference,
          city: this.address_form.value.address_city,
          state: this.address_form.value.address_state,
          country: "BR",
          complementary: this.address_form.value.address_complementary,
          latitude: this.address_form.value.address_latitude,
          longitude: this.address_form.value.address_longitude,
        }
      }

      if (user_id) {
        this.currentAddressService.getAddressLatAndLong(
          this.address_form.value.address_city, 
          body.address.street, 
          body.address.street_number,
          body.address.neighborhood,
          body.address.state
        ).subscribe({
          next: (res: any) => {
            body.address.latitude = res.results[0].geometry.location.lat
            body.address.longitude = res.results[0].geometry.location.lng
            this.customersEubeboApiService.updateAddress(this.data.user_id, this.data.address_id, body).subscribe({
              next: (res: any) => {

                if (res.main == true) {
                  this.currentAddressService.refreshAddress(res)
                }

                this.dialogRef.close()
                location.reload()
              },
              error: (err: any) => {
                console.log(err)
              }
            })
          },
          error: (err: any) => {
            console.log(err)
          }
        })
      }
    }
  }
}
