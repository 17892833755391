import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { MatDialog } from '@angular/material/dialog';
import { CreditCardComponent } from './credit-card/credit-card.component';

@Component({
  selector: 'app-payment-methods-page',
  templateUrl: './payment-methods-page.component.html',
  styleUrls: ['./payment-methods-page.component.scss']
})
export class PaymentMethodsPageComponent implements OnInit {

  customer_id: any

  credit_cards: any[] = []
  addresses: any[] = []

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private authUserService: AuthUserService,
    private customersEubeboApiService: CustomersEubeboApiService,
  ) {
    this.customer_id = this.authUserService.getValue(this.authUserService.userId)
  }

  ngOnInit(): void {
    this.getCustomerById()
  }

  getCustomerById() {
    this.customersEubeboApiService.getUserById(this.customer_id).subscribe({
      next: (res: any) => {
        this.credit_cards = res.cards
        this.addresses = res.addresses
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  deleteCard(cc_id: string) {
    this.customersEubeboApiService.deleteCreditCard(this.customer_id, cc_id).subscribe({
      next: (res: any) => {
      },
      error: (err: any) => {
        console.log(err)
      },
    })
  }

  openModal(type: string) {
    if (type == 'new-credit-card') {
      const dialogRef = this.dialog.open(CreditCardComponent, {
        data: {
          customer_id: this.customer_id,
          customer_addressess: this.addresses,
          isEdit: false
        }
      })

      dialogRef.afterClosed().subscribe((result) => {

      })
    }
  }

  checkBrandCard( value: string){
    let visaRegex: RegExp =  new RegExp(/^4[0-9]{5,}$/);
    let masterRegex: RegExp =  new RegExp(/^5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720$/);
    let americanExpressRegex: RegExp =  new RegExp(/^3[47][0-9]{5,}$/);
    let dinnersRegex: RegExp =  new RegExp(/^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/);
    let discoverRegex: RegExp =  new RegExp(/^6(?:011|5[0-9]{2})[0-9]{1,}$/);
    let jcbRegex: RegExp =  new RegExp(/^(?:2131|1800|35[0-9]{3})[0-9]{1,}$/);


    if(visaRegex.test(value)){
      return 'visa'
    } else if( americanExpressRegex.test(value)){
      return 'american-express';
    } else if( dinnersRegex.test(value)){
      return 'dinners-club';
    } else if( discoverRegex.test(value)){
      return 'discover';
    } else if( jcbRegex.test(value)){
      return 'jcb';
    } else if( masterRegex.test(value)){
      return 'mastercard';
    } else {
      return 'credit-card';
    }

  }
}
