//
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

//
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Modulos Angular
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http';

// Modulos do App
import { SharedModule } from './shared/shared.module';
import { InicioPageComponent } from './pages/inicio-page/inicio-page.component';
import { BuscaPageComponent } from './pages/busca-page/busca-page.component';
import { CarouselModule } from 'primeng/carousel';
import { CarouselModule as NgxCarouselModule } from 'ngx-bootstrap/carousel';
import { TagModule } from 'primeng/tag';
import { ButtonModule } from 'primeng/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatGridListModule } from '@angular/material/grid-list';
import { CarrinhoPageComponent } from './pages/carrinho-page/carrinho-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { DeliveryPageComponent } from './pages/delivery-page/delivery-page.component';
import { ProdutoPageComponent } from './pages/produto-page/produto-page.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatRippleModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LojasPageComponent } from './pages/lojas-page/lojas-page.component';
import { SeeMoreComponent } from './pages/lojas-page/see-more/see-more.component';
import { PedidosPageComponent } from './pages/pedidos-page/pedidos-page.component';
import { EnderecosPageComponent } from './pages/enderecos-page/enderecos-page.component';
import { FavoritosPageComponent } from './pages/favoritos-page/favoritos-page.component';
import { EditarPerfilPageComponent } from './pages/editar-perfil-page/editar-perfil-page.component';
import { VendaConoscoPageComponent } from './pages/venda-conosco-page/venda-conosco-page.component';
import { AtendimentoAoClientePageComponent } from './pages/atendimento-ao-cliente-page/atendimento-ao-cliente-page.component';
import { FaqPageComponent } from './pages/faq-page/faq-page.component';
import { AddProductErrorDialogComponent } from './pages/produto-page/add-product-error-dialog/add-product-error-dialog.component';
import { SeeCategoryComponent } from './pages/lojas-page/see-category/see-category.component';
import { SeeSubcategoryComponent } from './pages/lojas-page/see-subcategory/see-subcategory.component';
import { AgmCoreModule } from '@agm/core';
import { EditEnderecoComponent } from './pages/enderecos-page/edit-endereco/edit-endereco.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMaskModule }  from  'ngx-mask';
import { AvisoEnderecoPrincipalComponent } from './pages/enderecos-page/aviso-endereco-principal/aviso-endereco-principal.component';
import { CheckoutPageComponent } from './pages/checkout-page/checkout-page.component';
import { ConfirmModalComponent } from './pages/checkout-page/confirm-modal/confirm-modal.component';
import { CardHashCreatorComponent } from './pages/checkout-page/card-hash-creator/card-hash-creator.component';
import { CustomersEubeboApiService } from './services/customers-eubebo-api.service';
import { GridComponent } from './pages/favoritos-page/grid/grid.component';
import { DynamicPipeModule } from './config/pipe/dynamic-pipes/dynamic.module';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { registerLocaleData } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import localePt from '@angular/common/locales/pt';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { LacMatTelInputModule } from 'lac-mat-tel-input';
import { EditarSenhaPageComponent } from './pages/editar-perfil-page/editar-senha-page/editar-senha-page.component';
import { SkeletonModule } from 'primeng/skeleton';
import { AjudaModalComponent } from './pages/pedidos-page/ajuda-modal/ajuda-modal.component';
import { ProdutoSinglepageComponent } from './pages/produto-singlepage/produto-singlepage.component';
import { EventEmitService } from './services/event-emit.service';
import { NavigationModule } from './navigation/navigation.module';
import { StoreInfosDialogComponent } from './pages/lojas-page/store-infos-dialog/store-infos-dialog.component';
import { PaymentMethodsPageComponent } from './pages/payment-methods-page/payment-methods-page.component';
import { CreditCardComponent } from './pages/payment-methods-page/credit-card/credit-card.component';
import { CardAddressSelectedModule } from './pages/payment-methods-page/card-address-selected/card-address-selected.module';
import { CreditCardSelectorComponent } from './pages/checkout-page/credit-card-selector/credit-card-selector.component';
import { QrCodeComponent } from './pages/checkout-page/qr-code/qr-code.component';
import { SuccessTrasitionComponent } from './pages/checkout-page/success-trasition/success-trasition.component';
import { RatingComponent } from './pages/pedidos-page/rating/rating.component';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { ChatsService } from './services/chats.service';
import { TruncatePipeModule } from './config/pipe/pipe-truncate/truncate.module';
import { CardPaymentSelectedModule } from './shared/components/card-payment-selected/card-payment-selected.module';
import { ExchangeAmountComponent } from './pages/checkout-page/exchange-amount/exchange-amount.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CurrencyMaskConfig, CurrencyMaskModule, CURRENCY_MASK_CONFIG } from 'ng2-currency-mask';
import { StoreRatingsComponent } from './pages/lojas-page/store-ratings/store-ratings.component';
import { StartRateModule } from './pages/chat/start-rate/start-rate.module';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { ConfirmJustLiquidComponent } from './pages/produto-page/confirm-just-liquid/confirm-just-liquid.component';


registerLocaleData(localePt);

export function playerFactory() {
  return player;
}

const pluginModules = [
  // Angular Image Zoom
  NgxImageZoomModule,
  // LacMatTel
  LacMatTelInputModule,
  // Bootstrap
  BsDropdownModule.forRoot(),
  // Prime Modules
  ButtonModule,
  TagModule,
  CarouselModule,
  SkeletonModule,
  // MaterialModules
  MatIconModule,
  MatGridListModule,
  MatDialogModule,
  MatSelectModule,
  MatTabsModule,
  MatRadioModule,
  MatExpansionModule,
  MatFormFieldModule,
  MatMenuModule,
  MatInputModule,
  MatRippleModule,
  MatDividerModule,
  MatCardModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSidenavModule,
  MatButtonModule,
  MatCheckboxModule,
  // Bootstrap
  NgxCarouselModule,
  // NgxMask
  NgxMaskModule.forRoot(),
  // Lottie
  LottieModule.forRoot({ player: playerFactory }),
  // AgmCore
  AgmCoreModule.forRoot({
    apiKey: 'AIzaSyD4Dx_pOUPoDlTUQ1ZEov8__nfAvMHy4ao'
  }),
  
]

const config: SocketIoConfig = { url: environment.urlSocket , options: {
  transports: ['websocket'],
  autoConnect: true,

}};


export const CustomCurrencyMaskConfig: CurrencyMaskConfig = {
  align: "left",
  allowNegative: true,
  decimal: ",",
  precision: 2,
  prefix: "R$ ",
  suffix: "",
  thousands: "."
};


@NgModule({
  declarations: [
    AppComponent,
    InicioPageComponent,
    BuscaPageComponent,
    CarrinhoPageComponent,
    NotFoundPageComponent,
    DeliveryPageComponent,
    ProdutoPageComponent,
    LojasPageComponent,
    SeeMoreComponent,
    PedidosPageComponent,
    EnderecosPageComponent,
    FavoritosPageComponent,
    EditarPerfilPageComponent,
    VendaConoscoPageComponent,
    AtendimentoAoClientePageComponent,
    FaqPageComponent,
    AddProductErrorDialogComponent,
    SeeCategoryComponent,
    SeeSubcategoryComponent,
    EditEnderecoComponent,
    AvisoEnderecoPrincipalComponent,
    CheckoutPageComponent,
    ConfirmModalComponent,
    CardHashCreatorComponent,
    GridComponent,
    EditarSenhaPageComponent,
    AjudaModalComponent,
    ProdutoSinglepageComponent,
    StoreInfosDialogComponent,
    PaymentMethodsPageComponent,
    CreditCardComponent,
    CreditCardSelectorComponent,
    QrCodeComponent,
    SuccessTrasitionComponent,
    RatingComponent,
    ExchangeAmountComponent,
    StoreRatingsComponent,
    ConfirmJustLiquidComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // Modules
    DynamicPipeModule,
    RouterModule,
    SharedModule,
    CardAddressSelectedModule,
    NavigationModule,
    pluginModules,
    NgbModule,
    SocketIoModule.forRoot(config),
    TruncatePipeModule,
    CardPaymentSelectedModule,
    CurrencyMaskModule,
    StartRateModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: CURRENCY_MASK_CONFIG, useValue: CustomCurrencyMaskConfig },
    CustomersEubeboApiService,
    DatePipe,
    ChatsService,
    EventEmitService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
