import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { CurrentAddressService } from 'src/app/services/current-address.service';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AgmMap, MapsAPILoader } from '@agm/core';
import { EditEnderecoComponent } from 'src/app/pages/enderecos-page/edit-endereco/edit-endereco.component';
import { ConfirmAddressComponent } from './confirm-address/confirm-address.component';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { EventEmitService } from 'src/app/services/event-emit.service';

declare var google: any;

@Component({
  selector: 'app-cep-dialog',
  templateUrl: './cep-dialog.component.html',
  styleUrls: ['./cep-dialog.component.scss']
})
export class CepDialogComponent implements OnInit {
  @ViewChild(AgmMap) map!: AgmMap;

  mapaImagem: string = '../../../../assets/images/mapa.png'

  user_id: string | null = null
  user_info: any
  userAddresses: any = []

  address: any
  addressForm: FormGroup
  addressInfo: any

  isOnFirstScreen: boolean = true
  isOnSecondScreen: boolean = false
  isOnThirdScreen: boolean = false
  isOnFourthScreen: boolean = false
  isOnDeliveryInfoScreen: boolean = false

  isEdit: boolean = false

  isCasaSelected: boolean = false
  isTrabalhoSelected: boolean = false

  isFirstAddressSelected: boolean = false
  isSecondAddressSelected: boolean = false

  isGenericZipcode: boolean = false

  selectedAddressIndex: number | null = null;

  saveThisAddress: boolean = false

  nullComplement: boolean = false
  nullReference: boolean = false

  address_id: string | null = null;

  // Agm
  latitude: number = 0
  longitude: number = 0
  latitude_edit: number = 0
  longitude_edit: number = 0
  zoom: number = 15
  private geoCoder: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CepDialogComponent>,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private authUserService: AuthUserService,
    private currentAddressService: CurrentAddressService,
    private customersEubeboApiService: CustomersEubeboApiService,
    private eventEmitService: EventEmitService,
  ) {
    this.addressForm = this.formBuilder.group({
      zipcode: [null, [Validators.minLength(8), Validators.maxLength(8)]],
      state: [null],
      country: [null],
      city: [null],
      street: [null],
      num_street: [null],
      neighborhood: [null],
      complement: [null],
      reference: [null],
      address_name: [null],
      receiver_name: [null],
      phone_number: [null],
      cellphone_number: [null],
      main: [null],
    })

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;
    });
  }

  ngOnInit(): void {
    this.address_id = this.currentAddressService.getValue(this.currentAddressService.address_id)
    
   
    this.user_id = this.authUserService.getValue(this.authUserService.userId)

    if(this.user_id != null) {
      this.customersEubeboApiService.getUserById(this.user_id).subscribe({
        next: (res: any) => {
          this.userAddresses = res.addresses
          this.saveThisAddress = true;
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }

    if (this.data.type == 'edit-address') {
      this.isEdit = true
      this.getAddress(this.data.user_id, this.data.address_id)
    }

  }

  openModal(component: string): void {
    if (component == 'login') {
      const dialogRef = this.dialog.open(LoginDialogComponent, {
        data: {
          deactivatable: false,
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        this.user_id = this.authUserService.getValue(this.authUserService.userId)

        if (this.user_id != null) {
          this.customersEubeboApiService.getUserById(this.user_id).subscribe({
            next: (res: any) => {
              this.userAddresses = res.addresses
            },
            error: (err: any) => {
              console.log(err)
            }
          })
        }

        if (result == 'close-all') {
          this.dialogRef.close()
        }
      })
    }
  }

  closeModal(): void {
    this.dialogRef.close()

  }

  markerDragEnd($event: any) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.latitude_edit = $event.coords.lat;
    this.longitude_edit = $event.coords.lng;
    this.currentAddressService.setValue(this.currentAddressService.address_lat, $event.coords.lat)
    this.currentAddressService.setValue(this.currentAddressService.address_long, $event.coords.lng)
  }

  findLocation(street: any, street_number: any, neighborhood: any, city: any, state: any) {
    const address= `${street}, ${street_number} - ${neighborhood}, ${city} - ${state}`

    if (!this.geoCoder) {
      this.geoCoder = new google.maps.Geocoder();
    }
    this.geoCoder.geocode({
      'address': address
    }, (results: any, status: any) => {
      if (status == google.maps.GeocoderStatus.OK) {
        if (results[0].geometry.location) {
          this.latitude = results[0].geometry.location.lat();
          this.longitude = results[0].geometry.location.lng();
        }

        this.map.triggerResize()
      } else {
        alert("Sorry, this search produced no results.");

      }

    });

  }

  selectScreen(screenIndex: number) {
    switch (screenIndex) {
      case 1:
        this.isOnFirstScreen = true
        this.isOnSecondScreen = false
        this.isOnThirdScreen = false
        this.isOnFourthScreen = false
        break
      case 2:
        this.isOnFirstScreen = false
        this.isOnSecondScreen = true
        this.isOnThirdScreen = false
        this.isOnFourthScreen = false
        break
      case 3:
        this.isOnFirstScreen = false
        this.isOnSecondScreen = false
        this.isOnThirdScreen = true
        this.isOnFourthScreen = false
        break
      case 4:
        this.isOnFirstScreen = false
        this.isOnSecondScreen = false
        this.isOnThirdScreen = false
        this.isOnFourthScreen = true
        break
    }
  }

  selectAddressName(value: string): void {
    this.addressForm.patchValue({
      address_name: value
    })

    switch (value) {
      case 'Casa':
        this.isCasaSelected = true
        this.isTrabalhoSelected = false
        break
      case 'Trabalho':
        this.isTrabalhoSelected = true
        this.isCasaSelected = false
        break
    }
  }

  saveZipcode(): void {

    if(this.addressForm.get('zipcode')?.invalid ){

      this.addressForm.markAllAsTouched();
      return 
    }

    if (this.addressForm.value.zipcode != null) {
     

      this.currentAddressService.getAddressByCEP(this.addressForm.value.zipcode).subscribe({
        next: (res: any) => {

          if(res?.erro){
            this.dialog.open(ErrorDialogComponent, {
              data: {
                error: true,
                message: "Cep não encontrado"
              }
            })

            return;
          } 

          this.addressForm.controls['num_street'].addValidators([Validators.required])
          if(!res.bairro || !res.logradouro){
            this.isGenericZipcode = true;
            this.addressForm.controls['street'].addValidators([Validators.required])
            this.addressForm.controls['num_street'].addValidators([Validators.required])
            this.addressForm.controls['neighborhood'].addValidators([Validators.required])
            this.addressForm.controls['street'].patchValue(res.logradouro)
            this.addressForm.controls['neighborhood'].patchValue(res.bairro)
          }
          this.addressForm.updateValueAndValidity();
          this.addressInfo = res
          this.selectScreen(2)
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }

  saveStreetNumber(): void {
    if(this.addressForm.get('num_street')?.invalid ){
      this.addressForm.markAllAsTouched();
      return
    }

    this.selectScreen(3)
  }

  getAddress(user_id: string, address_id: string) {
    this.customersEubeboApiService.getUserById(user_id).subscribe({
      next: (res: any) => {
        for (let i = 0; i < res.addresses.length; i++) {
          if (res.addresses[i]._id === address_id) {
            this.address = res.addresses[i]
            this.latitude_edit = res.addresses[i].address.location.coordinates[0]
            this.longitude_edit = res.addresses[i].address.location.coordinates[1]

            const body = {
              zipcode: res.addresses[i].address.zipcode,
              state: res.addresses[i].address.state.toLowerCase(),
              country: res.addresses[i].address.country.toLowerCase(),
              city: res.addresses[i].address.city,
              street: res.addresses[i].address.street,
              num_street: res.addresses[i].address.street_number,
              neighborhood: res.addresses[i].address.neighborhood,
              complement: res.addresses[i].address.complementary,
              reference: res.addresses[i].address.reference ?? 'null',
              address_name: res.addresses[i].address.name,
              receiver_name: res.addresses[i].receiver_name,
              phone_number: res.addresses[i].homePhone ? res.addresses[i].homePhone.areaCode + res.addresses[i].homePhone.number : res.addresses[i].phone_number,
              cellphone_number: res.addresses[i].mobilePhone ? res.addresses[i].mobilePhone.areaCode + res.addresses[i].mobilePhone.number : res.addresses[i].cellphone_number,
              main: res.addresses[i].main,
            }

            this.addressForm.setValue(body)
          }
        }
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  saveAddress(): void {
    this.currentAddressService.setValue(this.currentAddressService.zipcode, this.addressForm.value.zipcode)
    this.currentAddressService.setValue(this.currentAddressService.state, this.addressInfo.uf)
    this.currentAddressService.setValue(this.currentAddressService.city, this.addressInfo.localidade)
    this.currentAddressService.setValue(this.currentAddressService.street, this.addressInfo.logradouro != null && this.addressInfo.logradouro != '' ? this.addressInfo.logradouro : this.addressForm.value.street)
    this.currentAddressService.setValue(this.currentAddressService.neighborhood, this.addressInfo.bairro != null && this.addressInfo.bairro != '' ? this.addressInfo.bairro : this.addressForm.value.neighborhood)
    this.currentAddressService.setValue(this.currentAddressService.street_number, this.addressForm.value.num_street)
    this.currentAddressService.setValue(this.currentAddressService.complement, this.addressForm.value.complement)
    this.currentAddressService.setValue(this.currentAddressService.reference, this.addressForm.value.reference)

    this.currentAddressService.getAddressLatAndLong( this.addressInfo.localidade, (this.addressForm.value.street != null ? this.addressForm.value.street : this.addressInfo.logradouro),  this.addressForm.value.num_street, (this.addressForm.value.neighborhood != null ? this.addressForm.value.neighborhood : this.addressInfo.bairro), this.addressInfo.uf).subscribe({
      next: (res: any) => {
        this.latitude = res.results[0]?.geometry.location.lat
        this.longitude = res.results[0]?.geometry.location.lng
        this.currentAddressService.setValue(this.currentAddressService.address_lat, res.results[0]?.geometry.location.lat)
        this.currentAddressService.setValue(this.currentAddressService.address_long, res.results[0]?.geometry.location.lng)

        if (!this.saveThisAddress) {
          this.selectScreen(4)

          this.findLocation(
            this.addressInfo.logradouro,
            this.addressForm.value.num_street,
            this.addressInfo.bairro,
            this.addressInfo.localidade,
            this.addressInfo.uf
          )
        } else {
          this.isOnThirdScreen = false
          this.isOnDeliveryInfoScreen = true
        }

      },
      error: (err: any) => {
        console.log(err)

        this.dialogRef.close()
        location.reload()
      },
    })
  }

  editAddress(address_id: string) {
    const dialogRef = this.dialog.open(CepDialogComponent, {
      autoFocus: false,
      data: {
        type: 'edit-address',
        user_id: this.user_id,
        address_id: address_id
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })
  }

  deleteAddress(user_id: string, address_id: string) {

    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        type: 'delete-address',
        user_id: user_id,
        address_id: address_id
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (this.data.user_id) {
        this.customersEubeboApiService.getUserById(this.data.user_id).subscribe({
          next: (res: any) => {
            this.userAddresses = res.addresses
          },
          error: (err: any) => {
            console.log(err)
          }
        })
      } else {
        this.user_id = this.authUserService.getValue(this.authUserService.userId)

        if (this.user_id) {
          this.customersEubeboApiService.getUserById(this.user_id).subscribe({
            next: (res: any) => {
              this.userAddresses = res.addresses
            },
            error: (err: any) => {
              console.log(err)
            }
          })
        }
      }
    })
  }

  markCheckbox(event: any, type: string) {
    // if (type === 'save-address') this.saveThisAddress = event.checked
    if (type === 'complement-address') this.nullComplement = event.checked
    if (type === 'reference-address') this.nullReference = event.checked
  }

  goToMap() {
    this.isOnDeliveryInfoScreen = false
    this.selectScreen(4)
  }

  sendForm(isEdit: boolean) {
    const user_id = this.authUserService.getValue(this.authUserService.userId)

    let mobilePhone = {
      areaCode: this.addressForm.value.cellphone_number.slice(0, 2),
      countryCode: '55',
      number: this.addressForm.value.cellphone_number.slice(2)
    }

    let homePhone = {
      areaCode: this.addressForm.value.phone_number.slice(0, 2),
      countryCode: '55',
      number: this.addressForm.value.phone_number.slice(2)
    }

    if (isEdit) {

      const body = {
        receiver_name: this.addressForm.value.receiver_name,
        homePhone: homePhone,
        mobilePhone: mobilePhone,
        main: this.addressForm.value.main ?? false,
        address: {
          name: `${this.addressForm.value.address_name} - ${this.addressForm.value.street} ${this.addressForm.value.num_street}` ,
          zipcode: this.addressForm.value.zipcode,
          street: this.addressForm.value.street,
          street_number: this.addressForm.value.num_street,
          neighborhood: this.addressForm.value.neighborhood,
          reference: this.addressForm.value.reference ?? 'null',
          city: this.addressForm.value.city,
          state: this.addressForm.value.state,
          country: this.addressForm.value.country,
          complementary: this.addressForm.value.complement ?? 'null',
          latitude: this.latitude_edit,
          longitude: this.longitude_edit,
        }
      }

      if (user_id) {
        this.customersEubeboApiService.updateAddress(this.data.user_id, this.data.address_id, body).subscribe({
          next: (res: any) => {
            this.dialogRef.close()
          },
          error: (err: any) => {
            console.log(err)
          }
        })
      }

    } else if (!isEdit) {

      const body = {
        receiver_name: this.addressForm.value.receiver_name,
        homePhone: homePhone,
        mobilePhone: mobilePhone,
        main: this.addressForm.value.main ?? false,
        address: {
          name: `${this.addressForm.value.address_name} - ${this.addressInfo.logradouro != null && this.addressInfo.logradouro != '' ? this.addressInfo.logradouro : this.addressForm.value.street} ${this.addressForm.value.num_street}` ,
          zipcode: this.addressForm.value.zipcode,
          street: this.addressInfo.logradouro != null && this.addressInfo.logradouro != '' ? this.addressInfo.logradouro : this.addressForm.value.street,
          street_number: this.addressForm.value.num_street,
          neighborhood: this.addressInfo.bairro != null && this.addressInfo.bairro != '' ? this.addressInfo.bairro : this.addressForm.value.neighborhood,
          reference: this.nullReference ? 'null' : this.addressForm.value.address_reference,
          city: this.addressForm.value.address_city ?? this.addressInfo.localidade,
          state: this.addressForm.value.address_state ?? this.addressInfo.uf,
          country: "BR",
          complementary: this.nullComplement ? 'null' : this.addressForm.value.complement,
          latitude: this.latitude,
          longitude: this.longitude,
        }
      }

      if (user_id) {
        this.currentAddressService.getAddressLatAndLong( this.addressInfo.localidade, 
          body.address.street, 
          this.addressForm.value.num_street,
          body.address.neighborhood,
          body.address.state

          ).subscribe({
          next: (res: any) => {
            body.address.latitude = res.results[0].geometry.location.lat
            body.address.longitude = res.results[0].geometry.location.lng
            this.customersEubeboApiService.registerNewAddress(user_id, body).subscribe({
              next: (res: any) => {
                this.selectScreen(4)

                this.findLocation(
                  this.addressInfo.logradouro,
                  this.addressForm.value.num_street,
                  this.addressInfo.bairro,
                  this.addressInfo.localidade,
                  this.addressInfo.uf
                )

                if (this.saveThisAddress) {
                  this.dialogRef.close()
                }
              },
              error: (err: any) => {
                console.log(err)
                const dialogRef = this.dialog.open(ErrorDialogComponent, {
                  data: {
                    error: true,
                    message: err.error.message
                  }
                })

                dialogRef.afterClosed().subscribe(result => {
                  console.log(result)
                })
              }
            })
          },
          error: (err: any) => {
            console.log(err)
          }
        })
      }
    }
  }

  finalizeModal(isEdit: boolean): void {

    if (isEdit) {
      this.sendForm(true)
    } else {
      if (this.saveThisAddress) {
        this.sendForm(false)
      } else {
        this.eventEmitService.emit('hasAddress', true)
        this.dialogRef.close()
      }
    }
  }

  isAddressSelected(index: number): boolean {
    return this.selectedAddressIndex === index;
  }


  selectAddress(index: number, address: any): void {
    const dialogRef = this.dialog.open(ConfirmAddressComponent, {
      data: {
        user_id: this.user_id,
        address: address
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.selectedAddressIndex = index;

        this.currentAddressService.refreshAddress(address)

        this.eventEmitService.emit('hasAddress', true)

        this.dialogRef.close();
      }
    })

  }

  redirectToRegisterPage() {
    location.href = 'https://teste.eubebo.com.br/autenticacao/cadastrar'
  }

  paisesDoMundo = [
    {
      nome: 'Brasil',
      sigla: 'BR',
      capital: 'Brasilia'
    }
  ]

  estadosBrasileiros = [
    {
      nome: 'Acre',
      sigla: 'AC',
      capital: 'Rio Branco',
      populacao: 881935
    },
    {
      nome: 'Alagoas',
      sigla: 'AL',
      capital: 'Maceió',
      populacao: 3351543
    },
    {
      nome: 'Amapá',
      sigla: 'AP',
      capital: 'Macapá',
      populacao: 861773
    },
    {
      nome: 'Amazonas',
      sigla: 'AM',
      capital: 'Manaus',
      populacao: 4207714
    },
    {
      nome: 'Bahia',
      sigla: 'BA',
      capital: 'Salvador',
      populacao: 14930634
    },
    {
      nome: 'Ceará',
      sigla: 'CE',
      capital: 'Fortaleza',
      populacao: 9187103
    },
    {
      nome: 'Distrito Federal',
      sigla: 'DF',
      capital: 'Brasília',
      populacao: 3055149
    },
    {
      nome: 'Espírito Santo',
      sigla: 'ES',
      capital: 'Vitória',
      populacao: 4064052
    },
    {
      nome: 'Goiás',
      sigla: 'GO',
      capital: 'Goiânia',
      populacao: 7113540
    },
    {
      nome: 'Maranhão',
      sigla: 'MA',
      capital: 'São Luís',
      populacao: 7114598
    },
    {
      nome: 'Mato Grosso',
      sigla: 'MT',
      capital: 'Cuiabá',
      populacao: 3526220
    },
    {
      nome: 'Mato Grosso do Sul',
      sigla: 'MS',
      capital: 'Campo Grande',
      populacao: 2809394
    },
    {
      nome: 'Minas Gerais',
      sigla: 'MG',
      capital: 'Belo Horizonte',
      populacao: 21168791
    },
    {
      nome: 'Pará',
      sigla: 'PA',
      capital: 'Belém',
      populacao: 8690745
    },
    {
      nome: 'Paraíba',
      sigla: 'PB',
      capital: 'João Pessoa',
      populacao: 4039277
    },
    {
      nome: 'Paraná',
      sigla: 'PR',
      capital: 'Curitiba',
      populacao: 11516840
    },
    {
      nome: 'Pernambuco',
      sigla: 'PE',
      capital: 'Recife',
      populacao: 9616621
    },
    {
      nome: 'Piauí',
      sigla: 'PI',
      capital: 'Teresina',
      populacao: 3273227
    },
    {
      nome: 'Rio de Janeiro',
      sigla: 'RJ',
      capital: 'Rio de Janeiro',
      populacao: 17366189
    },
    {
      nome: 'Rio Grande do Norte',
      sigla: 'RN',
      capital: 'Natal',
      populacao: 3534165
    },
    {
      nome: 'Rio Grande do Sul',
      sigla: 'RS',
      capital: 'Porto Alegre',
      populacao: 11422973
    },
    {
      nome: 'Rondônia',
      sigla: 'RO',
      capital: 'Porto Velho',
      populacao: 1796460
    },
    {
      nome: 'Roraima',
      sigla: 'RR',
      capital: 'Boa Vista',
      populacao: 631181
    },
    {
      nome: 'Santa Catarina',
      sigla: 'SC',
      capital: 'Florianópolis',
      populacao: 7252502
    },
    {
      nome: 'São Paulo',
      sigla: 'SP',
      capital: 'São Paulo',
      populacao: 46289333
    },
    {
      nome: 'Sergipe',
      sigla: 'SE',
      capital: 'Aracaju',
      populacao: 2318822
    },
    {
      nome: 'Tocantins',
      sigla: 'TO',
      capital: 'Palmas',
      populacao: 1590248
    }
  ];
}
