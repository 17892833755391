import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CustomersEubeboApiService } from 'src/app/services/customers-eubebo-api.service';
import { EditEnderecoComponent } from './edit-endereco/edit-endereco.component';
import { checkMargins } from 'ngx-bootstrap/positioning';
import { CurrentAddressService } from 'src/app/services/current-address.service';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';

@Component({
  selector: 'app-enderecos-page',
  templateUrl: './enderecos-page.component.html',
  styleUrls: ['./enderecos-page.component.scss']
})
export class EnderecosPageComponent implements OnInit {

  larguraTela: number = window.innerWidth;

  user_id: string | null = null
  user_info: any = {}

  options: AnimationOptions = {
    path: '/assets/images/address.json',
  };

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private authUserService: AuthUserService,
    private currentAddressService: CurrentAddressService,
    private customersEubeboApiService: CustomersEubeboApiService,
  ) { }

  ngOnInit(): void {
    this.user_id = this.authUserService.getValue(this.authUserService.userId);

    if (this.user_id == null) {
      this.router.navigate(['/'])
    }

    this.getCustomerInfo()
  }

  getCustomerInfo() {
    if (this.user_id) {
      this.customersEubeboApiService.getUserById(this.user_id).subscribe({
        next: (res: any) => {
          this.user_info = res
        },
        error: (err: any) => {
          console.log(err)
        }
      })
    }
  }

  openDialog(component: string) {
    if (component == 'new-address') {
      const dialogRef = this.dialog.open(EditEnderecoComponent, {
        data: {
          type: 'new-address'
        }
      })

      dialogRef.afterClosed().subscribe(result => {
        console.log(result)
      })
    }
  }

  definirPrincipal(user_id: string, address_id: string) {
    this.customersEubeboApiService.getUserById(user_id).subscribe({
      next: (res: any) => {
        for (let i = 0; i < res.addresses.length; i++) {
          if (res.addresses[i]._id != address_id) {
            const body = {
              main: false,
            }


            this.customersEubeboApiService.updateAddress(user_id, address_id, body).subscribe({
              next: (res: any) => {
              },
              error: (err: any) => {
                console.log(err)
              }
            })
          } else if (res.addresses[i]._id == address_id) {
            const body = {
              receiver_name: res.addresses[i].receiver_name,
              phone_number: res.addresses[i].phone_number,
              cellphone_number: res.addresses[i].cellphone_number,
              main: true,
              address: {
                name: res.addresses[i].address?.name,
                zipcode: res.addresses[i].address?.zipcode,
                street: res.addresses[i].address?.street,
                street_number: res.addresses[i].address?.street_number,
                neighborhood: res.addresses[i].address?.neighborhood,
                reference: res.addresses[i].address?.reference,
                city: res.addresses[i].address?.city,
                state: res.addresses[i].address?.state,
                country: "BR",
                complementary: res.addresses[i].address?.complementary,
                latitude: res.addresses[i].address?.latitude,
                longitude: res.addresses[i].address?.longitude,
              }
            }

            this.currentAddressService.getAddressLatAndLong( 
              body.address.city, 
              body.address.street, 
              body.address.street_number,
              body.address.neighborhood,
              body.address.state
            ).subscribe({
              next: (res: any) => {
                body.address.latitude = res.results[0]?.geometry.location.lat
                body.address.longitude = res.results[0]?.geometry.location.lng
                this.customersEubeboApiService.updateAddress(user_id, address_id, body).subscribe({
                  next: (resp: any) => {
                    this.currentAddressService.refreshAddress(resp)

                    this.getCustomerInfo()
                  },
                  error: (err: any) => {
                    console.log(err)
                  }
                })
              },
              error: (err: any) => {}
            })
          }
        }
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  editAddress(address_id: string) {
    const dialogRef = this.dialog.open(EditEnderecoComponent, {
      data: {
        type: 'edit-address',
        user_id: this.user_id,
        address_id: address_id
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
    })
  }

  deleteAddress(user_id: string, address_id: string) {
    this.customersEubeboApiService.deleteAddress(user_id, address_id).subscribe({
      next: (res: any) => {
        location.reload()
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.setSpeed(1);
  }
}
