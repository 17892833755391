import { Component, DoCheck, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CupomDialogComponent } from '../cupom-dialog/cupom-dialog.component';
import { CartEubeboService } from 'src/app/services/cart-eubebo.service';
import { AuthUserService } from 'src/app/services/auth-user.service';
import { CartProductsList } from 'src/app/config/models/cart-products.model';
import { SellerEubeboApiService } from 'src/app/services/sellers-eubebo-api.service';
import { CurrentAddressService } from 'src/app/services/current-address.service';
import { Router } from '@angular/router';
import { LoginDialogComponent } from '../login-dialog/login-dialog.component';
import { CheckoutPageComponent } from 'src/app/pages/checkout-page/checkout-page.component';
import { EditItemCartDialogComponent } from './edit-item-cart-dialog/edit-item-cart-dialog.component';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { InformationComponent } from 'src/app/shared/dialogs/information/information.component';
import { DynamicPipe } from 'src/app/config/pipe/dynamic-pipes/dynamic.pipe';

@Component({
  selector: 'app-cart-dialog',
  templateUrl: './cart-dialog.component.html',
  styleUrls: ['./cart-dialog.component.scss'],
  providers: [DynamicPipe]
})
export class CartDialogComponent implements OnInit, DoCheck {

  options: AnimationOptions = {
    path: '/assets/images/shopping-bag-error.json',
  };

  cupomLogo: string = '../../../../assets/images/local_activity.png'
  listProductsInCart: CartProductsList = {
    data: []
  };

  cupom: any
  lat: any
  long: any
  currentStore: any
  currentStoreId: string = ''
  currentStoreName: string | null = null
  discount_value: number = 0
  shipping_value: number = 0
  subtotal: number = 0
  total: number = 0

  user_id: string | null = null

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    public dialogRef: MatDialogRef<CartDialogComponent>,
    public dialog: MatDialog,
    private dynamicPipe: DynamicPipe,
    private authUserService: AuthUserService,
    private sellerEubeboApiService: SellerEubeboApiService,
    private currentAddressService: CurrentAddressService,
    private cartEubeboService: CartEubeboService,
  ) { }

  ngOnInit(): void {
    this.lat = this.currentAddressService.getValue(this.currentAddressService.address_lat)
    this.long = this.currentAddressService.getValue(this.currentAddressService.address_long)
    this.user_id = this.authUserService.getValue(this.authUserService.userId)

    if (this.user_id == null) {
      const dialogRef = this.dialog.open(LoginDialogComponent)

      this.dialogRef.close()

      dialogRef.afterClosed().subscribe(result => {
        this.user_id = this.authUserService.getValue(this.authUserService.userId)
      })
    }

    this.getProductsInCart()
    this.getCupom()
    this.currentStoreId = this.listProductsInCart.data[0].product_seller_id
    this.getSubtotal()
    this.getSellerById()
  }

  ngDoCheck(): void {
    let listProductsInCartParse = this.cartEubeboService.getValue(this.cartEubeboService.productsList);
    let numOfProducts = Number(this.cartEubeboService.getValue(this.cartEubeboService.numOfProducts));
    if (!listProductsInCartParse && numOfProducts > 0) {
      this.cartEubeboService.setValue(this.cartEubeboService.numOfProducts, 0)
    }
  }

  animationCreated(animationItem: AnimationItem): void {
    animationItem.setSpeed(1);
  }

  getSubtotal() {
    if (this.subtotal > 0) {
      this.subtotal = 0
    }

    for (let i = 0; i < this.listProductsInCart.data.length; i++) {
      this.subtotal = this.subtotal + (+this.listProductsInCart.data[i].number_of_products * +this.listProductsInCart.data[i].product_base_price)
    }
  }

  calcularShippingFee() {
    if (this.currentStore?.seller_delivery?.shipping_type == 1) {
      this.shipping_value = this.currentStore?.seller_delivery?.shipping_fee
    }
    if (this.currentStore?.seller_delivery?.shipping_type == 2) {
      this.shipping_value = this.currentStore?.seller_delivery?.shipping_fee * this.currentStore?.distance
    }
  }

  somarTotal() {
    this.getCupom()
    this.calcularShippingFee()

    if (this.total > 0) {
      this.total = 0
    }

    if (this.cupom && this.cupom?.type === 'percentage' && this.cupom?.applicable_on === 1) {
      if (this.currentStore?.seller_delivery) {
        this.discount_value = (this.subtotal * this.cupom?.discount / 100)
        this.total = (this.subtotal + this.shipping_value) - this.discount_value
      } else {
        this.discount_value = (this.subtotal * this.cupom?.discount) / 100
        this.total = this.subtotal - this.discount_value
      }
    }
    if (this.cupom && this.cupom?.type === 'percentage' && this.cupom?.applicable_on === 2 && this.cupom?.discount === 100) {
      this.discount_value = this.shipping_value
      this.total = this.subtotal
    }
    if (this.cupom && this.cupom?.type === 'percentage' && this.cupom?.applicable_on === 2 && this.cupom?.discount !== 100) {
      const discountValue = (this.shipping_value * this.cupom?.discount / 100);

      this.discount_value = discountValue;
      this.total = this.subtotal + (this.shipping_value - discountValue);
    }
    if (this.cupom && this.cupom?.type === 'amount' && this.cupom?.applicable_on === 1) {
      this.discount_value = this.cupom?.discount / 100
      this.total = (this.subtotal - this.discount_value) + this.shipping_value
    }
    if (this.cupom && this.cupom?.type === 'amount' && this.cupom?.applicable_on === 2) {
      this.discount_value = this.cupom?.discount / 100
      this.total = this.subtotal + (this.shipping_value - this.discount_value)
    }
    if (!this.cupom) {
      if (typeof this.shipping_value === 'number') {
        this.total = this.subtotal + Number(this.shipping_value)
      } else {
        this.total = this.subtotal
      }
    }
  }

  openModal(component: string, data?: any): void {
    if (component == 'cupom') {
      const dialogRef = this.dialog.open(CupomDialogComponent, {
        data: {
          subtotal: this.subtotal
        }
      })

      this.dialogRef.close()

      dialogRef.afterClosed().subscribe(result => {
        this.getProductsInCart()
        this.getSubtotal()
        this.somarTotal()
      })
    }
    if (component == 'edit-item') {
      const dialogRef = this.dialog.open(EditItemCartDialogComponent, {
        data: data
      })

      dialogRef.afterClosed().subscribe(result => {
        this.getProductsInCart()
        if (this.listProductsInCart.data.length == 0) this.currentStoreName = null
        this.getSubtotal()
        this.somarTotal()
      })
    }
  }

  closeModal(): void {
    this.dialogRef.close()
  }

  openStorePage(display_name: string, seller_id: string): void {
    if (this.currentStoreName) {
      this.router.navigate([`lojas/${display_name}/${seller_id}`])
      this.dialogRef.close()
    }
  }

  getCupom() {
    this.cupom = this.cartEubeboService.getSession(this.cartEubeboService.cupomObject)
  }

  getSellerById() {
    this.sellerEubeboApiService.getById(this.currentStoreId, this.lat, this.long).subscribe({
      next: (res: any) => {
        this.currentStoreName = res.display_name
        this.currentStore = res
        this.somarTotal()
      },
      error: (err: any) => {
        console.log(err)
      }
    })
  }

  getProductsInCart() {
    let listProductsInCartParse = this.cartEubeboService.getValue(this.cartEubeboService.productsList);

    if (listProductsInCartParse) {
      this.listProductsInCart = JSON.parse(listProductsInCartParse);
    }

    this.cartEubeboService.setValue(this.cartEubeboService.productsList, JSON.stringify(this.listProductsInCart));
  }

  removeProductsFromCart(indexItem: number) {
    let listOfProductsParse =  this.cartEubeboService.getValue(this.cartEubeboService.productsList)
    let numOfProducts = Number(this.cartEubeboService.getValue(this.cartEubeboService.numOfProducts))
    let listOfProducts

    if (listOfProductsParse) {
      listOfProducts = JSON.parse(listOfProductsParse)
    }

    if (indexItem > -1) {
      numOfProducts = numOfProducts - Number(listOfProducts.data[indexItem]?.number_of_products)
      listOfProducts.data.splice(indexItem, 1)
    }

    this.cartEubeboService.setValue(this.cartEubeboService.productsList, JSON.stringify(listOfProducts))
    this.listProductsInCart = listOfProducts
    this.cartEubeboService.setValue(this.cartEubeboService.numOfProducts, numOfProducts)
    this.getSubtotal()
    this.somarTotal()
    if (this.subtotal == 0) {
      this.currentStoreName = null
    }
  }

  editProductsFromCart(indexItem: number) {
    let listOfProductsParse =  this.cartEubeboService.getValue(this.cartEubeboService.productsList)
    let numOfProducts = Number(this.cartEubeboService.getValue(this.cartEubeboService.numOfProducts))
    let listOfProducts
    let listOfProductsLessIndex

    if (listOfProductsParse) {
      listOfProducts = JSON.parse(listOfProductsParse)
    }

    if (indexItem > -1) {

      numOfProducts = numOfProducts - Number(listOfProducts.data[indexItem]?.number_of_products)
      listOfProductsLessIndex = listOfProducts.data.filter((item: any, index: number) => index != indexItem)

      const data = {
        indexItem: indexItem,
        product: listOfProducts.data[indexItem],
        listOfProducts: listOfProductsLessIndex,
        numOfProducts: numOfProducts
      }

      this.openModal('edit-item', data)
    }
  }

  isShippingFeeNumber(): boolean {
    return typeof this.currentStore?.seller_delivery?.shipping_fee === 'number';
  }

  choosePaymentMethod(): void {
    if(this.currentStore?.seller_delivery?.has_minimum_order_amount && (this.currentStore?.seller_delivery?.minimum_order_amount > this.subtotal)){
      this.dialog.open(InformationComponent, {
        panelClass: 'container-add',
        disableClose: true,
        data: {
          error: true,
          message: `O pedido mínimo para essa loja é de ${this.dynamicPipe.currency(this.currentStore?.seller_delivery?.minimum_order_amount) }. não inclusa a taxa de entrega.`
        }
      })
      return;
    }
    if (this.currentStoreName) {
      this.router.navigate(['checkout'])
      this.dialogRef.close()
    }
  }

  redirectToRegisterPage() {
    location.href = 'https://teste.eubebo.com.br/autenticacao/cadastrar'
  }
}
