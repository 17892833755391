import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  larguraTela: number = window.innerWidth;

  eubeboLogo: String = '../../../assets/images/Logo fundo roxo.png';

  constructor() { }

  ngOnInit(): void {
  }

}
